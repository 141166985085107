import html2canvas from "html2canvas";
import moment from "moment";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function YearRankingTable({ userEventRanking, previousYearUserEventRanking }) {
  const { t, i18n } = useTranslation();
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const currentYear = moment().year();
  const previousYear = moment().subtract(1, "year").year();

  const handleDownload = () => {
    setIsButtonVisible(false);

    setTimeout(() => {
      html2canvas(document.querySelector(".year-rank-card")).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "year-rank.png";
        link.click();
      });
    }, 1000);

    setTimeout(() => {
      setIsButtonVisible(true);
    }, 1000);
  };

  return (
    <>
      <div className="year-rank-card">
        <div className="comparison-header">
          <div className="temple-list-head-sec">
            <h2 className="pledge-head-temple-points">{t("Year Wise Stats")}</h2>
          </div>
          {isButtonVisible && (
            <span className="button-text-download" onClick={handleDownload}>
              {t("Download")}
            </span>
          )}
        </div>
        <h2 className="comparison-text">
          {t("Comparison")} {currentYear} {t("Vs")} {previousYear}
        </h2>

        <Table bordered hover responsive className="mt-2 ranking-table">
          <thead className="table-head">
            <tr>
              <th scope="col text-start" className="text-start">
                {t("Category")}
              </th>
              <th scope="col text-start" className="text-end">
                {currentYear}
              </th>
              <th scope="col text-start" className="text-end">
                {previousYear}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="welcome-info-text text-start">{t("All India Rank")}</td>
              <td className="text-end">{userEventRanking?.all_rank ?? "-"}</td>
              <td className="text-end">{previousYearUserEventRanking?.all_rank ?? "-"}</td>
            </tr>
            <tr>
              <td className="welcome-info-text text-start">{t("State Rank")}</td>
              <td className="text-end">{userEventRanking?.state_rank ?? "-"}</td>
              <td className="text-end">{previousYearUserEventRanking?.state_rank ?? "-"}</td>
            </tr>
            <tr>
              <td className="welcome-info-text text-start">{t("Zonal Rank")}</td>
              <td className="text-end">{userEventRanking?.zone_rank ?? "-"}</td>
              <td className="text-end">{previousYearUserEventRanking?.zone_rank ?? "-"}</td>
            </tr>
            <tr>
              <td className="welcome-info-text text-start">{t("Category Rank")}</td>
              <td className="text-end">{userEventRanking?.temple_rank ?? "-"}</td>
              <td className="text-end">{previousYearUserEventRanking?.temple_rank ?? "-"}</td>
            </tr>
            <tr>
              <td className="welcome-info-text text-start">{t("Total Score")}</td>
              <td className="text-end">{userEventRanking?.total_points ? Math.ceil(userEventRanking?.total_points).toLocaleString("en-IN") : "-"}</td>
              <td className="text-end">
                {previousYearUserEventRanking?.total_points ? Math.ceil(previousYearUserEventRanking?.total_points).toLocaleString("en-IN") : "-"}
              </td>
            </tr>
            <tr>
              <td className="welcome-info-text text-start">{t("Book Count")}</td>
              <td className="text-end">{userEventRanking?.count_book ? Math.ceil(userEventRanking?.count_book).toLocaleString("en-IN") : "-"}</td>
              <td className="text-end">
                {previousYearUserEventRanking?.count_book ? Math.ceil(previousYearUserEventRanking?.count_book).toLocaleString("en-IN") : "-"}
              </td>
            </tr>
          </tbody>
        </Table>
        {/* <div className="d-flex flex-column justify-content-center align-items-center">
          <b>
            Comparison {currentYear} Vs {previousYear}
          </b>
        </div> */}
      </div>
    </>
  );
}
