import React, { useEffect, useState } from "react";
import DatamapsIndia from "react-datamaps-india";
import ApiService from "../../api/ApiService";
import { useTranslation } from "react-i18next";

export default function IndiaMap({ selectedEvent }) {
  const [statePointsData, getStatePointsData] = useState([]);
  const { t, i18n } = useTranslation();

  const getStateWisePointsForMap = async () => {
    getStatePointsData([]);
    try {
      const res = await ApiService.getStateWisePointsForMap(selectedEvent);
      if (res.data.length > 0) {
        const updatedRegionData = {};
        res.data.forEach((item) => {
          updatedRegionData[item.state_name] = {
            value: item?.value ? Math.ceil(item.value) : 0,
          };
        });
        getStatePointsData(updatedRegionData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStateWisePointsForMap();
  }, [selectedEvent]);

  return (
    <>
      {Object.keys(statePointsData).length > 0 && (
        <div className="col-md-6 user-welcome-card">
          <h2 className="welcome-text graphs-heading">{t("State Wise Points")}</h2>
          <div className="map-container">
            <DatamapsIndia
              regionData={statePointsData}
              hoverComponent={({ value }) => {
                return (
                  <div className="custom-tooltip">
                    <p>{t(value.name)}</p>
                    <p>
                      {t("Points")} : {value?.value ? Math.ceil(value?.value)?.toLocaleString("en-IN") : 0}
                    </p>
                  </div>
                );
              }}
              mapLayout={{
                // title: "Points per State in India",
                legendTitle: t("Points"),
                startColor: "#fff4df",
                endColor: "#ffa652",
                hoverTitle: "Count",
                noDataColor: "#f5f5f5",
                borderColor: "#8D8D8D",
                hoverColor: "#E6A632",
                hoverBorderColor: "green",
                height: 0,
                weight: 0,
                display: "none",
              }}
            />
            <p className="map-heading">
              <b>{t("Note")}</b>: {t("The below graphs indicates state wise performance of book distribution. ")}
              {t("Darker color indicates higher book distribution, lighter color indicates lower book distribution.")}
            </p>

            {/* <p className="Note-text">
            </p> */}
          </div>
        </div>
      )}
    </>
  );
}
