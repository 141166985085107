import { useState, useContext } from "react";
import FileUploader from "../common/dragAndDrop";
import AppContext from "../../context/AppContext";
import { useTranslation } from "react-i18next";

export default function EditProfile() {
  const { user } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const [uploadedFile, setUploadedFile] = useState(null);

  const roleMap = {
    Sevak: "Book Distributor",
    "Sevak Head": "Book Distributor Head",
  };

  let userRole = roleMap[user.role] || user.role;

  return (
    <div className="container">
      <div className="edit-profile-wrapper">
        <h5 className="header-text">{t("My Profile")}</h5>
        <form>
          <div className="row">
            <div className="col-md-6 input-bottom">
              <label className="label-text mb-1">{t("Name")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter your name")}
                value={i18n.language === "hi" ? user.hindiname : user.name}
                disabled
              />
            </div>
            <div className="col-md-6 input-bottom">
              <label className="label-text mb-1">{t("Phone")}</label>
              <input type="text" className="form-control" placeholder={t("Enter your phone number")} value={user.phone} disabled />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 input-bottom">
              <label className="label-text mb-1">{t("Email")}</label>
              <input type="text" className="form-control" placeholder={t("Enter your email")} value={user.email} disabled />
            </div>
            <div className="col-md-6 input-bottom">
              <label className="label-text mb-1">{t("Role")}</label>
              <input type="text" className="form-control" placeholder={t("Enter your role")} value={t(userRole)} disabled />
            </div>
            {/* <div className="col-md-6 input-bottom">
              <label className="label-text mb-1">{t("Address")}</label>
              <input 
                type="text" 
                className="form-control" 
                placeholder={t("Enter your address")} 
                value={user.zone_name + ", " + user.state_name} 
              />
            </div> */}
          </div>
          {/* <div className="row">
            <div className="col-md-6 input-bottom">
              <label className="label-text mb-1">{t("Pin code")}</label>
              <input 
                type="text" 
                className="form-control" 
                placeholder={t("Enter your pin code")} 
                value={110001} 
              />
            </div>
            <div className="col-md-6 input-bottom">
              <label className="label-text mb-1">{t("City")}</label>
              <select className="form-select" aria-label="select example">
                <option value="1">{t("Thane")}</option>
                <option value="2">{t("Mumbai")}</option>
              </select>
            </div>
          </div> */}
          <div className="row">
            {/* <div className="col-md-6 input-bottom">
              <label className="label-text mb-1">{t("District")}</label>
              <select className="form-select" aria-label="select example">
                <option value="1">{t("Mumbai")}</option>
                <option value="2">{t("Navi Mumbai")}</option>
              </select>
            </div> 
            <div className="col-md-6 input-bottom">
              <label className="label-text mb-1">{t("State")}</label>
              <select className="form-select" aria-label="select example">
                <option value="1">{user.state_name}</option>
                <option value="2">{t("Delhi")}</option>
              </select>
            </div>*/}
            {user.role != "GBC (Governing Body Commission)" && user.role != "Regional Zonal Secretary" && (
              <>
                <div className="col-md-6 input-bottom">
                  <label className="label-text mb-1">{t("Temple")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Enter your temple")}
                    // value={user.temple_name}
                    value={i18n.language === "hi" ? user.hinditempleName : user.temple_name}
                    disabled
                  />
                </div>
                <div className="col-md-6 input-bottom">
                  <label className="label-text mb-1">{t("Zone")}</label>
                  <input type="text" className="form-control" placeholder={t("Enter your zone")} value={t(user.zone_name)} disabled />
                </div>
              </>
            )}
          </div>
          <div className="row">
            {user.role != "GBC (Governing Body Commission)" && user.role != "Regional Zonal Secretary" && (
              <>
                <div className="col-md-6 input-bottom">
                  <label className="label-text mb-1">{t("State")}</label>
                  <input type="text" className="form-control" placeholder={t("Enter your state")} value={t(user.state_name)} disabled />
                </div>
                <div className="col-md-6 input-bottom">
                  <label className="label-text mb-1">{t("Note")}*</label>
                  <p style={{ color: "#6e717f" }}>
                    {t("Please be advised that if you wish to change your profile details, kindly contact us at")}{" "}
                    <a href="mailto:support@iskcon.com">support@iskcon.com</a>.
                  </p>
                </div>
              </>
            )}
            {/* <div className="col-md-6 input-bottom">
              <label className="label-text mb-1">{t("Zone")}</label>
              <select className="form-select" aria-label="select example">
                <option value="1">{user.zone_name}</option>
                <option value="2">{t("West Zone")}</option>
              </select>
              <label className="label-text mb-1 mt-2">{t("Choose Profile Photo")}</label>
              <div className="upload-photo mt-2">
                <FileUploader setUploadedFile={setUploadedFile} />
                {uploadedFile && <img src={uploadedFile} className="popular-img" />}
              </div>
            </div>
            <div className="col-md-6 input-bottom mt-4">
              <button className="notification-load-more">{t("Update Profile")}</button>
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
}
