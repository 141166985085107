// import React, { useEffect, useRef, useState } from "react";
// import CanvasJSReact from "@canvasjs/react-charts";
// import ApiService from "../../api/ApiService";
// import html2canvas from "html2canvas";
// import { tooltip } from "./toolTip";
// import { OverlayTrigger } from "react-bootstrap";
// import { useTranslation } from "react-i18next";

// const CanvasJSChart = CanvasJSReact.CanvasJSChart;

// export default function DrillChart({ zone = "", selectedEvent }) {
//   const [zoneData, setZoneData] = useState([]);
//   const { t, i18n } = useTranslation();

//   const getAllZoneList = async () => {

//     setZoneData([]);
//     const zoneColors = {};
//     try {
//       let response = [];
//       if (!zone) {
//         response = await ApiService.getAllZoneList(selectedEvent);

//         zoneColors["East Zone"] = "#AF4B91";
//         zoneColors["North Zone"] = "#41B0AA";
//         zoneColors["South Zone"] = "#E6A632";
//         zoneColors["West Zone"] = "#8070C9";
//       } else {
//         response = await ApiService.getTemplesLIstByZoneList(zone, selectedEvent);

//         zoneColors["Preaching Centre"] = "#AF4B91";
//         zoneColors["Medium Temple"] = "#41B0AA";
//         zoneColors["Extension Centre"] = "#E6A632 ";
//         zoneColors["Big Temple"] = "#8070C9 ";
//         zoneColors["Small Temple"] = "#02A0E1";
//         zoneColors["Outpost"] = "#D7642C";
//       }

//       if (response?.data?.length > 0) {
//         const coloredDataPoints = response.data.map((item) => ({
//           ...item,
//           color: zoneColors[item.name] || "#000000",
//         }));

//         setZoneData(coloredDataPoints);
//       }

//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     getAllZoneList();
//   }, [selectedEvent]);

//   const [options, setOptions] = useState({
//     animationEnabled: true,
//     // exportEnabled: true,
//     title: {
//       // text: zone ?? "Zone Wise Total Score",
//     },
//     subtitles: [
//       {
//         verticalAlign: "center",
//         fontSize: 24,
//         dockInsidePlotArea: true,
//       },
//     ],
//     height: zone ? 300 : 443,
//     data: [
//       {
//         type: "doughnut",
//         indexLabelPlacement: "inside",
//         showInLegend: true,
//         indexLabel: "{y}",
//         yValueFormatString: "#,###'%'",
//         radius: "100%",
//         dataPoints: [],
//         indexLabelFontColor: "white",
//       },
//     ],
//     legend: {
//       verticalAlign: "bottom",
//       horizontalAlign: "center",
//       fontSize: 12, // Adjust as needed
//       itemWidth: 120, // Adjust width to fit your design
//       // maxWidth: 600, // Adjust to fit the container width
//       // margin: 10, // Add margin if needed
//     },
//   });

//   const chartRef = useRef(null);

//   useEffect(() => {
//     if (chartRef.current) {
//       chartRef.current.render();
//     }

//     setOptions((state) => ({
//       ...state,
//       data: [
//         {
//           type: "doughnut",
//           indexLabelPlacement: "inside",
//           showInLegend: true,
//           indexLabel: "{y}",
//           yValueFormatString: "#,###'%'",
//           radius: "100%",
//           dataPoints: zoneData,
//           indexLabelFontColor: "white",
//         },
//       ],
//     }));
//   }, [options, zoneData]);

//   const handleDownload = () => {
//     html2canvas(document.querySelector(".zone-wise-stats" + zone?.toLocaleLowerCase()?.replaceAll(" ", "-"))).then((canvas) => {
//       const link = document.createElement("a");
//       link.href = canvas.toDataURL("image/png");
//       link.download = `${zone?.toLocaleLowerCase()?.replaceAll(" ", "-")}stats.png`;
//       link.click();
//     });
//   };

//   return (
//     <div className={"p-3 top-temples-card zone-wise-stats" + zone?.toLocaleLowerCase()?.replaceAll(" ", "-")}>
//       <div className="head-sec" style={{ border: "none" }}>
//         <div className="temple-list-head-sec">
//           <h2 className="welcome-text mt-1 graph-stats-div graphs-heading">{t(zone) ? <span>{t(zone)}</span> : t("Zone Wise Total Points")}</h2>
//         </div>
//         <span className="button-text-download" onClick={handleDownload}>
//           {t("Download")}
//         </span>
//       </div>
//       <div className={"stats-graph-card " + (!zone ? "mt-3" : "")}>
//         <CanvasJSChart options={options} onRef={(ref) => (chartRef.current = ref)} />
//       </div>
//     </div>
//   );
// }
import React, { useEffect, useRef, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import ApiService from "../../api/ApiService";
import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function DrillChart({ zone = "", selectedEvent }) {
  const [zoneData, setZoneData] = useState([]);
  const { t, i18n } = useTranslation();
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const getAllZoneList = async () => {
    setZoneData([]);
    const zoneColors = {};
    try {
      let response = [];
      if (!zone) {
        response = await ApiService.getAllZoneList(selectedEvent);

        zoneColors["East Zone"] = "#AF4B91";
        zoneColors["North Zone"] = "#41B0AA";
        zoneColors["South Zone"] = "#E6A632";
        zoneColors["West Zone"] = "#8070C9";
      } else {
        response = await ApiService.getTemplesLIstByZoneList(zone, selectedEvent);

        zoneColors["Preaching Centre"] = "#AF4B91";
        zoneColors["Medium Temple"] = "#41B0AA";
        zoneColors["Extension Centre"] = "#E6A632";
        zoneColors["Big Temple"] = "#8070C9";
        zoneColors["Small Temple"] = "#02A0E1";
        zoneColors["Outpost"] = "#D7642C";
      }

      if (response?.data?.length > 0) {
        console.log("response.data", response.data);

        const coloredDataPoints = response.data.map((item) => ({
          ...item,
          color: zoneColors[item.name] || "#000000",
        }));

        setZoneData(coloredDataPoints);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllZoneList();
  }, [selectedEvent]);

  const [options, setOptions] = useState({
    animationEnabled: true,
    // title: {
    //   text: zone ? t(zone) : t("Zone Wise Total Points"),
    // },
    subtitles: [
      {
        verticalAlign: "center",
        fontSize: 24,
        dockInsidePlotArea: true,
      },
    ],
    height: zone ? 300 : 443,
    data: [
      {
        type: "doughnut",
        indexLabelPlacement: "inside",
        showInLegend: true,
        indexLabel: "{y}",
        yValueFormatString: "#,###'%'",
        radius: "100%",
        dataPoints: [],
        indexLabelFontColor: "white",
      },
    ],
    legend: {
      verticalAlign: "bottom",
      horizontalAlign: "center",
      fontSize: 12,
      itemWidth: 120,
    },
  });

  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.render();
    }

    setOptions((state) => ({
      ...state,
      data: [
        {
          type: "doughnut",
          indexLabelPlacement: "inside",
          showInLegend: true,
          indexLabel: "{y}",
          yValueFormatString: "#,###'%'",
          radius: "100%",
          dataPoints: zoneData,
          indexLabelFontColor: "white",
        },
      ],
    }));
  }, [options, zoneData]);

  const handleDownload = () => {
    setIsButtonVisible(false);

    setTimeout(() => {
      html2canvas(document.querySelector(".zone-wise-stats" + zone?.toLocaleLowerCase()?.replaceAll(" ", "-"))).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = `${zone?.toLocaleLowerCase()?.replaceAll(" ", "-")}stats.png`;
        link.click();
      });
    }, 1000);

    setTimeout(() => {
      setIsButtonVisible(true);
    }, 1000);
  };

  return (
    <div className={"p-3 top-temples-card zone-wise-stats" + zone?.toLocaleLowerCase()?.replaceAll(" ", "-")}>
      <div className="head-sec" style={{ border: "none" }}>
        <div className="temple-list-head-sec">
          <h2 className="welcome-text mt-1 graph-stats-div graphs-heading">{t(zone) ? <span>{t(zone)}</span> : t("Zone Wise Total Points")}</h2>
        </div>
        {isButtonVisible && (
          <span className="button-text-download" onClick={handleDownload}>
            {t("Download")}
          </span>
        )}
      </div>
      <div className={"stats-graph-card " + (!zone ? "mt-3" : "")}>
        <CanvasJSChart options={options} onRef={(ref) => (chartRef.current = ref)} />
      </div>
    </div>
  );
}
