// import React, { useState } from "react";
// import { Calendar } from "react-date-range";
// import { format } from "date-fns";
// import PropTypes from "prop-types";
// import "react-date-range/dist/styles.css";
// import "react-date-range/dist/theme/default.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../../css/RecordingOfScores.css";
// import { useTranslation } from "react-i18next";

// export default function DataRangePicker({ selectedDate, setSelectedDate }) {
//   const [showDateRangeSelector, setShowDateRangeSelector] = useState(false);
//   const { t, i18n } = useTranslation();

//   const handleChange = (date) => {
//     const formattedDate = format(date, "yyyy-MM-dd");
//     setSelectedDate(formattedDate);
//     setShowDateRangeSelector(false);
//   };

//   const handleDone = () => {
//     setShowDateRangeSelector(false);
//   };

//   const handleCancel = () => {
//     setShowDateRangeSelector(false);
//   };

//   const handleInputFocus = () => {
//     setShowDateRangeSelector(true);
//   };

//   // Function to get today's date
//   const getToday = () => {
//     const today = new Date();
//     today.setHours(0, 0, 0, 0);
//     return today;
//   };

//   const getStartOfCurrentYear = () => {
//     const today = new Date();
//     return new Date(today.getFullYear(), 0, 1);
//   };

//   return (
//     <div>
//       <div>
//         <input
//           type="text"
//           placeholder={t("Select Date")}
//           onFocus={handleInputFocus}
//           value={selectedDate ? format(selectedDate, "dd-MM-yyyy") : ""}
//           readOnly
//           className="form-control date-range-input full-width"
//         />
//         {showDateRangeSelector && (
//           <>
//             <Calendar
//               date={selectedDate}
//               onChange={handleChange}
//               minDate={getStartOfCurrentYear()} // Disable previous year dates
//               maxDate={getToday()} // Disable future dates
//               dateDisplayFormat="dd-MM-yyyy"
//               showDateDisplay={false}
//               showMonthAndYearPickers={false}
//               showDateRange={false}
//             />
//             {/* <div className="text-right position-relative rdr-buttons-position mt-2 mr-3">
//               <button className="btn btn-transparent text-primary rounded-0 px-4 mr-2" onClick={handleDone}>
//                 Apply
//               </button>
//               <button className="btn btn-transparent text-danger rounded-0 px-4" onClick={handleCancel}>
//                 Cancel
//               </button>
//             </div> */}
//           </>
//         )}
//       </div>
//     </div>
//   );
// }

// DataRangePicker.propTypes = {
//   selectedDate: PropTypes.instanceOf(Date),
//   setSelectedDate: PropTypes.func.isRequired,
// };
import React, { useState } from "react";
import { Calendar } from "react-date-range";
import { format } from "date-fns";
import PropTypes from "prop-types";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/RecordingOfScores.css";
import { useTranslation } from "react-i18next";

export default function DataRangePicker({ selectedDate, setSelectedDate }) {
  const [showDateRangeSelector, setShowDateRangeSelector] = useState(false);
  const { t } = useTranslation();

  const handleChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setSelectedDate(formattedDate);
    setShowDateRangeSelector(false);
  };

  const handleInputFocus = () => {
    setShowDateRangeSelector(true);
  };

  // Function to get today's date
  const getToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };

  // Function to get the date of 3 years ago
  const getThreeYearsAgo = () => {
    const today = new Date();
    return new Date(today.getFullYear() - 3, today.getMonth(), today.getDate());
  };

  return (
    <div>
      <div>
        <input
          type="text"
          placeholder={t("Select Date")}
          onFocus={handleInputFocus}
          value={selectedDate ? format(new Date(selectedDate), "dd-MM-yyyy") : ""}
          readOnly
          className="form-control date-range-input full-width"
        />
        {showDateRangeSelector && (
          <>
            <Calendar
              date={selectedDate ? new Date(selectedDate) : getToday()}
              onChange={handleChange}
              minDate={getThreeYearsAgo()} // Allow dates from 3 years ago
              maxDate={getToday()} // Disable future dates
              dateDisplayFormat="dd-MM-yyyy"
              showDateDisplay={false}
              showMonthAndYearPickers={true} // Enable month and year pickers
            />
          </>
        )}
      </div>
    </div>
  );
}

DataRangePicker.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  setSelectedDate: PropTypes.func.isRequired,
};
