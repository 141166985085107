import axios from "axios";
import history from "../history";

//Configuration
const fetch = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL + "/api/",
  timeout: 60000,
});

fetch.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem("token");
    if (jwtToken) {
      config.headers["Authorization"] = "Bearer " + jwtToken;
    }
    if (!jwtToken && !config.headers["public-request"]) {
      history.push("/");
      window.location.reload();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

fetch.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error);

    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      history.push("/");
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

const ApiService = {};

ApiService.fetchVideos = () => {
  return fetch({
    url: "/why-krishna",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.sendOtp = (data) => {
  return fetch({
    url: `/send-otp?number=${data.number}`,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.loginUser = (data) => {
  return fetch({
    url: `/login`,
    data,
    method: "POST",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.iccScoreSubmit = (data) => {
  return fetch({
    url: "/scoresubmit",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.recordingPledgeScoreSubmit = (data) => {
  return fetch({
    url: "/recordingpledgesubmit",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchPledgeCount = (id, event, isTemple = false) => {
  return fetch({
    url: `/pledgeCount/list/${id}/${event}/${isTemple}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchSubmittedScoreCount = (userId, event, date = "date", isTemple = false) => {
  return fetch({
    url: "/submittedScore/" + userId + "/" + event + (date != "date" ? "/" + date : "/date") + "/" + isTemple,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchSubmittedScoreRegularEvent = (id, event, isTemple = false) => {
  return fetch({
    url: "/get-submitted-score-regular-event/" + id + "/" + event + "/" + isTemple,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchPoints = () => {
  return fetch({
    url: "/bookPoints/list",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchZoneWiseTotalScores = () => {
  return fetch({
    url: "/zone-total-scores",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchFinancialYear = () => {
  return fetch({
    url: "/financial_year",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchTempleWiseTotalScores = () => {
  return fetch({
    url: "/templewise-total-scores",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDataFromAllLiveRankingByUserId = async (templeId, zoneId, temple, state_id, event) => {
  return fetch({
    url: `/fetch-user-current-ranking/${templeId}/${zoneId}/${temple}/${state_id}/${event}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchUserPreviousYearRanking = async (templeId, zoneId, temple, state_id, event) => {
  return fetch({
    url: `/fetch-user-previous-ranking/${templeId}/${zoneId}/${temple}/${state_id}/${event}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDataFromByDefaulFinancialY = (financial_year) => {
  return fetch({
    url: `/fetch_financial_yeardata/${financial_year}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};
ApiService.fetchDataByFinancialandEvents = (financial_year, events) => {
  return fetch({
    url: `/fetch_financial_year_and_eventdata/${financial_year}/${events}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDataByFyearEventZone = (financial_year, events, zonewise) => {
  const url = events
    ? `/fetch_financial_year_and_event_zonewisedata/${financial_year}/${events}/${zonewise}`
    : `/fetch_financial_year_and_event_zonewisedata/${financial_year}/all/${zonewise}`;

  return fetch({
    url: url,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDataByFyearEventBookWise = (financial_year, events, zonewise) => {
  // console.log("checkcallapi");
  return fetch({
    url: `/fetch_financial_year_and_event_bookewisedata/${financial_year}/${events ? events : "all"}/${zonewise}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDataByFyearEventTemple = (financial_year, events, zonewise) => {
  return fetch({
    url: `/fetch_financial_year_and_event_templewisedata/${financial_year}/${events ? events : "all"}/${zonewise}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDataByFyearEventState = (financial_year, events, zonewise) => {
  return fetch({
    url: `/fetch_financial_year_and_event_statewisedata/${financial_year}/${events ? events : "all"}/${zonewise}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDataByFyearEventZoneandSelected = (financial_year, events, zonewise, selectedZone) => {
  // console.log("financial_year, events, zonewise, selectedZone", financial_year, events, zonewise, selectedZone);
  return fetch({
    url: `/fetch_financial_year_and_event_selectedzonedata/${financial_year}/${events ? events : "all"}/${zonewise}/${selectedZone}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDataBOnFSEvtSzoneforTempleWise = (financial_year, events, zonewise, selectedZone, templecategory) => {
  // console.log("financial_year, events, zonewise, selectedZone", financial_year, events, zonewise, selectedZone, templecategory);
  return fetch({
    url: `/fetch_timplewise_data_basedon_fez/${financial_year}/${events ? events : "all"}/${zonewise}/${selectedZone}/${templecategory}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDataByFyearEventTempleandSelected = (financial_year, events, zonewise, selectedcategory) => {
  // console.log("financial_year, events, zonewise, selectedZone", financial_year, events, zonewise, selectedcategory);
  return fetch({
    url: `/fetch_financial_year_and_event_selectedtempledata/${financial_year}/${events ? events : "all"}/${zonewise}/${selectedcategory}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};
ApiService.fetchDataByFyearEventStateandSelected = (financial_year, events, zonewise, states) => {
  // console.log("financial_year, events, zonewise, selectedZone", financial_year, events, zonewise, states);
  return fetch({
    url: `/fetch_financial_year_and_event_selectedstatedata/${financial_year}/${events ? events : "all"}/${zonewise}/${states}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDataBookWiseWithZone = (financial_year, events, zonewise, bookwisezone) => {
  // console.log("bookwisezone", financial_year, events, zonewise, bookwisezone);
  return fetch({
    url: `/fetch_data_bookwise_withzone/${financial_year}/${events ? events : "all"}/${zonewise}/${bookwisezone}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDataBookWiseWithZoneAndTemple = (financial_year, events, zonewise, bookwisezone, bookwisetemple) => {
  // console.log("bookwisezonewithtemple", financial_year, events, zonewise, bookwisezone, bookwisetemple);
  return fetch({
    url: `/fetch_data_bookwise_withzoneandtemple/${financial_year}/${events ? events : "all"}/${zonewise}/${bookwisezone}/${bookwisetemple}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDataWithYECAndZone = (financial_year, event, filterBy, category, categoryandzone) => {
  // Log the parameters for debugging purposes (optional)
  // console.log("fetchDataWithYECAndZone params", financial_year, event, filterBy, category, categoryandzone);

  return fetch({
    url: `/fetch_data_with_yec_and_zone/${financial_year}/${event ? event : "all"}/${filterBy}/${category}/${categoryandzone}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getAllTransactions = (templeId, page, event, year) => {
  return fetch({
    url: "/get-all-score-transactions-by-temple/" + templeId + "/" + page + "/" + event + "/" + year,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getAllTransactionsForPledge = (templeId, page, event, year) => {
  return fetch({
    url: "/get-all-pledge-transactions-by-temple/" + templeId + "/" + page + "/" + event + "/" + year,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getHistoryDownload = (templeId, event, year, type) => {
  return fetch({
    url: "/get-history-download/" + templeId + "/" + event + "/" + year + "/" + type,
    method: "GET",
    headers: {
      "public-request": "true",
      Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  });
};

ApiService.getBlogsListData = (searchKey, category, page) => {
  const searchWord = searchKey != "" ? searchKey.replace(/[^\w\s]/gi, "") : "false";
  return fetch({
    url: "/get-blogs-list/" + searchWord + "/" + category + "/" + page,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getBlogDetailsById = (bogId) => {
  return fetch({
    url: "/get-blog-details/" + bogId,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getVideosListData = (category, page) => {
  return fetch({
    url: "/get-videos-list/" + category + "/" + page,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.postBlogComments = (data) => {
  return fetch({
    url: "/post-user-blog-comments",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getBlogComments = (blogId) => {
  return fetch({
    url: "/get-user-blog-comments/" + blogId,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.postBlogUserReplies = (data) => {
  return fetch({
    url: "/post-user-blog-comments-reply",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.viewAllUserReplies = (blogId, commentId, replyId) => {
  return fetch({
    url: "/get-user-blog-comments-replies/" + blogId + "/" + commentId + "/" + replyId,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.updateMostPopularBlogsCount = (blogId) => {
  return fetch({
    url: "/update-blog-popular-count/" + blogId,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getBlogsListData = (searchKey, category, page) => {
  const searchWord = searchKey != "" ? searchKey.replace(/[^\w\s]/gi, "") : "false";

  return fetch({
    url: "/get-blogs-list/" + searchWord + "/" + category + "/" + page,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.userForumQuestion = (data) => {
  return fetch({
    url: "/post-user-forum-question",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getAllForumList = (searchKey, category, page, filterOption) => {
  const searchWord = searchKey != "" ? searchKey.replace(/[^\w\s]/gi, "") : "false";

  return fetch({
    url: "/get-user-forum-question-list/" + searchWord + "/" + category + "/" + filterOption + "/" + page,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getForumDetailsById = (forumId) => {
  return fetch({
    url: "/get-forum-details/" + forumId,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.postForumAnswer = (data) => {
  return fetch({
    url: "/post-user-forum-answers",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getForumAnswer = (forumId, page) => {
  return fetch({
    url: "/get-user-forum-answers/" + forumId + `?page=${page}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.upVoteForumAnswer = (data) => {
  return fetch({
    url: "/update-forum-upvotes-count",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.postAnswerComment = (data) => {
  return fetch({
    url: "/post-user-forum-answers-comments",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getCommentsForForumByAnswerId = (answerId) => {
  return fetch({
    url: "/get-forum-comments-by-answers/" + answerId,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.viewAllForumUserReplies = (commentId, answerId, forumId) => {
  return fetch({
    url: "/get-user-forum-comments-replies/" + commentId + "/" + answerId + "/" + forumId,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.postForumUserReplies = (data) => {
  return fetch({
    url: "/post-user-forum-comments-reply",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.deleteForumByUserId = (data) => {
  return fetch({
    url: "/delete-user-forum-by-id",
    method: "DELETE",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.deleteForumAnswerByUserId = (data) => {
  return fetch({
    url: "/delete-user-forum-answer-by-id",
    method: "DELETE",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getCurrentAndPreviousBooksCount = (temple_id, event) => {
  return fetch({
    url: "/get-current-previous-books-count/" + temple_id + "/" + event,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getCurrentAndPreviousPointsCount = (temple_id, event) => {
  return fetch({
    url: "/get-current-previous-points-count/" + temple_id + "/" + event,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getTopTemplesList = (event, temple) => {
  return fetch({
    url: "/get-top-ten-temples-list/" + event + "/" + temple,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getAllZoneList = (event) => {
  return fetch({
    url: "/get-all-zones-list/" + event,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getTemplesLIstByZoneList = (zone, event) => {
  return fetch({
    url: "/get-temple-list-zones-list/" + zone + "/" + event,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getTempleAndBooksDataByZones = (type, event) => {
  return fetch({
    url: "/get-temple-and-books-list/" + type + "/" + event,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getStateWisePointsForMap = (event) => {
  return fetch({
    url: "/get-points-by-state/" + event,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getUserNotifications = (page, userId) => {
  return fetch({
    url: "/get-user-notifications/" + userId + `?page=${page}`,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchDataFromByForCompare = (financial_year, selecte_events) => {
  let url;
  if (selecte_events) {
    url = `/fetch_compare_yeardata/${financial_year}/${selecte_events}`;
  } else {
    url = `/fetch_compare_yeardata/${financial_year}/all`;
  }

  return fetch({
    url: url,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.userNeedHelp = (data) => {
  return fetch({
    url: "/post-user-need-help",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getNotificationDetailsById = (id) => {
  return fetch({
    url: "/get-notification-details/" + id,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.userReadNotification = (data) => {
  return fetch({
    url: "/user-notification-status",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.userDeleteNotification = (data) => {
  return fetch({
    url: "/user-delete-notification",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getUserNotificationStatus = (userId, notificationId) => {
  return fetch({
    url: "/get-user-notification-status/" + userId + "/" + notificationId,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getUserNotificationDeletedStatus = (userId, notificationId) => {
  return fetch({
    url: "/get-user-delete-notification-status/" + userId + "/" + notificationId,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getUserUnReadNotificationCount = (userId) => {
  return fetch({
    url: "/get-user-new-notification-cont/" + userId,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getResourcesList = (category) => {
  return fetch({
    url: "/get-resources-list/" + category,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.uploadBulkScore = (data) => {
  // console.log("data", data);

  return fetch({
    url: "/uploadbulkscore",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getOverAllIndiaPledgeStatus = (event) => {
  return fetch({
    url: "/get-all-data-india-pledge/" + event,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.fetchScoreLiveUpdates = () => {
  return fetch({
    url: "/get-score-live-updates",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};
// In ApiService.js or your API service file
ApiService.fetchQuotes = () => {
  return fetch({
    url: "/quotes",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getRSACurrentAndPreviousBooksCount = (data) => {
  return fetch({
    url: "/get-data-bookcount_for_ras",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getRSACurrentAndPreviousPointsCount = (data) => {
  return fetch({
    url: "/get-data-pointscount_for_ras",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getRSATopTemplesList = (data) => {
  return fetch({
    url: "/get-data-gettopten_temple_for_ras",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getRSACategoryWiseBookCount = (data) => {
  return fetch({
    url: "/get-rsa-category-book-wise-count",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getRSAPledgeData = (data) => {
  return fetch({
    url: "/get-rsa-pledge-data",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getRSAScoreData = (data) => {
  return fetch({
    url: "/get-rsa-score-data",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getCombineTempleData = (data) => {
  return fetch({
    url: "/get-rsa-score-and-pledge-data",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getRSATempleStats = (data) => {
  return fetch({
    url: "/get-rsa-current-previous-year-score-data",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getRSATempleList = (data) => {
  return fetch({
    url: "/get-rsa-temple-list",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getAllTemplesList = () => {
  return fetch({
    url: "/get-all-temples-list",
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getAllTemplesPledgeData = (event) => {
  return fetch({
    url: "/get-overall-pledge-data/" + event,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

ApiService.getAllTemplesScore = (event) => {
  return fetch({
    url: "/get-overall-score-data/" + event,
    method: "GET",
    headers: {
      "public-request": "true",
    },
  });
};

export default ApiService;
