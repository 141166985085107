import React, { useState } from "react";
import "../../css/RecordingOfScores.css";
import "../../css/app.css";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import { useTranslation } from "react-i18next";

export default function Header() {
  const { t, i18n } = useTranslation();
  const { user, notificationsCount } = React.useContext(AppContext);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUserCardOpen, setIsUserCardOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("en"); // Track the current language

  const navigate = useNavigate();

  const toggleResourcesDropdown = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const toggleUserDropdown = () => {
    setIsUserCardOpen((prevState) => !prevState);
  };

  const toggleNav = () => {
    const navbarTogglerIcon = document.getElementsByClassName("mobile-nav-open")[0];
    if (navbarTogglerIcon && navbarTogglerIcon.offsetParent !== null) {
      const isMenuOpen = !isNavOpen;
      setIsNavOpen(isMenuOpen);
      if (isMenuOpen) {
        document.body.classList.add("mobile-menu-open");
      } else {
        document.body.classList.remove("mobile-menu-open");
      }
    }
  };

  const userLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.reload();
  };

  const ProfileNotification = () => {
    if (window.location.pathname !== "/my-profile") {
      localStorage.setItem("user-notification", true);
      navigate("/my-profile");
    }
  };

  const changeLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "hi" : "en";
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
  };

  const headerMenu = [
    { name: t("Dashboard"), link: "/" },
    { name: t("Live Ranking"), link: "/live-ranking" },
    { name: t("Pledges"), link: "/recording-pledge" },
    { name: t("Update Score"), link: "/update-score" },
    { name: t("Resources"), link: "" },
    { name: t("Forum"), link: "/forum" },
    { name: t("Contact Us"), link: "/contact-us" },
  ];

  if (user.role == "Regional Zonal Secretary" || user.role == "GBC (Governing Body Commission)") {
    headerMenu.splice(2, 0, { name: t("Temple stats"), link: "/temple-stats" });
  }

  const menuList = [
    { name: t("Videos"), link: "/videos" },
    { name: t("Blogs"), link: "/blogs" },
    { name: t("Posters"), link: "/posters" },
    { name: t("Quotes"), link: "/quotes" },
  ];

  return (
    <header className={"recording-of-scores" + (isNavOpen ? " mobile-responsive" : "")}>
      <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top shadow-sm">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src="/images/common/iskcon_logo_1.png" alt="Your Logo" width="75" height="75" />
          </Link>
          <div className="mobile-notification col-md-4">
            <div onClick={() => ProfileNotification()} className="notification-icon">
              <svg width="51" height="54" viewBox="0 0 51 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="25.5" cy="28.5" r="25.5" fill="#F3F3F3" />
                <g clipPath="url(#clip0_1644_391)">
                  <path
                    d="M34.7965 29.385L33.2132 23.6883C32.7491 22.0195 31.7405 20.5536 30.3477 19.5237C28.9548 18.4939 27.2577 17.9592 25.5261 18.0046C23.7945 18.05 22.1277 18.6729 20.7908 19.7743C19.4538 20.8757 18.5234 22.3924 18.1474 24.0833L16.9216 29.5958C16.7861 30.205 16.7893 30.8367 16.9307 31.4444C17.0721 32.0522 17.3483 32.6204 17.7387 33.1071C18.1291 33.5939 18.6239 33.9867 19.1865 34.2566C19.7491 34.5266 20.3651 34.6667 20.9891 34.6667H21.9174C22.1086 35.6086 22.6197 36.4554 23.3638 37.0637C24.108 37.6719 25.0396 38.0042 26.0007 38.0042C26.9618 38.0042 27.8934 37.6719 28.6376 37.0637C29.3818 36.4554 29.8928 35.6086 30.0841 34.6667H30.7824C31.4247 34.6667 32.0584 34.5183 32.6338 34.2329C33.2093 33.9475 33.711 33.5329 34.0997 33.0216C34.4885 32.5102 34.7537 31.9159 34.8747 31.285C34.9957 30.6542 34.9684 30.0039 34.7965 29.385ZM26.0007 36.3333C25.4855 36.3312 24.9835 36.1699 24.5635 35.8716C24.1434 35.5733 23.8258 35.1524 23.6541 34.6667H28.3474C28.1756 35.1524 27.858 35.5733 27.438 35.8716C27.0179 36.1699 26.5159 36.3312 26.0007 36.3333ZM32.7724 32.0125C32.5402 32.3206 32.2393 32.5702 31.8937 32.7416C31.5481 32.9131 31.1673 33.0015 30.7815 33H20.9891C20.6147 32.9999 20.2452 32.9158 19.9077 32.7538C19.5702 32.5918 19.2734 32.3561 19.0392 32.0641C18.805 31.7721 18.6394 31.4312 18.5545 31.0665C18.4697 30.7019 18.4678 30.3229 18.5491 29.9575L19.7741 24.4442C20.0693 23.116 20.8001 21.9247 21.8503 21.0595C22.9004 20.1944 24.2096 19.7051 25.5697 19.6695C26.9298 19.6339 28.2628 20.0539 29.3568 20.8629C30.4508 21.6719 31.243 22.8233 31.6074 24.1342L33.1907 29.8308C33.2953 30.202 33.312 30.5924 33.2394 30.9711C33.1668 31.3498 33.0069 31.7064 32.7724 32.0125Z"
                    fill="#43567D"
                  />
                </g>
                {/* <circle cx="40" cy="13" r="10" fill="#FF5656" /> */}
                <path
                  d="M36.6545 16V15.12L38.321 13.5965C38.849 13.1125 39.2743 12.6963 39.597 12.348C39.9197 11.996 40.1543 11.6788 40.301 11.3965C40.4477 11.1142 40.521 10.8282 40.521 10.5385C40.521 10.1168 40.3762 9.78867 40.0865 9.554C39.8005 9.31567 39.4008 9.1965 38.8875 9.1965C38.5465 9.1965 38.2202 9.25517 37.9085 9.3725C37.5968 9.48983 37.331 9.66033 37.111 9.884L36.66 8.9875C36.9717 8.70883 37.331 8.498 37.738 8.355C38.145 8.20833 38.5722 8.135 39.0195 8.135C39.5512 8.135 40.015 8.23033 40.411 8.421C40.8107 8.608 41.1205 8.87383 41.3405 9.2185C41.5642 9.5595 41.676 9.95917 41.676 10.4175C41.676 10.7365 41.6118 11.0555 41.4835 11.3745C41.3588 11.6898 41.1333 12.0455 40.807 12.4415C40.4843 12.8375 40.026 13.3123 39.432 13.866L38.2495 14.955H41.8685V16H36.6545Z"
                  fill="white"
                />
                <defs>
                  <clipPath id="clip0_1644_391">
                    <rect width="20" height="20" fill="white" transform="translate(16 18)" />
                  </clipPath>
                </defs>
              </svg>
              {notificationsCount > 0 && <span className="notification-count-mobile mobile-nav-open">{notificationsCount}</span>}
            </div>
            <button
              className="navbar-toggler burger-background"
              type="button"
              onClick={() => {
                setIsNavOpen(!isNavOpen);
                toggleNav();
              }}
              aria-controls="navbarSupportedContent"
              aria-expanded={isNavOpen ? "true" : "false"}
              aria-label="Toggle navigation"
            >
              {!isNavOpen ? (
                <span className="navbar-toggler-icon"></span>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x-lg mobile-nav-open"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                </svg>
              )}
            </button>
          </div>
          <div className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`} id="navbarSupportedContent">
            <div className="user-header-section">
              {isNavOpen && (
                <a href="/my-profile" className="d-flex align-items-left user-header-div">
                  <img className="rounded-circle temple-image-responsive" src="/images/common/temple-image.png" width="48" height="48" />
                  <div className="header-name user-name-responsive">
                    <h4 className="mb-0 mt-1 header-name">{user?.name}</h4>
                    {user.role != "GBC (Governing Body Commission)" && user.role != "Regional Zonal Secretary" && (
                      <small className="header-name-span">{user?.temple_name}</small>
                    )}
                  </div>
                </a>
              )}
            </div>
            <ul className="navbar-nav ml-auto">
              {headerMenu.map((menulist, i) => (
                <React.Fragment key={i}>
                  {menulist.name !== t("Resources") ? (
                    <>
                      {(user.role === "GBC (Governing Body Commission)" || user.role == "Regional Zonal Secretary") &&
                      (menulist.name === t("Pledges") || menulist.name === t("Update Score")) ? null : (
                        <li className="nav-item active" key={i} onClick={toggleNav}>
                          <Link className="nav-link nav-menu-list" to={menulist.link}>
                            {menulist.name}
                          </Link>
                        </li>
                      )}
                    </>
                  ) : (
                    <li
                      className="nav-item active header-menu-drop-down"
                      key={i}
                      onMouseEnter={toggleResourcesDropdown}
                      onMouseLeave={toggleResourcesDropdown}
                    >
                      <a className="nav-link nav-menu-list">
                        {menulist.name}
                        &nbsp;
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-caret-down-fill mt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                      </a>
                      {isMenuOpen && (
                        <ul className="header-drop-down-list header-drop-down-card">
                          {menuList.map((menu, i) => (
                            <li key={i} className=" sub-menu-list">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-caret-right-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                              </svg>{" "}
                              <Link
                                className="text-left"
                                onClick={() => {
                                  if (isNavOpen) {
                                    toggleNav();
                                  } else {
                                    toggleResourcesDropdown();
                                  }
                                }}
                                to={menu.link}
                              >
                                {menu.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
            {!isNavOpen && (
              <>
                <div className="d-flex">
                  <div onClick={() => ProfileNotification()} className="notification-icon">
                    {/* <img
                      className="rounded-circle notification-image"
                      src="https://static.vecteezy.com/system/resources/previews/010/366/210/original/bell-icon-transparent-notification-free-png.png"
                      alt="Your Logo"
                      height="50"
                    /> */}
                    <svg width="51" height="54" viewBox="0 0 51 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="25.5" cy="28.5" r="25.5" fill="#F3F3F3" />
                      <g clipPath="url(#clip0_1644_391)">
                        <path
                          d="M34.7965 29.385L33.2132 23.6883C32.7491 22.0195 31.7405 20.5536 30.3477 19.5237C28.9548 18.4939 27.2577 17.9592 25.5261 18.0046C23.7945 18.05 22.1277 18.6729 20.7908 19.7743C19.4538 20.8757 18.5234 22.3924 18.1474 24.0833L16.9216 29.5958C16.7861 30.205 16.7893 30.8367 16.9307 31.4444C17.0721 32.0522 17.3483 32.6204 17.7387 33.1071C18.1291 33.5939 18.6239 33.9867 19.1865 34.2566C19.7491 34.5266 20.3651 34.6667 20.9891 34.6667H21.9174C22.1086 35.6086 22.6197 36.4554 23.3638 37.0637C24.108 37.6719 25.0396 38.0042 26.0007 38.0042C26.9618 38.0042 27.8934 37.6719 28.6376 37.0637C29.3818 36.4554 29.8928 35.6086 30.0841 34.6667H30.7824C31.4247 34.6667 32.0584 34.5183 32.6338 34.2329C33.2093 33.9475 33.711 33.5329 34.0997 33.0216C34.4885 32.5102 34.7537 31.9159 34.8747 31.285C34.9957 30.6542 34.9684 30.0039 34.7965 29.385ZM26.0007 36.3333C25.4855 36.3312 24.9835 36.1699 24.5635 35.8716C24.1434 35.5733 23.8258 35.1524 23.6541 34.6667H28.3474C28.1756 35.1524 27.858 35.5733 27.438 35.8716C27.0179 36.1699 26.5159 36.3312 26.0007 36.3333ZM32.7724 32.0125C32.5402 32.3206 32.2393 32.5702 31.8937 32.7416C31.5481 32.9131 31.1673 33.0015 30.7815 33H20.9891C20.6147 32.9999 20.2452 32.9158 19.9077 32.7538C19.5702 32.5918 19.2734 32.3561 19.0392 32.0641C18.805 31.7721 18.6394 31.4312 18.5545 31.0665C18.4697 30.7019 18.4678 30.3229 18.5491 29.9575L19.7741 24.4442C20.0693 23.116 20.8001 21.9247 21.8503 21.0595C22.9004 20.1944 24.2096 19.7051 25.5697 19.6695C26.9298 19.6339 28.2628 20.0539 29.3568 20.8629C30.4508 21.6719 31.243 22.8233 31.6074 24.1342L33.1907 29.8308C33.2953 30.202 33.312 30.5924 33.2394 30.9711C33.1668 31.3498 33.0069 31.7064 32.7724 32.0125Z"
                          fill="#43567D"
                        />
                      </g>
                      {/* <circle cx="40" cy="13" r="10" fill="#FF5656" /> */}
                      <path
                        d="M36.6545 16V15.12L38.321 13.5965C38.849 13.1125 39.2743 12.6963 39.597 12.348C39.9197 11.996 40.1543 11.6788 40.301 11.3965C40.4477 11.1142 40.521 10.8282 40.521 10.5385C40.521 10.1168 40.3762 9.78867 40.0865 9.554C39.8005 9.31567 39.4008 9.1965 38.8875 9.1965C38.5465 9.1965 38.2202 9.25517 37.9085 9.3725C37.5968 9.48983 37.331 9.66033 37.111 9.884L36.66 8.9875C36.9717 8.70883 37.331 8.498 37.738 8.355C38.145 8.20833 38.5722 8.135 39.0195 8.135C39.5512 8.135 40.015 8.23033 40.411 8.421C40.8107 8.608 41.1205 8.87383 41.3405 9.2185C41.5642 9.5595 41.676 9.95917 41.676 10.4175C41.676 10.7365 41.6118 11.0555 41.4835 11.3745C41.3588 11.6898 41.1333 12.0455 40.807 12.4415C40.4843 12.8375 40.026 13.3123 39.432 13.866L38.2495 14.955H41.8685V16H36.6545Z"
                        fill="white"
                      />
                      <defs>
                        <clipPath id="clip0_1644_391">
                          <rect width="20" height="20" fill="white" transform="translate(16 18)" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="notification-count">{notificationsCount}</span>
                  </div>
                  <div className="d-flex align-items-left profile-icon" onMouseEnter={toggleUserDropdown} onMouseLeave={toggleUserDropdown}>
                    <img className="rounded-circle temple-img-desktop" src="/images/common/temple-image.png" width="50" height="50" />
                    <div className="user-details w-200 text-start">
                      <h4 className={"mb-0 header-name " + (user.role !== "GBC (Governing Body Commission)" ? "user-padding" : "gbc-padding")}>
                        {i18n.language === "hi" ? user?.hindiname : user?.name}{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-caret-down-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                      </h4>
                      {user.role != "GBC (Governing Body Commission)" && user.role != "Regional Zonal Secretary" && (
                        <small className="header-name-span">{i18n.language === "hi" ? user?.hinditempleName : user?.temple_name}</small>
                      )}
                    </div>
                    {isUserCardOpen && (
                      <div className="user-drop-down">
                        <ul className=" user-drop-down-card">
                          <div className="user-card-pointer"></div>
                          <li className="sub-menu-list" onClick={toggleUserDropdown}>
                            <Link className="text-left" to="/my-profile">
                              {t("My Profile")}
                            </Link>
                          </li>
                          <li className="sub-menu-list" onClick={toggleUserDropdown}>
                            <a className="text-left" onClick={changeLanguage}>
                              {currentLanguage === "en" ? "हिन्दी" : "English"}
                            </a>
                          </li>
                          <li className="sub-menu-list" onClick={toggleUserDropdown}>
                            <a onClick={() => userLogOut()} className="text-left logout-text">
                              {t("Logout Account")}
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                {/* <div className="mt-2">
                  <button onClick={() => userLogOut()} className="logout-button">
                    {t("Logout Account")}
                  </button>
                </div>
                <div className="mt-2">
                  <a href="#" onClick={changeLanguage}>
                    {currentLanguage === "en" ? "हिन्दी" : "English"}
                  </a>
                </div> */}
              </>
            )}
            {isNavOpen && (
              <div
                className="header-user-logout mt-4"
                onClick={() => {
                  userLogOut();
                }}
              >
                <h5 className="header-logout-text">{t("Logout Account")}</h5>
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}
