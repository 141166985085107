import { useEffect, useState } from "react";
import "../css/blogStyles.css";
import { Link, useParams } from "react-router-dom";
import ApiService from "../api/ApiService";
import moment from "moment/moment";
import Pagination from "../components/common/pagination";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import GoogleTranslate from "../components/common/GoogleTranslate";

export default function BlogsAndArticles() {
  const { t, i18n } = useTranslation();
  // console.log("language", i18n.language)
  const categories = [
    "All Categories",
    "Srimad Bhagavatam",
    "Gita Marathon",
    "CC Marathon",
    "Book Pricing",
    "History of Book Distribution",
    "Monthly Sankirtan Festival",
    "Vidya Daan",
    "Corporate Book Distribution",
    "Success Stories",
    "Prabhupada Distributing Books",
    "For Prabhupada's Pleasure",
    "Others",
  ];

  const { id } = useParams();
  const [blogsData, setBlogsData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(typeof id != "undefined" ? id : categories[0]);
  // console.log("selectedCategory", selectedCategory)
  const [totalPagesCont, setTotalPagesCont] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState("");

  const getBlogsList = async () => {
    setBlogsData([]);
    const response = await ApiService.getBlogsListData(searchKey, selectedCategory, pageNumber);
    if (response.status) {
      setBlogsData(response.data);
      setTotalPagesCont(response.total_count);
    }
  };

  useEffect(() => {
    getBlogsList();
  }, [searchKey, selectedCategory, pageNumber]);

  useEffect(() => {
    if (typeof id != "undefined") {
      setSelectedCategory(id);
    } else {
      setSelectedCategory(categories[0]);
    }
  }, [id]);

  const convertDateFormate = (dateString) => {
    return moment(dateString).format("DD MMM, YYYY");
  };

  const updateMostPopularBlogs = async (blogId) => {
    return await ApiService.updateMostPopularBlogsCount(blogId);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setSearchKey(event.target.value);
      setPageNumber(1);
      setSelectedCategory(categories[0]);
    }
  };

  const resetSearchFilter = () => {
    const searchInput = document.getElementById("search-input");
    setSearchKey("");
    setSelectedCategory(categories[0]);
    setPageNumber(1);
    searchInput.value = "";
  };

  const tooltip = <Tooltip id="tooltip-example">Reset All</Tooltip>;

  return (
    <div className="blog-wrapper container">
      <div className="blog-top-header row">
        <div className="col-lg-4">
          <h4 className="blog-heading mt-3">{t("Blog And Articles")}</h4>
        </div>
        <div className="blog-filters-row col-lg-4">
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
          <div className="input-group">
            <div className="input-group-prepend search-width">
              <span className="input-group-text search-span" id="basic-addon1">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.61745 1.9435L3.61746 1.94353L3.62503 1.94166C3.98503 1.8527 4.38176 1.84332 4.88111 1.84473C5.83834 1.84975 6.82277 1.84997 7.80428 1.85019C8.67625 1.85038 9.54592 1.85058 10.3921 1.85413C10.4015 3.84791 10.3997 5.84058 10.398 7.83595C10.3972 8.77084 10.3964 9.70632 10.3967 10.6428C7.4699 10.6441 4.54142 10.6455 1.61569 10.6415C1.60221 9.7434 1.60373 8.8562 1.60526 7.96055C1.60637 7.31447 1.60748 6.66398 1.60298 6.00184C1.60531 5.77702 1.60264 5.58615 1.60013 5.40668C1.59667 5.15961 1.59352 4.93415 1.60413 4.67148C1.62056 4.26502 1.67099 3.92317 1.78648 3.61914L1.79076 3.60788L1.79473 3.59651C2.07711 2.78869 2.79009 2.14034 3.61745 1.9435Z"
                    stroke="#43567D"
                    stroke-width="1.8"
                  />
                  <path
                    d="M21.2779 1.84446L21.2779 1.84455L21.2909 1.84415C22.0493 1.82022 22.743 1.98404 23.2464 2.36504L23.2542 2.37091L23.262 2.37662C23.9599 2.88141 24.3707 3.79318 24.3596 4.73073L24.3596 4.73073L24.3595 4.73831C24.3571 5.46076 24.357 6.17985 24.357 6.89715C24.3569 8.14783 24.3568 9.3931 24.3439 10.6414C22.6789 10.6444 21.0159 10.6439 19.352 10.6435C18.0879 10.6431 16.8232 10.6428 15.5566 10.6439C15.5553 10.6091 15.5545 10.5717 15.5543 10.5313C15.554 10.4535 15.5556 10.3755 15.5575 10.2844C15.5578 10.2718 15.558 10.2589 15.5583 10.2458C15.5603 10.146 15.5623 10.0313 15.5601 9.91328C15.5631 7.97948 15.5626 6.04456 15.562 4.11091C15.5618 3.36624 15.5615 2.62176 15.5615 1.8776C16.6458 1.83761 17.7064 1.84364 18.7882 1.84979C19.5998 1.85441 20.4234 1.85909 21.2779 1.84446Z"
                    stroke="#43567D"
                    stroke-width="1.8"
                  />
                  <path
                    d="M1.40614 15.2971C1.40612 15.2971 1.40684 15.297 1.40837 15.2967L1.40614 15.2971ZM1.60444 15.3692C1.60417 15.3389 1.60405 15.3118 1.60417 15.2871C1.6291 15.2861 1.6563 15.2849 1.68404 15.2833C3.24803 15.2884 4.82307 15.2877 6.39631 15.2869C7.73312 15.2862 9.06863 15.2856 10.3949 15.2886C10.4039 17.3736 10.4019 19.4574 10.3999 21.5435C10.3992 22.3849 10.3984 23.2267 10.3983 24.069C9.0796 24.0805 7.76294 24.0802 6.44149 24.08C5.77883 24.0799 5.11496 24.0798 4.44903 24.0812L4.44903 24.0811L4.43689 24.0813C3.58601 24.0945 2.76204 23.7516 2.26801 23.15L2.2681 23.15L2.25901 23.1392C1.81702 22.6178 1.59022 21.8912 1.60157 21.1363L1.60169 21.1289L1.60168 21.1215C1.60049 20.3094 1.60236 19.4903 1.60424 18.6692C1.60675 17.57 1.60927 16.4674 1.60447 15.3733L1.60448 15.3733L1.60444 15.3692Z"
                    stroke="#43567D"
                    stroke-width="1.8"
                  />
                  <path
                    d="M19.4502 15.3239L19.4611 15.3226L19.472 15.3209C21.3286 15.0437 23.3032 16.1363 24.0628 17.8538L24.0661 17.8613L24.0696 17.8687C24.6163 19.0467 24.6017 20.4789 24.0283 21.6418L24.0283 21.6418L24.0243 21.6499C23.5368 22.6644 22.6366 23.4796 21.5787 23.8655L21.5787 23.8655L21.5734 23.8674C20.4906 24.2701 19.2424 24.2238 18.1983 23.7381L18.1983 23.7381L18.1912 23.7349C17.1699 23.2706 16.3369 22.3912 15.9265 21.3456L15.9265 21.3456L15.9238 21.3389C15.4548 20.1715 15.5228 18.7935 16.107 17.6796L16.107 17.6796L16.1109 17.672C16.7567 16.4118 18.0503 15.498 19.4502 15.3239Z"
                    stroke="#43567D"
                    stroke-width="1.8"
                  />
                </svg>
              </span>
            </div>
            <div className="blog-filters" style={{ width: '75%' }}>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                  setPageNumber(1);
                }}
                value={selectedCategory}
              >
                {categories.map((category, i) => (
                  <option className="filter-text" key={category} value={category}>
                    {t(category)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="input-group">
            <div className="input-group-prepend search-width">
              <span className="input-group-text search-span" id="basic-addon1">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_885_89)">
                    <path
                      d="M18.6527 16.9775L14.9753 13.2985C17.7269 9.62139 16.9766 4.40985 13.2995 1.65824C9.62237 -1.09337 4.41083 -0.343099 1.65922 3.33401C-1.0924 7.01113 -0.342122 12.2227 3.33499 14.9743C6.28875 17.1846 10.3457 17.1846 13.2995 14.9743L16.9784 18.6532C17.4408 19.1156 18.1903 19.1156 18.6526 18.6532C19.115 18.1909 19.115 17.4413 18.6526 16.9791L18.6527 16.9775ZM8.34789 14.2642C5.07985 14.2642 2.43061 11.615 2.43061 8.34692C2.43061 5.07887 5.07985 2.42963 8.34789 2.42963C11.6159 2.42963 14.2652 5.07887 14.2652 8.34692C14.2617 11.6135 11.6145 14.2607 8.34789 14.2642Z"
                      fill="#43567D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_885_89">
                      <rect width="19" height="19" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div>
            <input
              id="search-input"
              type="text"
              className="form-control search-container"
              placeholder={t("Search...")}
              aria-label="Username"
              aria-describedby="basic-addon1"
              onKeyPress={(e) => handleKeyPress(e)}
            />
            <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={tooltip}>
              <div className="input-group-prepend" onClick={resetSearchFilter}>
                <span className="input-group-text forum-input-group-text-reset" id="basic-addon1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="m-1 bi bi-arrow-clockwise" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                  </svg>
                </span>
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </div>
      {searchKey != "" && <div className="questions-count">Search results for "{searchKey}"</div>}
      <div className={"mt-3 blogs-container " + (searchKey == "" ? "blogs-container-top" : "")}>
        <div className="row">
          {blogsData.map((v, i) => (
            <Link to={"/blog-details/" + v.id} key={i} className="col-lg-4 mb-4" onClick={() => updateMostPopularBlogs(v.id)}>
              <div className="blog-card">
                <div className="card-blog-image">
                  <img src={process.env.REACT_APP_BACKEND_URL + v.image} alt={i18n.language === "hi" ? v.hindititle : v.title} />
                </div>
                <div className="description-card">
                  {i18n.language === "hi" ? (
                    <>
                      <span className="blog-card-category">{t(v.category)}</span>
                      <p className="blog-title">{v.hindititle || "कोई शीर्षक नहीं"}</p>
                      <p className="blog-content">{(v.hindidescription?.replace(/<\/?p>/g, "").slice(0, 100) || "कोई विवरण नहीं...") + "..."}</p>
                    </>
                  ) : (
                    <>
                      <div className="span-card">
                        <span className="blog-card-category">
                          {t(v.category)} | {convertDateFormate(v.createdAt)}
                        </span>
                      </div>
                      <p className="blog-title">{v.title || "No Title"}</p>
                      <p className="blog-content">{(v.description?.replace(/<\/?p>/g, "").slice(0, 100) || "No description...") + "..."}</p>
                    </>
                  )}
                </div>
                <div className="mt-1">
                  <button className="blog-button">{t("Read More")}</button>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      {totalPagesCont > 1 && (
        <div className="bottom-nav">
          <Pagination pageNumber={pageNumber} totalPagesCont={totalPagesCont} setPageNumber={setPageNumber} />
        </div>
      )}
    </div>
  );
}
