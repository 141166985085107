import React from "react";
import { Link } from "react-router-dom";
import "../../css/commonCss.css";
import { useTranslation } from "react-i18next";

const Breadcrumb = ({ trail }) => {
  const { t, i18n } = useTranslation();

  return (
    <nav aria-label="breadcrumb" className="common-breadcrumb">
      <ul>
        {trail.map((step, index) => (
          <li key={index}>
            {index < trail.length - 1 ? (
              <Link to={step.path}>
                {step.label == "Home" ? (
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.4167 4.53131V1.58315C17.4167 1.14615 17.0628 0.791479 16.625 0.791479C16.1872 0.791479 15.8333 1.14615 15.8333 1.58315V3.45623L11.7143 0.676688C10.3693 -0.230562 8.63075 -0.230562 7.28571 0.676688L1.74404 4.41652C0.652333 5.15356 0 6.37985 0 7.69798V15.0415C0 17.2241 1.77571 18.9998 3.95833 18.9998H5.54167C5.97946 18.9998 6.33333 18.6451 6.33333 18.2081V11.0831C6.33333 10.6469 6.688 10.2915 7.125 10.2915H11.875C12.312 10.2915 12.6667 10.6469 12.6667 11.0831V18.2081C12.6667 18.6451 13.0205 18.9998 13.4583 18.9998H15.0417C17.2243 18.9998 19 17.2241 19 15.0415V7.69798C19 6.44635 18.411 5.27706 17.4167 4.53131Z"
                      fill="#A1A8B7"
                    />
                  </svg>
                ) : (
                  <span>{t(step.label)}</span>
                )}
              </Link>
            ) : (
              <span className="active-path">{t(step.label)}</span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
