import React, { useEffect, useState } from "react";
import ApiService from "../api/ApiService";

const AppContext = React.createContext({});

export const AppContextProvider = ({ children }) => {
  const [loginPopup, setLoginPopup] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [historyEvent, setHistoryEvent] = useState(null);

  const getUserNotificationSCount = async (user) => {
    try {
      const res = await ApiService.getUserUnReadNotificationCount(user.id);

      if (res.status) {
        setNotificationsCount(res.new_notifications_count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user) {
      getUserNotificationSCount(user);
    }
  }, [user]);

  let values = {
    loginPopup,
    setLoginPopup,
    token,
    setToken,
    user,
    setUser,
    setShowHeaderFooter,
    showHeaderFooter,
    notificationsCount,
    setNotificationsCount,
    historyEvent,
    setHistoryEvent,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export default AppContext;
