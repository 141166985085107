import { useContext, useEffect, useState } from "react";
import "../css/myProfile.css";
import ProfileNotification from "../components/my-profile/myProfileNotification";
import EditProfile from "../components/my-profile/editProfile";
import NeedHelp from "../components/my-profile/needHelp";
import AppContext from "../context/AppContext";
import { useTranslation } from "react-i18next";

export default function MyProfile() {
  const { user } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState("en"); // Track the current language

  const [tabItems, setTabItems] = useState([
    {
      value: "my-profile",
      label: "My Profile",
    },
    {
      value: "notifications",
      label: "Notifications",
    },
    // {
    //   value: "need-help",
    //   label: "Need Help?",
    // },
  ]);

  const [activeTab, setActiveTab] = useState("my-profile");

  useEffect(() => {
    const userNotification = localStorage.getItem("user-notification");
    if (userNotification) {
      setActiveTab("notifications");
    }
    localStorage.removeItem("user-notification");
  }, []);

  const userLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.reload();
  };
  const changeLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "hi" : "en";
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
  };

  return (
    <div className="container profile-wrapper">
      <div className="row">
        <div className="col-md-3">
          <div className="profile-left">
            <div className="profile-image">
              <img src="/images/common/temple-image.png" className="user-img" width="50" height="50" />
              <div className="profile-user-name">
                <p className="m-0">{t("Hare Krishna")},</p>
                <p className="profile-name-text">{i18n.language === "hi" ? user?.hindiname : user?.name}</p>
              </div>
            </div>
            <ul className="mt-4">
              {tabItems.map((item, i) => (
                <li
                  className={"list-border " + (activeTab == item.value ? "profile-tab-active" : "")}
                  key={i}
                  onClick={() => setActiveTab(item.value)}
                >
                  <a className="profile-item-list profile-item-text">
                    <div className="">
                      {activeTab == item.value ? (
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="4" cy="4" r="4" fill="#9F1D21" />
                        </svg>
                      ) : (
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="4" cy="4" r="4" fill="#ECD7DA" />
                        </svg>
                      )}
                      &nbsp;&nbsp;&nbsp;
                      {t(item.label)}
                    </div>
                    <span>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.52827 11.5286C5.26792 11.7889 5.26792 12.211 5.52827 12.4714C5.78862 12.7317 6.21073 12.7317 6.47108 12.4714L10.4711 8.47139C10.7314 8.21105 10.7314 7.78894 10.4711 7.52859L6.47108 3.52859C6.21073 3.26824 5.78862 3.26824 5.52827 3.52859C5.26792 3.78894 5.26792 4.21105 5.52827 4.47139L9.05687 7.99999L5.52827 11.5286Z"
                          fill="#9F1D21"
                        />
                      </svg>
                    </span>
                  </a>
                </li>
              ))}

              <li
                className="logout-section"
                onClick={() => {
                  userLogOut();
                }}
              >
                <a className="profile-item-list profile-item-text log-out">
                  <div>
                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="4" cy="4" r="4" fill="#D55F5A" />
                    </svg>
                    &nbsp;&nbsp;&nbsp;{t("Logout Account")}
                  </div>

                  <span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5.52827 11.5286C5.26792 11.7889 5.26792 12.211 5.52827 12.4714C5.78862 12.7317 6.21073 12.7317 6.47108 12.4714L10.4711 8.47139C10.7314 8.21105 10.7314 7.78894 10.4711 7.52859L6.47108 3.52859C6.21073 3.26824 5.78862 3.26824 5.52827 3.52859C5.26792 3.78894 5.26792 4.21105 5.52827 4.47139L9.05687 7.99999L5.52827 11.5286Z"
                        fill="#9F1D21"
                      />
                    </svg>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-9">
          <div className="profile-right">
            {activeTab == "my-profile" && <EditProfile />}
            {activeTab == "notifications" && <ProfileNotification />}
            {/* {activeTab == "need-help" && <NeedHelp />} */}
          </div>
        </div>
      </div>
    </div>
  );
}
