import { useEffect, useState } from "react";
import ApiService from "../../api/ApiService";
import "../../css/commonCss.css";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

export default function LiveScoreUpdates() {
  const [liveScoreData, setLiveScoreData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { t, i18n } = useTranslation();

  const getScoreLiveUpdates = async () => {
    setIsLoaded(false);
    try {
      const response = await ApiService.fetchScoreLiveUpdates();
      if (response) {
        setIsLoaded(true);
        if (response.data) {
          setLiveScoreData(response.data);
        }
      }
    } catch (error) {
      setIsLoaded(true);
      console.log(error);
    }
  };

  useEffect(() => {
    getScoreLiveUpdates();
  }, []);

  if (!isLoaded) {
    return false;
  }

  const bookMapping = {
    SrimadBhagavatamSet: "SB Set",
    ChaitanyaCharitamritaSet: "CC Set",
    "1 Year BTG Subscription-2": "1 Year BTG Subscription Non English Book",
    BigBooks: "Big Book",
    MediumBooks: "Medium Book",
    Magazines: "Magazine",
    "1 Year BTG Subscription-1": "1 Year BTG Subscription English Book",
    SmallBooks: "Small Book",
    MahabigBooks: "Mahabig Book",
  };

  const HindiBookMapping = {
    SrimadBhagavatamSet: "SB-Set",
    ChaitanyaCharitamritaSet: "CC Set",
    "1 Year BTG Subscription-2": "1 वर्ष बीटीजी सदस्यता गैर अंग्रेजी पुस्तक",
    BigBooks: "Big Books",
    MediumBooks: "Medium Books",
    Magazines: "Magazines",
    "1 Year BTG Subscription-1": "1 वर्ष बीटीजी सदस्यता अंग्रेजी पुस्तक",
    SmallBooks: "Small Books",
    MahabigBooks: "Mahabig Books",
  };

  return (
    <div className="container live-score-wrapper">
      <Marquee pauseOnHover={true} speed={60}>
        {liveScoreData.length > 0 ? (
          liveScoreData.map((list, i) => (
            <div className="live-score-item" key={i}>
              {i == 0 && <b className="text-dark">{t("Today's Update")}: </b>}&nbsp;&nbsp;
              <img src="/images/common/iskcon_logo_1.png" alt="Your Logo" width="30" height="30" /> &nbsp;&nbsp;
              {i18n.language === "hi" ? (
                // Show this block if the language is "hi" (Hindi)
                <p>
                  {list.hinditempleName} {"ने"} <b className="text-dark">{parseFloat(list.updated_count?.toFixed(2)).toLocaleString("en-IN")}</b>{" "}
                  {t(HindiBookMapping[list.book_id])} {t("distributed")} {"कीं।"}
                </p>
              ) : (
                // Show this block if the language is not "hi"
                <p className="score-content">
                  {list.temple_name} {t("distributed")}{" "}
                  <b className="text-dark">{parseFloat(list.updated_count?.toFixed(2)).toLocaleString("en-IN")}</b> {bookMapping[list.book_id]}
                  {i18n.language !== "hi" && list.updated_count > 1 ? "s" : ""}{" "}
                </p>
              )}
            </div>
          ))
        ) : (
          <>
            <div className="live-score-item">
              &nbsp;&nbsp;
              <img src="/images/common/iskcon_logo_1.png" alt="Your Logo" width="30" height="30" /> &nbsp;&nbsp;
              <p className="score-content">{t("Hare Krishna Hare Krishna, Krishna Krishna Hare Hare | Hare Rama Hare Rama, Rama Rama Hare Hare")}</p>
            </div>
            <div className="live-score-item">
              &nbsp;&nbsp;
              <img src="/images/common/iskcon_logo_1.png" alt="Your Logo" width="30" height="30" /> &nbsp;&nbsp;
              <p className="score-content">{t("Hare Krishna Hare Krishna, Krishna Krishna Hare Hare | Hare Rama Hare Rama, Rama Rama Hare Hare")}</p>
            </div>
          </>
        )}
      </Marquee>
    </div>
  );
}
