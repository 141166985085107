import moment from "moment";
import "../../css/forum.css";
import ApiService from "../../api/ApiService";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import AnswerCommentsItem from "./AnswerCommentsItem";
import notification from "../../helpers/notification";
import { ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";

export default function ForumAnswerItem({ item, forumId, getAllForumAnswer, getForumDetails, setPageNumber, setForumAnswersList }) {
  const answerId = item.id;
  const { user } = useContext(AppContext);
  const [showCommentBoxId, setShowCommentBoxId] = useState("");
  const [answerCommentsText, setAnswerCommentsText] = useState("");
  const [answerCommentsList, setAnswerCommentsList] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [deletePopOpen, setDeletePopOpen] = useState(false);

  const convertDateFormate = (dateString, showTime = false) => {
    return moment(dateString).format("DD MMM, YYYY hh:mm a");
  };

  const upVoteForumAnswer = async () => {
    const payload = {
      forum_id: forumId,
      answer_id: item.id,
      user_id: user.id,
      user_name: user.name,
    };

    const response = await ApiService.upVoteForumAnswer(payload);
    if (response.status) {
      setForumAnswersList([]);
      setPageNumber(1);
      getAllForumAnswer();
      getForumDetails();
    } else {
      notification("warning", response.message);
    }
  };

  const handleTextareaChange = (event) => {
    setAnswerCommentsText(event.target.value);
  };

  const getCommentsForForumByAnswerId = async () => {
    const response = await ApiService.getCommentsForForumByAnswerId(item.id);

    if (response.status) {
      setAnswerCommentsList(response.data);
    }
  };

  const postAnswerComment = async () => {
    const payload = {
      forum_id: forumId,
      answer_id: item.id,
      user_id: user.id,
      user_name: user.name,
      comments: answerCommentsText,
    };

    const response = await ApiService.postAnswerComment(payload);

    if (response.status) {
      notification("success", "Comment added successfully.");
      setForumAnswersList([]);
      setPageNumber(1);
      getCommentsForForumByAnswerId();
      getAllForumAnswer();
      getForumDetails();
      setShowCommentBoxId("");
    }
  };

  const HideShowComments = () => {
    if (answerCommentsList.length > 0) {
      setShowComments(!showComments);
    }
  };

  useEffect(() => {
    getCommentsForForumByAnswerId();
  }, []);

  const deleteForumByUser = async () => {
    const payload = {
      answer_id: item.id,
    };
    const response = await ApiService.deleteForumAnswerByUserId(payload);

    if (response.status) {
      setDeletePopOpen(false);
      notification("success", "Your answer successfully.");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const deleteConfirmation = () => {
    setDeletePopOpen(true);
  };

  const handleHideModal = () => {
    setDeletePopOpen(false);
  };

  return (
    <div className="forum-answer-section mt-2">
      <ToastContainer />
      <div className="d-flex align-items-left user-header-div mt-3">
        <img className="rounded-circle" src="/images/common/default-profile.png" width="40" height="40" />
        <div className="user-details text-start ml-2">
          <h4 className="mb-0 user-name">{item.user_name}</h4>
          <small className="header-name-span">{convertDateFormate(item.createdAt)}</small>
        </div>
        <div className="ms-auto">
          {user.id == item.user_id && (
            <svg
              onClick={deleteConfirmation}
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              className="bi bi-trash cursor-pointer"
              viewBox="0 0 16 16"
            >
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
            </svg>
          )}
        </div>
      </div>
      <div className="forum-ans-section">
        <p className="user-answer">{item.answer}</p>
      </div>
      <div className="share-reply-section mt-2">
        <span className="answers-stats">
          <svg
            width="18"
            height="18"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer"
            onClick={upVoteForumAnswer}
          >
            <path
              d="M7.66049 16H6.34049C4.86716 16 3.66716 14.8133 3.66716 13.3533V8.66668H2.29382C1.47382 8.66668 0.773824 8.19334 0.460491 7.43334C0.147158 6.68001 0.313824 5.84667 0.893824 5.26667L5.11382 0.786675C6.16049 -0.259992 7.84049 -0.259992 8.87383 0.773341L13.1205 5.28667C13.6872 5.84667 13.8538 6.68001 13.5405 7.43334C13.2272 8.18668 12.5272 8.66001 11.7138 8.66668H10.3405V13.3533C10.3405 14.8133 9.14049 16 7.66716 16H7.66049ZM7.00049 1.33334C6.66049 1.33334 6.32716 1.46001 6.06716 1.72001L1.84716 6.20001C1.54716 6.50001 1.65382 6.83334 1.68716 6.92668C1.72716 7.02001 1.88716 7.33334 2.28716 7.33334H4.32049C4.68716 7.33334 4.98716 7.63334 4.98716 8.00001V13.3533C4.98716 14.08 5.58716 14.6667 6.32716 14.6667H7.64716C8.38716 14.6667 8.98716 14.0733 8.98716 13.3533V8.00001C8.98716 7.63334 9.28716 7.33334 9.65382 7.33334H11.6938C12.0938 7.33334 12.2538 7.02001 12.2938 6.92668C12.3338 6.83334 12.4405 6.50001 12.1538 6.21334L7.92716 1.71334C7.67382 1.46001 7.34049 1.33334 7.00716 1.33334H7.00049Z"
              fill="#43567D"
            />
          </svg>
          &nbsp;&nbsp;{item.upvotes_count} &nbsp;<span className="">Likes</span>
        </span>
        <span className="answers-stats">
          <svg
            width="17"
            height="18"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer"
            onClick={HideShowComments}
          >
            <path
              d="M3.01275 12.3807C3.12952 12.498 3.219 12.6395 3.27481 12.7953C3.33062 12.951 3.35138 13.1172 3.33562 13.2819C3.25637 14.0441 3.10647 14.7974 2.88787 15.5319C4.45725 15.1685 5.41575 14.7478 5.85113 14.5273C6.09807 14.4022 6.38245 14.3725 6.64987 14.444C7.41634 14.6491 8.20656 14.752 9 14.75C13.4955 14.75 16.875 11.5921 16.875 8C16.875 4.40788 13.4955 1.25 9 1.25C4.5045 1.25 1.125 4.409 1.125 8C1.125 9.6515 1.81912 11.1838 3.01275 12.3807ZM2.45812 16.7739C2.19156 16.8267 1.92415 16.875 1.656 16.919C1.431 16.955 1.26 16.721 1.34888 16.5117C1.4485 16.2761 1.54005 16.0371 1.62337 15.7951L1.62675 15.7839C1.90575 14.9739 2.133 14.0424 2.21625 13.175C0.835875 11.7913 0 9.98 0 8C0 3.65075 4.02975 0.125 9 0.125C13.9703 0.125 18 3.65075 18 8C18 12.3492 13.9703 15.875 9 15.875C8.10856 15.877 7.22076 15.7612 6.35962 15.5308C5.77462 15.8266 4.51575 16.3655 2.45812 16.7739Z"
              fill="#43567D"
            />
          </svg>
          &nbsp;&nbsp;{item.comments_count} &nbsp;<span className="">Comments</span>
        </span>
        <span className="answers-stats" onClick={() => setShowCommentBoxId(item.id)}>
          | &nbsp;&nbsp; &nbsp;
          <span className="cursor-pointer" style={{ color: "#9f1d21" }}>
            Reply
          </span>
        </span>
      </div>
      <div className="comment-box form-group mb-2">
        {showCommentBoxId == item.id && (
          <textarea className="form-control text-area mt-4" id="exampleFormControlTextarea1" rows="2" onChange={handleTextareaChange} />
        )}
        {showCommentBoxId == item.id && (
          <button className="mt-2 blog-post-comment" onClick={postAnswerComment} disabled={answerCommentsText == ""}>
            Post
          </button>
        )}
      </div>
      {answerCommentsList.length > 0 &&
        showComments &&
        answerCommentsList.map((item, index) => <AnswerCommentsItem key={index} item={item} forumId={forumId} answerId={answerId} />)}
      <Modal centered show={deletePopOpen} onHide={handleHideModal}>
        <div className="modal-wrap">
          <p className="card-text p-3">Are you sure you want to delete the question?</p>

          <div className="d-flex justify-content-center mb-2 modal-gap">
            <button className="btn-primarysave custom-button" onClick={deleteForumByUser}>
              Yes
            </button>

            <button className="btn-primarysave custom-button" onClick={() => setDeletePopOpen(false)}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
