import React from "react";
import "../css/app.css";
import notification from "../helpers/notification";
import { useContext, useEffect, useState } from "react";
import ApiService from "../api/ApiService";
import AppContext from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const expiry = process.env.REACT_APP_OTPEXPIRY;
let timer = null;

export default function LogInWrapper() {
  const { setToken, setUser } = useContext(AppContext);
  const navigate = useNavigate();
  const [otpSent, setOtpSent] = useState(false);
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpExp, setOtpExp] = useState(expiry);
  // const [resendtimes, setResendTimes] = useState(0);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const [resendtimes, setResendTimes] = useState(0);
  const [showresendlink, setShowResendLink] = useState(false);
  const [isWatchLive, setIsWatchLive] = useState(false);
  const [showTimer, setShowTimer] = useState(true);
  const [seticsmob, SetIcsmob] = useState([]);

  function getTime(time) {
    return Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2);
  }

  const handleSendOtp = async () => {
    if (timer) {
      clearInterval(timer);
    }
    if (number.length >= 10) {
      try {
        setBtnDisabled(true);
        const res = await ApiService.sendOtp({ number });
        notification("success", res.message);
        setBtnDisabled(false);
        timer = setInterval(() => {
          setOtpExp((prev) => prev - 1);
        }, 1000);

        setOtpSent(true);
        const numberInput = document.getElementById("number");
        if (numberInput) {
          numberInput.value = ""; // Clear the input using query selector
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 400) {
          notification("warning", err.response.data.message);
        } else {
          notification("error", err.response.data.message);
        }
        setBtnDisabled(false);
      }
    } else {
      notification("warning", "Please Enter Valid Number");
      setBtnDisabled(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (otp.length >= 6) {
      try {
        let data = {
          number,
          otp,
        };

        const res = await ApiService.loginUser(data);
        localStorage.setItem(`token`, res.token);
        localStorage.setItem(`user`, JSON.stringify(res.user));
        setToken(res.token);
        setUser(res.user);
        notification("success", "Login Successful");
        navigate("/");
        setOtpSent(false);

        setOtp("");
      } catch (err) {
        notification("error", err.response.data.message);
      }
    } else {
      notification("warning", "Please Enter Valid OTP");
    }
  };

  const resendOtp = () => {
    setShowResendLink(false);

    if (getCookie("limitReached2")) {
      notification("warning", "Resend OTP limit has been reached!");
      setResendTimes(0);
      setShowTimer(false);
    } else {
      if (resendtimes < 2) {
        setOtpExp(expiry);
        handleSendOtp();
        setResendTimes((prev) => prev + 1);
      } else {
        setCookie("limitReached2", true);
        notification("warning", "Resend OTP limit has been reached!");
        setShowTimer(false);
      }
    }
  };

  function setCookie(name, value) {
    let expires = "";
    let date = new Date();
    date.setTime(date.getTime() + 1 * 60 * 1000);
    expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  useEffect(() => {
    if (otpExp === 0) {
      setShowResendLink(true);
      clearInterval(timer);
      setOtpExp(expiry);
    }
  }, [otpExp, expiry]);

  return (
    <>
      <ToastContainer />
      <div className="log-in-container">
        <div className="row login-white-background">
          <div className="col-lg-6 login-image-right"></div>
          <div className="col-lg-6 d-flex justify-content-center align-items-center login-card">
            {otpSent ? (
              <div className="">
                <center>
                  <img src="images/common/iskcon_logo_1.png" alt="ISKCON Logo" className="mb-2" height="250" width="250" />
                  <h4 className="login-welcome-text">ISKCON India</h4>
                </center>
                <div className="text-center mt-3">
                  <h4 className="login-welcome-text">ICC Brihad Mridanga</h4>
                  <h4 className="login-welcome-sub-text">The official book distribution website of ISKCON India</h4>
                  {/* <span className="register-mobile-text mt-3">Enter your OTP</span> */}
                  <span className="register-mobile-text mt-3">
                    Please enter the OTP sent to +91 {number}.&nbsp;
                    <a className="change-number" href="/login">
                      Change
                    </a>
                  </span>
                </div>
                <div className="text-center">
                  <form action="" className="mt-4 edit-profile-wrapper login-form">
                    <input
                      type="tel"
                      className="form-control mb-3"
                      name="otp"
                      id="otp"
                      defaultValue={""}
                      onChange={(e) => setOtp(e.target.value)}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      pattern="[0-9]*"
                      minLength={6}
                      maxLength={6}
                      placeholder="Enter OTP"
                      required
                    />
                    <button type="submit" onClick={handleLogin} className="btn btn-primary btn-sm btn-block login-button">
                      VERIFY OTP
                    </button>
                  </form>
                  <div className="text-center">
                    {showresendlink && (
                      <div className="time-wrap mt-2">
                        <p className="mb-0 register-mobile-text">
                          Not received your code?&nbsp;
                          <a className="resent-text" onClick={resendOtp} type="button">
                            Resend code
                          </a>
                        </p>
                      </div>
                    )}
                    {!showresendlink && showTimer && (
                      <div className="time-wrap register-mobile-text">
                        <p className="mb-0 mt-2">
                          resend OTP in &nbsp;
                          <span className="timer_span d-inline-block">{getTime(otpExp)}s</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="">
                <center>
                  <img src="images/common/iskcon_logo_1.png" alt="ISKCON Logo" className="mb-2" height="230" width="230" />
                  <h4 className="login-welcome-text">ISKCON India</h4>
                </center>
                <div className="text-center mt-3">
                  <h4 className="login-welcome-text">ICC Brihad Mridanga</h4>
                  <h4 className="login-welcome-sub-text">The official book distribution website of ISKCON India</h4>
                  <span className="register-mobile-text mt-3">Please enter your registered mobile number</span>
                </div>
                <form action="" className="mt-4 edit-profile-wrapper login-form">
                  <div className="login-mobile-input">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      fill="#9f1d21"
                      className="bi bi-phone mobile-icon"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                      <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                    </svg>
                    <input
                      type="tel"
                      className="form-control mb-3"
                      name="number"
                      id="number"
                      onChange={(e) => {
                        setNumber(e.target.value);
                        // const mobileIcon = document.querySelector(".mobile-icon");
                        // if (mobileIcon && e.target.value != "") {
                        //   mobileIcon.classList.add("d-none");
                        // } else {
                        //   mobileIcon.classList.remove("d-none");
                        // }
                      }}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      pattern="[0-9]*"
                      minLength={10}
                      maxLength={10}
                      placeholder={"Enter mobile number"}
                      required
                    />
                  </div>
                  <button type="submit" onClick={handleSendOtp} disabled={btnDisabled} className="btn btn-primary btn-block login-button">
                    GET OTP
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
