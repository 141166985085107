import React, { useEffect, useState } from "react";
import "../css/app.css";
import AppContext from "../context/AppContext";
import { useContext } from "react";
import ApiService from "../api/ApiService";
import { useTranslation } from "react-i18next";
import CommonProgressBar from "../components/common/progressBar";
import GBCDashboard from "../components/dashboards/gbcDashboard";
import TempleDashboard from "../components/dashboards/templeDashboard";
import RSADashboard from "../components/dashboards/RSADashboard";

const Dashboard = () => {
  const { t, i18n } = useTranslation();

  // Event tabs
  const selectTabs = [
    {
      label: t("Regular Distribution"),
      value: "Regular Update",
    },
    {
      label: t("Bhadra Purnima Marathon"),
      value: "Bhadra Purnima",
    },
    {
      label: t("Chaitanya Charitamrita Marathon"),
      value: "Chaitanya Charitamrita",
    },
    {
      label: t("Gita Marathon"),
      value: "Gita Marathon",
    },
  ];

  const { user } = useContext(AppContext);
  const [userEventRanking, setUserEventRanking] = useState({});
  const [previousYearUserEventRanking, setPreviousYearUserEventRanking] = useState({});
  const [pledgeCountsFromDB, setPledgeCountFromDB] = useState([]);
  const [animatedPercentage, setAnimatedPercentage] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(selectTabs[0].value);
  const [userBooksStatsData, setUserBooksStatsData] = useState([]);
  const [overAllPointsCount, setOverAllPointsCount] = useState([]);
  const [topTemplesList, setTopTemplesList] = useState([]);
  const [indiaPledgeData, setIndiaPledgeData] = useState({});
  const [RSATempleList, setRSATempleList] = useState([]);

  // Temple Total Pledge
  const fetchPledgeCount = async (user) => {
    setAnimatedPercentage(0);
    try {
      let res = [];
      if (user.role != "Regional Zonal Secretary") {
        res = await ApiService.fetchPledgeCount(user.temple_id, selectedEvent, true);
      } else {
        const postData = {
          temple_array: user.temple_array,
          state_array: user.state_array,
          zone_array: user.zone_array,
          event: selectedEvent,
        };

        res = await ApiService.getRSAPledgeData(postData);
      }

      const accumulatedData = {};

      res.forEach((item) => {
        const { book_name, count_book } = item;

        if (accumulatedData[book_name]) {
          accumulatedData[book_name].count_book += parseInt(count_book);
        } else {
          accumulatedData[book_name] = {
            ...item,
            count_book: parseInt(count_book),
          };
        }
      });
      const accumulatedArray = Object.values(accumulatedData);
      // setUserPledgedData(accumulatedArray);
      let totalPledgeCount = 0;

      if (res) {
        totalPledgeCount = res.reduce((acc, curVal) => acc + Number(curVal.count_book), 0);
      }

      setPledgeCountFromDB(totalPledgeCount);
    } catch (error) {
      console.log(error);
    }
  };

  // Temple previous year ranking
  const fetchPreviousYearRankingByUserId = async (user) => {
    setPreviousYearUserEventRanking({});
    try {
      let res;

      if (user) {
        res = await ApiService.fetchUserPreviousYearRanking(user.temple_id, user.zone_name, user.temple, user.state_id, selectedEvent);
        setPreviousYearUserEventRanking(res);
      } else {
        throw new Error("User ID is required");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Temple Current year ranking
  const fetchCurrentYearByUserId = async (user) => {
    try {
      let res;

      if (user.role != "Regional Zonal Secretary") {
        if (user) {
          res = await ApiService.fetchDataFromAllLiveRankingByUserId(user.temple_id, user.zone_name, user.temple, user.state_id, selectedEvent);
          setUserEventRanking(res);
        } else {
          throw new Error("User ID is required");
        }
      } else {
        const postData = {
          temple_array: user.temple_array,
          state_array: user.state_array,
          zone_array: user.zone_array,
          event: selectedEvent,
        };

        res = await ApiService.getRSAScoreData(postData);

        const result = res.reduce(
          (acc, item) => {
            acc.count_book += item.count_book;
            acc.total_points += item.total_points;
            return acc;
          },
          { count_book: 0, total_points: 0 }
        );

        setUserEventRanking(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Current and Previous Year Books Graph
  const getCurrentAndPreviousYearBooks = async () => {
    setUserBooksStatsData([]);

    try {
      let res = [];

      if (user.role != "Regional Zonal Secretary") {
        res = await ApiService.getCurrentAndPreviousBooksCount(user.temple_id, selectedEvent);
      } else {
        const postData = {
          temple_array: user.temple_array,
          state_array: user.state_array,
          zone_array: user.zone_array,
          event: selectedEvent,
        };

        res = await ApiService.getRSACurrentAndPreviousBooksCount(postData);
      }

      const resultData = res.data;

      //for over all book count
      if (selectedEvent == "Regular Update" || selectedEvent == "Gita Marathon") {
        const booksCountArray = [
          {
            name: "ChaitanyaCharitamritaSet",
            lastYearScore: "0",
            currentScore: "0",
          },
          {
            name: "SrimadBhagavatamSet",
            lastYearScore: "0",
            currentScore: "0",
          },
          {
            name: "MahabigBooks",
            lastYearScore: "0",
            currentScore: "0",
          },
          {
            name: "BigBooks",
            lastYearScore: "0",
            currentScore: "0",
          },
          {
            name: "MediumBooks",
            lastYearScore: "0",
            currentScore: "0",
          },
          {
            name: "SmallBooks",
            lastYearScore: "0",
            currentScore: "0",
          },
          {
            name: "Magazines",
            lastYearScore: "0",
            currentScore: "0",
          },
          {
            name: "1 Year BTG Subscription-1",
            lastYearScore: "0",
            currentScore: "0",
          },
          {
            name: "1 Year BTG Subscription-2",
            lastYearScore: "0",
            currentScore: "0",
          },
        ];

        const existingItemsMap = new Map(resultData.map((item) => [item.name, item]));

        const updatedData = [];

        booksCountArray.forEach((item) => {
          if (existingItemsMap.has(item.name)) {
            updatedData.push(existingItemsMap.get(item.name));
          } else {
            updatedData.push(item);
          }
        });
        setUserBooksStatsData(updatedData);
      } else {
        if (resultData.length > 0) {
          setUserBooksStatsData(resultData);
        } else if (selectedEvent == "Chaitanya Charitamrita") {
          setUserBooksStatsData([
            {
              name: "ChaitanyaCharitamritaSet",
              lastYearScore: "0",
              currentScore: "0",
            },
          ]);
        } else {
          setUserBooksStatsData([
            {
              name: "SrimadBhagavatamSet",
              lastYearScore: "0",
              currentScore: "0",
            },
          ]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Current and Previous Year Points Graph
  const getCurrentAndPreviousYearPointsCount = async () => {
    setOverAllPointsCount([]);

    try {
      let res = [];

      if (user.role != "Regional Zonal Secretary") {
        res = await ApiService.getCurrentAndPreviousPointsCount(user.temple_id, selectedEvent);
      } else {
        const postData = {
          temple_array: user.temple_array,
          state_array: user.state_array,
          zone_array: user.zone_array,
          event: selectedEvent,
        };
        res = await ApiService.getRSACurrentAndPreviousPointsCount(postData);
      }

      const resultData = res.data;

      if (resultData.length > 0) {
        const totalPointsObj = { currentScore: 0, lastYearScore: 0 };
        resultData.map((v, i) => {
          totalPointsObj["lastYearScore"] += Number(v.lastYearScore);
          totalPointsObj["currentScore"] += Number(v.currentScore);
        });

        // totalPointsObj.lastYearScore = Math.ceil(totalPointsObj.lastYearScore).toLocaleString("en-IN");
        // totalPointsObj.currentScore = Math.ceil(totalPointsObj.currentScore).toLocaleString("en-IN");

        setOverAllPointsCount([totalPointsObj]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Top 10 Temples
  const getTopTenTemplesList = async () => {
    setTopTemplesList([]);
    const temple = user.role != "GBC (Governing Body Commission)" ? user.temple : "all";

    try {
      let res = [];

      if (user.role != "Regional Zonal Secretary") {
        res = await ApiService.getTopTemplesList(selectedEvent, temple);
      } else {
        const postData = {
          temple_array: user.temple_array,
          state_array: user.state_array,
          zone_array: user.zone_array,
          event: selectedEvent,
        };

        res = await ApiService.getRSATopTemplesList(postData);
      }

      if (res.data.length > 0) {
        setTopTemplesList(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // India Pledge
  const getIndiaPledgeData = async () => {
    setIndiaPledgeData({});
    try {
      const res = await ApiService.getOverAllIndiaPledgeStatus(selectedEvent);
      if (res.status) {
        let indiaPledgePercentage = 0;

        if (res?.data?.total_count_book_for_score > 0 && res?.data?.total_india_pledge_count > 0) {
          indiaPledgePercentage = Math.floor((res?.data?.total_count_book_for_score / res?.data?.total_india_pledge_count) * 100);
        }

        const indiaPledgeObj = {
          ...res.data,
          india_pledge_percentage: indiaPledgePercentage,
        };

        setIndiaPledgeData(indiaPledgeObj);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //RSA Temples list
  const getRSAAllTempleList = async (user) => {
    if (user.temple_array != null && user.temple_array.length > 0) {
      setRSATempleList(user.temple_array);
    } else {
      try {
        const postData = {
          state_array: user.state_array,
          zone_array: user.zone_array,
        };

        const res = await ApiService.getRSATempleList(postData);

        if (res) {
          setRSATempleList(res);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    setAnimatedPercentage("");
    if (pledgeCountsFromDB > 0 && userEventRanking.count_book > 0) {
      const percentage = Math.floor((userEventRanking.count_book / pledgeCountsFromDB) * 100);

      setAnimatedPercentage(percentage);
    } else {
      setAnimatedPercentage(0);
    }
  }, [pledgeCountsFromDB, userEventRanking]);

  useEffect(() => {
    if (user.role != "GBC (Governing Body Commission)") {
      fetchPledgeCount(user);
      getCurrentAndPreviousYearBooks(user);
      getCurrentAndPreviousYearPointsCount(user);
      fetchCurrentYearByUserId(user);
      fetchPreviousYearRankingByUserId(user);
    }
    getTopTenTemplesList();
    getIndiaPledgeData();
  }, [user, selectedEvent]);

  useEffect(() => {
    if (user.role == "Regional Zonal Secretary") {
      getRSAAllTempleList(user);
    }
  }, [user]);

  function getOrdinalSuffix(number) {
    const lastDigit = number % 10;

    const secondLastDigit = Math.floor(number / 10) % 10;

    if (secondLastDigit === 1) {
      return "th";
    } else {
      switch (lastDigit) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }
  }

  function displayOrdinal(number) {
    const suffix = getOrdinalSuffix(number);
    return `${number}${suffix}`;
  }

  return (
    <div className="dashboard-container">
      <div className="container">
        {/* User Login stats*/}
        {user.role != "GBC (Governing Body Commission)" && (
          <div className="row">
            <div className="col-md-4 welcome-left-div">
              <div className={"col-md-12 user-welcome-card " + (user.role == "Regional Zonal Secretary" ? "right-section" : "")}>
                <p className="welcome-text-name">
                  {t("Welcome")} {i18n.language === "hi" ? user?.hindiname : user?.name}
                </p>
                <p className="welcome-info-text">{t("Explore the dashboard for detailed insights and current progress in book distribution")}</p>
                {user.role == "Regional Zonal Secretary" && (
                  <>
                    <b className="text-dark">
                      {t("Your Temples")} -{" "}
                      {user.temple_array != null && user.temple_array.length > 0 ? user.temple_array.length : RSATempleList.length}
                    </b>
                    <div className="card-text temple-list-card">
                      {user.temple_array != null && user.temple_array.length > 0 ? (
                        user.temple_array.map((item, i) => (
                          <p className="mt-1 temple-list" key={i}>
                            {i + 1}. {i18n.language === "hi" ? item.split(",")[3] : item.split(",")[2]}
                          </p>
                        ))
                      ) : (
                        <>
                          {RSATempleList.length > 0 &&
                            RSATempleList.map((item, i) => (
                              <p className="mt-1 temple-list" key={i}>
                                {i + 1}. {i18n.language === "hi" ? item.hinditempleName : item.templeName}
                              </p>
                            ))}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
              {typeof userEventRanking.all_rank != "undefined" && user.role != "Regional Zonal Secretary" && (
                <div className="col-md-12 wish-card user-welcome-card">
                  <div className="d-flex align-items-center mt-2">
                    <div className="rank-image">
                      <img src="/images/dashboard-images/rank.png" className="rounded" height="103" width="103" />
                    </div>
                    <div className="px-2 mt-1">
                      <h2 className="welcome-text-rank">
                        {t("You are on the")} {t(displayOrdinal(userEventRanking.all_rank))} {t("rank")}
                      </h2>
                      <p className="welcome-rank-info">{t("Your score will please Srila Prabhupada.")}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-8 welcome-right">
              <div className="row user-welcome-card right-section">
                <div className="col-md-3 card-border-right">
                  <div className="flex-progress-container">
                    <div className="d-flex align-items-center">
                      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          opacity="0.21"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z"
                          fill="#883DCF"
                        />
                        <path
                          opacity="0.587821"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20.6667 23.3333C20.6667 26.2789 23.0546 28.6667 26.0001 28.6667C28.9456 28.6667 31.3334 26.2789 31.3334 23.3333C31.3334 20.3878 28.9456 18 26.0001 18C23.0546 18 20.6667 20.3878 20.6667 23.3333ZM34.0001 28.6667C34.0001 30.8758 35.7909 32.6667 38.0001 32.6667C40.2092 32.6667 42.0001 30.8758 42.0001 28.6667C42.0001 26.4575 40.2092 24.6667 38.0001 24.6667C35.7909 24.6667 34.0001 26.4575 34.0001 28.6667Z"
                          fill="#883DCF"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M25.9778 31.3333C19.6826 31.3333 14.5177 34.5687 14.0009 40.9323C13.9727 41.2789 14.6356 42 14.97 42H36.9956C37.9972 42 38.0128 41.194 37.9972 40.9333C37.6065 34.3909 32.3616 31.3333 25.9778 31.3333ZM45.2746 42L40.1333 42C40.1333 38.9988 39.1417 36.2291 37.4683 34.0008C42.0103 34.0505 45.7189 36.3469 45.998 41.2C46.0092 41.3955 45.998 42 45.2746 42Z"
                          fill="#883DCF"
                        />
                      </svg>

                      <div className="p-2 mt-2">
                        <h4 className="welcome-user-details">{t("Your Pledge")}</h4>
                        <p className="welcome-user-count">{pledgeCountsFromDB ? Math.ceil(pledgeCountsFromDB).toLocaleString("en-IN") : "-"}</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <svg width="71" height="71" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          opacity="0.21"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z"
                          fill="#883DCF"
                        />
                        <path
                          d="M19.1111 40.8889H42.4444C43.3036 40.8889 44 41.5853 44 42.4444C44 43.3036 43.3036 44 42.4444 44H17.5556C16.6964 44 16 43.3036 16 42.4444V17.5556C16 16.6964 16.6964 16 17.5556 16C18.4147 16 19.1111 16.6964 19.1111 17.5556V40.8889Z"
                          fill="#883DCF"
                        />
                        <path
                          opacity="0.5"
                          d="M24.9126 34.175C24.325 34.8018 23.3406 34.8335 22.7138 34.2459C22.0871 33.6584 22.0553 32.674 22.6429 32.0472L28.4762 25.825C29.0445 25.2188 29.9888 25.1663 30.6208 25.7056L35.2248 29.6344L41.2235 22.0361C41.7558 21.3618 42.734 21.2467 43.4083 21.7791C44.0826 22.3114 44.1977 23.2896 43.6653 23.9639L36.6653 32.8306C36.1186 33.5231 35.1059 33.6227 34.4347 33.05L29.7306 29.0358L24.9126 34.175Z"
                          fill="#883DCF"
                        />
                      </svg>

                      <div className="mt-2" style={{ paddingLeft: "10px" }}>
                        <h4 className="welcome-user-details">{t("Books Distributed")}</h4>
                        <p className="welcome-user-count">
                          {userEventRanking?.count_book ? Math.ceil(userEventRanking?.count_book).toLocaleString("en-IN") : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          opacity="0.21"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z"
                          fill="#883DCF"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15 24.3165L27.9005 31.7646C28.0394 31.8448 28.1851 31.9027 28.3333 31.9395V46.3847L15.9201 39.0385C15.3498 38.701 15 38.0876 15 37.4249V24.3165ZM45 24.1185V37.4249C45 38.0876 44.6502 38.701 44.0799 39.0385L31.6667 46.3847V31.8129C31.6969 31.7978 31.7269 31.7817 31.7566 31.7646L45 24.1185Z"
                          fill="#883DCF"
                        />
                        <path
                          opacity="0.499209"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.4053 20.7014C15.5628 20.5024 15.7617 20.3343 15.9936 20.2108L29.1186 13.2201C29.6696 12.9266 30.3305 12.9266 30.8815 13.2201L44.0065 20.2108C44.1852 20.306 44.3444 20.4277 44.4801 20.5697L30.0899 28.8778C29.9953 28.9325 29.9081 28.995 29.8286 29.064C29.7491 28.995 29.6618 28.9325 29.5672 28.8778L15.4053 20.7014Z"
                          fill="#883DCF"
                        />
                      </svg>

                      <div className="p-2 mt-2">
                        <h4 className="welcome-user-details">{t("Your Points")}</h4>
                        <p className="welcome-user-count">
                          {userEventRanking?.total_points ? Math.ceil(userEventRanking?.total_points).toLocaleString("en-IN") : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 text-center" id="progress-stats-download">
                  <div className="pledge-text">
                    <h4 className="user-pledge-covered">{t("Pledge Covered")}</h4>
                    <small className="pledge-status-text">{t("Your pledge completion status")}</small>
                  </div>
                  <div className="your-score-input">
                    <input type="radio" className="your-input" checked readOnly />{" "}
                    <p className="progress-count">{animatedPercentage != "" ? animatedPercentage : 0}%</p>
                    <p className="progress-count-text-your">{t("Your Score")}</p>
                  </div>
                  <div className="india-score-input">
                    <input type="radio" className="india-input" checked readOnly />
                    <p className="progress-count">{indiaPledgeData?.india_pledge_percentage}%</p>
                    <p className="progress-count-text-india">{t("India's Score")}</p>
                  </div>
                  {animatedPercentage !== "" && (
                    <CommonProgressBar
                      animatedPercentage={animatedPercentage != "" ? animatedPercentage : 0}
                      type={"your-progress"}
                      selectedEvent={selectedEvent}
                    />
                  )}
                  <CommonProgressBar animatedPercentage={indiaPledgeData?.india_pledge_percentage} type={"india-progress"} selectedEvent={""} />
                  {animatedPercentage != "" && (
                    <p className="welcome-info-text progress-stats-text">
                      {t("The progress indicates your temple pledge and overall india pledge covered")}
                    </p>
                  )}
                </div>
                <div className="col-md-3 india-stats card-border-left">
                  <div className="flex-progress-container">
                    <div className="d-flex align-items-center">
                      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          opacity="0.21"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z"
                          fill="#3DCFCF"
                        />
                        <path
                          opacity="0.587821"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20.6667 23.3333C20.6667 26.2789 23.0546 28.6667 26.0001 28.6667C28.9456 28.6667 31.3334 26.2789 31.3334 23.3333C31.3334 20.3878 28.9456 18 26.0001 18C23.0546 18 20.6667 20.3878 20.6667 23.3333ZM34.0001 28.6667C34.0001 30.8758 35.7909 32.6667 38.0001 32.6667C40.2092 32.6667 42.0001 30.8758 42.0001 28.6667C42.0001 26.4575 40.2092 24.6667 38.0001 24.6667C35.7909 24.6667 34.0001 26.4575 34.0001 28.6667Z"
                          fill="#3DCFCF"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M25.9778 31.3333C19.6826 31.3333 14.5177 34.5687 14.0009 40.9323C13.9727 41.2789 14.6356 42 14.97 42H36.9956C37.9972 42 38.0128 41.194 37.9972 40.9333C37.6065 34.3909 32.3616 31.3333 25.9778 31.3333ZM45.2746 42L40.1333 42C40.1333 38.9988 39.1417 36.2291 37.4683 34.0008C42.0103 34.0505 45.7189 36.3469 45.998 41.2C46.0092 41.3955 45.998 42 45.2746 42Z"
                          fill="#3DCFCF"
                        />
                      </svg>

                      <div className="mt-2" style={{ paddingLeft: "10px" }}>
                        <h4 className="welcome-user-details">{t("India's Pledge")}</h4>
                        <p className="welcome-user-count">
                          {indiaPledgeData?.total_india_pledge_count
                            ? Math.ceil(indiaPledgeData?.total_india_pledge_count).toLocaleString("en-IN")
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <svg width="71" height="71" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          opacity="0.21"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z"
                          fill="#3DCFCF"
                        />
                        <path
                          d="M19.1111 40.8889H42.4444C43.3036 40.8889 44 41.5853 44 42.4444C44 43.3036 43.3036 44 42.4444 44H17.5556C16.6964 44 16 43.3036 16 42.4444V17.5556C16 16.6964 16.6964 16 17.5556 16C18.4147 16 19.1111 16.6964 19.1111 17.5556V40.8889Z"
                          fill="#3DCFCF"
                        />
                        <path
                          opacity="0.5"
                          d="M24.9126 34.175C24.325 34.8018 23.3406 34.8335 22.7138 34.2459C22.0871 33.6584 22.0553 32.674 22.6429 32.0472L28.4762 25.825C29.0445 25.2188 29.9888 25.1663 30.6208 25.7056L35.2248 29.6344L41.2235 22.0361C41.7558 21.3618 42.734 21.2467 43.4083 21.7791C44.0826 22.3114 44.1977 23.2896 43.6653 23.9639L36.6653 32.8306C36.1186 33.5231 35.1059 33.6227 34.4347 33.05L29.7306 29.0358L24.9126 34.175Z"
                          fill="#3DCFCF"
                        />
                      </svg>

                      <div className="mt-2" style={{ paddingLeft: "10px" }}>
                        <h4 className="welcome-user-details">{t("Books Distributed")}</h4>
                        <p className="welcome-user-count">
                          {indiaPledgeData?.total_count_book_for_score
                            ? Math.ceil(indiaPledgeData?.total_count_book_for_score).toLocaleString("en-IN")
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          opacity="0.21"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z"
                          fill="#3DCFCF"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15 24.3165L27.9005 31.7646C28.0394 31.8448 28.1851 31.9027 28.3333 31.9395V46.3847L15.9201 39.0385C15.3498 38.701 15 38.0876 15 37.4249V24.3165ZM45 24.1185V37.4249C45 38.0876 44.6502 38.701 44.0799 39.0385L31.6667 46.3847V31.8129C31.6969 31.7978 31.7269 31.7817 31.7566 31.7646L45 24.1185Z"
                          fill="#3DCFCF"
                        />
                        <path
                          opacity="0.499209"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.4053 20.7014C15.5628 20.5024 15.7617 20.3343 15.9936 20.2108L29.1186 13.2201C29.6696 12.9266 30.3305 12.9266 30.8815 13.2201L44.0065 20.2108C44.1852 20.306 44.3444 20.4277 44.4801 20.5697L30.0899 28.8778C29.9953 28.9325 29.9081 28.995 29.8286 29.064C29.7491 28.995 29.6618 28.9325 29.5672 28.8778L15.4053 20.7014Z"
                          fill="#3DCFCF"
                        />
                      </svg>

                      <div className="p-2 mt-3">
                        <h4 className="welcome-user-details">{t("India's Points")}</h4>
                        <p className="welcome-user-count">
                          {indiaPledgeData?.total_points_for_score ? Math.ceil(indiaPledgeData?.total_points_for_score).toLocaleString("en-IN") : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* GBC Login stats*/}
        {user.role == "GBC (Governing Body Commission)" && (
          <>
            <div className="row">
              <div className="col-md-12 user-welcome-card">
                <p className="welcome-text-name">
                  {t("Welcome")} {i18n.language === "hi" ? user?.hindiname : user?.name}
                </p>
                <p className="welcome-info-text">
                  {t(
                    "This page provides a comprehensive overview of performance metrics for temples across India, categorized by events, zones, and states."
                  )}
                </p>
              </div>
            </div>
            {typeof indiaPledgeData?.india_pledge_percentage != "undefined" && (
              <div className="row mt-4">
                <div className="col-md-12 user-welcome-card">
                  <div className="gbc-pledge-covered-card">
                    <div className="gbc-items">
                      <h4 className="user-pledge-covered">{t("Pledge Covered")}</h4>
                      <small className="pledge-status-text">{t("India pledge completion status")}</small>
                    </div>
                    <div className="gbc-items gbc-pledge-div">
                      <div className="gbc-india-score-input">
                        <input type="radio" className="india-input" checked readOnly />
                        <p className="progress-count">{indiaPledgeData?.india_pledge_percentage}%</p>
                        <p className="progress-count-text-india">{t("India's Score")}</p>
                      </div>
                      <CommonProgressBar
                        animatedPercentage={indiaPledgeData?.india_pledge_percentage}
                        type={"gbc-india-progress"}
                        selectedEvent={""}
                      />
                    </div>
                    <div className="gbc-items">
                      <div className="d-flex align-items-center">
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            opacity="0.21"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z"
                            fill="#3DCFCF"
                          />
                          <path
                            opacity="0.587821"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M20.6667 23.3333C20.6667 26.2789 23.0546 28.6667 26.0001 28.6667C28.9456 28.6667 31.3334 26.2789 31.3334 23.3333C31.3334 20.3878 28.9456 18 26.0001 18C23.0546 18 20.6667 20.3878 20.6667 23.3333ZM34.0001 28.6667C34.0001 30.8758 35.7909 32.6667 38.0001 32.6667C40.2092 32.6667 42.0001 30.8758 42.0001 28.6667C42.0001 26.4575 40.2092 24.6667 38.0001 24.6667C35.7909 24.6667 34.0001 26.4575 34.0001 28.6667Z"
                            fill="#3DCFCF"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M25.9778 31.3333C19.6826 31.3333 14.5177 34.5687 14.0009 40.9323C13.9727 41.2789 14.6356 42 14.97 42H36.9956C37.9972 42 38.0128 41.194 37.9972 40.9333C37.6065 34.3909 32.3616 31.3333 25.9778 31.3333ZM45.2746 42L40.1333 42C40.1333 38.9988 39.1417 36.2291 37.4683 34.0008C42.0103 34.0505 45.7189 36.3469 45.998 41.2C46.0092 41.3955 45.998 42 45.2746 42Z"
                            fill="#3DCFCF"
                          />
                        </svg>

                        <div style={{ paddingLeft: "10px" }}>
                          <h4 className="welcome-user-details">{t("India's Pledge")}</h4>
                          <p className="welcome-user-count">
                            {indiaPledgeData?.total_india_pledge_count
                              ? Math.ceil(indiaPledgeData?.total_india_pledge_count).toLocaleString("en-IN")
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="gbc-items">
                      <div className="d-flex align-items-center">
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            opacity="0.21"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z"
                            fill="#3DCFCF"
                          />

                          <path
                            d="M19.1111 40.8889H42.4444C43.3036 40.8889 44 41.5853 44 42.4444C44 43.3036 43.3036 44 42.4444 44H17.5556C16.6964 44 16 43.3036 16 42.4444V17.5556C16 16.6964 16.6964 16 17.5556 16C18.4147 16 19.1111 16.6964 19.1111 17.5556V40.8889Z"
                            fill="#3DCFCF"
                          />
                          <path
                            opacity="0.5"
                            d="M24.9126 34.175C24.325 34.8018 23.3406 34.8335 22.7138 34.2459C22.0871 33.6584 22.0553 32.674 22.6429 32.0472L28.4762 25.825C29.0445 25.2188 29.9888 25.1663 30.6208 25.7056L35.2248 29.6344L41.2235 22.0361C41.7558 21.3618 42.734 21.2467 43.4083 21.7791C44.0826 22.3114 44.1977 23.2896 43.6653 23.9639L36.6653 32.8306C36.1186 33.5231 35.1059 33.6227 34.4347 33.05L29.7306 29.0358L24.9126 34.175Z"
                            fill="#3DCFCF"
                          />
                        </svg>

                        <div style={{ paddingLeft: "10px" }}>
                          <h4 className="welcome-user-details">{t("Books Distributed")}</h4>
                          <p className="welcome-user-count">
                            {indiaPledgeData?.total_count_book_for_score
                              ? Math.ceil(indiaPledgeData?.total_count_book_for_score).toLocaleString("en-IN")
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="gbc-items">
                      <div className="d-flex align-items-center">
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            opacity="0.21"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z"
                            fill="#3DCFCF"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15 24.3165L27.9005 31.7646C28.0394 31.8448 28.1851 31.9027 28.3333 31.9395V46.3847L15.9201 39.0385C15.3498 38.701 15 38.0876 15 37.4249V24.3165ZM45 24.1185V37.4249C45 38.0876 44.6502 38.701 44.0799 39.0385L31.6667 46.3847V31.8129C31.6969 31.7978 31.7269 31.7817 31.7566 31.7646L45 24.1185Z"
                            fill="#3DCFCF"
                          />
                          <path
                            opacity="0.499209"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.4053 20.7014C15.5628 20.5024 15.7617 20.3343 15.9936 20.2108L29.1186 13.2201C29.6696 12.9266 30.3305 12.9266 30.8815 13.2201L44.0065 20.2108C44.1852 20.306 44.3444 20.4277 44.4801 20.5697L30.0899 28.8778C29.9953 28.9325 29.9081 28.995 29.8286 29.064C29.7491 28.995 29.6618 28.9325 29.5672 28.8778L15.4053 20.7014Z"
                            fill="#3DCFCF"
                          />
                        </svg>

                        <div style={{ paddingLeft: "10px" }}>
                          <h4 className="welcome-user-details">{t("India's Points")}</h4>
                          <p className="welcome-user-count">
                            {indiaPledgeData?.total_points_for_score
                              ? Math.ceil(indiaPledgeData?.total_points_for_score).toLocaleString("en-IN")
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {/* Event tabs */}
        <div className="row mt-4">
          <div className="col-md-12">
            <h4 className="welcome-text">
              {user.role != "GBC (Governing Body Commission)" && user.role != "Regional Zonal Secretary"
                ? t("Your Ranking")
                : t("Event Wise Dashboard")}
            </h4>
            <div className="ranking-filters mt-4">
              <div className="tabs-wrapper">
                {selectTabs.map((item, i) => (
                  <div className="event-div" key={i}>
                    <div
                      className={"select-tab-div " + (selectedEvent == item.value ? " active-tab" : "")}
                      onClick={() => setSelectedEvent(item.value)}
                    >
                      <span className={selectedEvent == item.value ? "tab-event-text-active" : "tab-event-text"}>{item.label}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* User Graphs*/}
        {user.role != "GBC (Governing Body Commission)" && user.role != "Regional Zonal Secretary" && (
          <TempleDashboard
            selectedEvent={selectedEvent}
            userBooksStatsData={userBooksStatsData}
            overAllPointsCount={overAllPointsCount}
            userEventRanking={userEventRanking}
            previousYearUserEventRanking={previousYearUserEventRanking}
            topTemplesList={topTemplesList}
            user={user}
          />
        )}

        {/* GBC Graphs*/}
        {user.role == "GBC (Governing Body Commission)" && <GBCDashboard selectedEvent={selectedEvent} topTemplesList={topTemplesList} user={user} />}

        {/* RSA Graphs*/}
        {user.role == "Regional Zonal Secretary" && (
          <RSADashboard
            selectedEvent={selectedEvent}
            userBooksStatsData={userBooksStatsData}
            overAllPointsCount={overAllPointsCount}
            userEventRanking={userEventRanking}
            previousYearUserEventRanking={previousYearUserEventRanking}
            topTemplesList={topTemplesList}
            user={user}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
