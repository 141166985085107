import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ApiService from "../api/ApiService";
import "../css/RecordingOfScores.css";
import "../css/app.css";
import AppContext from "../context/AppContext";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import "bootstrap-icons/font/bootstrap-icons.css";
import * as XLSX from "xlsx";
import { Table } from "react-bootstrap";
Modal.setAppElement("#root");

const LiveRankingData = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState("en");

  const [liveRankingData, setDataFromAllLiveRanking] = useState([]);
  const [liveRankingDataByUserId, setDataFromAllLiveRankinguserId] = useState([]);
  const [liveRankingDataZoneWiseN, setDataFromDBZoneWise] = useState([]);
  const [liveRankingDataTempleWiseN, setDataFromDBTempleWise] = useState([]);
  const currentYear = new Date().getFullYear();
  const [financialYearArray, setFinancialYearFromDB] = useState([]);
  // console.log("financialYearArray", financialYearArray);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState(currentYear);
  const [selectedYearForCompare, setSelectedYearForCompare] = useState();
  const [selectedEventForCompare, setSelectedEventForComapre] = useState("");

  const [financialAllData, setFinancialAllData] = useState([]);
  const [CompareAllData, setCompareAllData] = useState([]);
  console.log("CompareAllData", CompareAllData);

  const [financialAndEventsAllData, setFinancialandEventsAllData] = useState([]);
  const [financialAndEventsZoneAllData, setFinancialandEventsZoneAllData] = useState([]);
  const [financialAndEventsTempleAllData, setFinancialandEventsTempleAllData] = useState([]);
  const [financialAndEventsBookWiseAllData, setFinancialandEventsBookWiseAllData] = useState([]);

  const [financialAndEventsStateAllData, setFinancialandEventsStateAllData] = useState([]);

  const [financialAndEventsSelectedZoneAllData, setFinancialandEventsSelectedZoneAllData] = useState([]);

  const [financialAndEventsSelectedTempleAllData, setFinancialandEventsSelectedTempleAllData] = useState([]);
  const [financialAndEventsSelectedStateAllData, setFinancialandEventsSelectedStateAllData] = useState([]);
  const [TempleWiseDataBasedonFEZ, setTempleWiseDataBasedonFEZ] = useState([]);
  const [allDataBookWiseWithZone, setAllDataBookWiseWithZone] = useState([]);
  const [allDataBookWiseWithZoneandTemple, setAllDataBookWiseWithZoneandTemple] = useState([]);
  const [allDataFYECandZone, setAllDataWithFYECandZone] = useState([]);

  // console.log("allDataFYECandZone", allDataFYECandZone);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [filterBy, setFilterBy] = useState("");
  // const [zoneWise, setZonewise] = useState(""); // Added state for filter by selection
  // Added state for filter by selection
  const [selectedEvent, setSelectedEvent] = useState("");
  const [userScoreData, setUserScoreData] = useState({});
  const [category, setCategory] = useState("");
  const [categoryandzone, setZonetTemple] = useState("");
  // console.log("categoryandzone", categoryandzone);

  const [templecategory, setTempleCategory] = useState("");
  const [bookwisezone, setBookWiseZones] = useState("");
  const [bookwisetemple, setBookWiseTemple] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTemple, setSelectedTemple] = useState("");
  const [comparisonData, setComparisonData] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdowns, setShowDropdowns] = useState(false);
  const [selectedTemples, setSelectedTemples] = useState(["SELECT Temple", "", "", ""]);
  //  const { user } = React.useContext(AppContext);

  // console.log("comparisonData", financialAllData, comparisonData);

  // console.log("cattototoot", category)
  const [states, setStates] = useState("");
  const [zone, setZones] = useState("");
  const [isSubZone, setIsSubZone] = useState(false);
  const { user } = React.useContext(AppContext);
  // Code for Search Logic Start

  const [searchVisibleCat, setSearchVisibleCat] = useState(false);
  const [searchVisiblePart, setSearchVisiblePart] = useState(false);

  const [searchVisibleZone, setSearchVisibleZone] = useState(false);

  const [searchTextTemple, setSearchTextTemple] = useState("");
  const [searchTextTempleName, setSearchTextTempleName] = useState("");
  const [searchTextZone, setSearchTextZone] = useState("");

  const handleHeaderClickCat = () => {
    setSearchVisibleCat(true);
    setSearchVisiblePart(false);
    setSearchVisibleZone(false);
  };
  const handleHeaderClickPart = () => {
    setSearchVisiblePart(true);
    setSearchVisibleCat(false);
    setSearchVisibleZone(false);
  };
  const handleHeaderClickZone = () => {
    setSearchVisibleZone(true);
    setSearchVisiblePart(false);
    setSearchVisibleCat(false);
  };

  const handleSearchChangeTemple = (e) => {
    setSearchTextTemple(e.target.value);
  };

  const handleSearchChangeTempleName = (e) => {
    setSearchTextTempleName(e.target.value);
  };

  const handleSearchChangeZone = (e) => {
    setSearchTextZone(e.target.value);
  };

  const filteredDataForSearch = (selectedEvent ? financialAndEventsAllData : financialAllData).filter(
    (row) =>
      row.temple.toLowerCase().includes(searchTextTemple.toLowerCase()) &&
      row.temple_name.toLowerCase().includes(searchTextTempleName.toLowerCase()) &&
      row.zone.toLowerCase().includes(searchTextZone.toLowerCase())
  );

  // Code for Search Logic End
  // console.log("user", user, CompareAllData);
  // useEffect(() => {
  //     let firstSelectedTemple = CompareAllData.filter((temple) => temple?.temple_id === user?.temple_id)?.[0]
  //     console.log("firstSelectedTemple", firstSelectedTemple, user, CompareAllData);

  //     setSelectedTemples([`${firstSelectedTemple?.temple_id}`, "", "", ""])
  // }, []);
  // console.log("liveRankingDataTempleWiseN", liveRankingDataTempleWiseN);
  const templeCategories = [
    { id: "BT", name: "Big Temple" },
    { id: "MT", name: "Medium Temple" },
    { id: "ST", name: "Small Temple" },
    { id: "PC", name: "Preaching Centre" },
    { id: "EC", name: "Extension Centre" },
    { id: "OP", name: "Outpost" },
  ];
  const abbreviationMapping = {
    BT: "Big Temple",
    ST: "Small Temple",
    MT: "Medium Temple",
    OP: "Outpost",
    PC: "Preaching Centre",
    EC: "Extension Centre",
  };
  const bookIdMapping = {
    MahabigBooks: "Mahabig Books",
    SrimadBhagavatamSet: "Srimad Bhagavatam Sets",
    "1 Year BTG Subscription-1": "1 Year BTG Subscription-English",
    MediumBooks: "Medium Books",
    BigBooks: "Big Books",
    SmallBooks: "Small Books",
    "1 Year BTG Subscription-2": "1 Year BTG Subscription-Non English",
    ChaitanyaCharitamritaSet: "Chaitanya Charitamrita Sets",
    Magazines: "Magazines",
  };

  const categories = ["Big Temple", "Medium Temple", "Small Temple", "Preaching Centre", "Extension Centre", "Outpost"];
  const zones = ["East Zone", "West Zone", "North Zone", "South Zone"];
  const statesOfIndia = [
    "Andaman & Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra & Nagar Haveli",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];
  // const [dataArrays, setDataArrays] = useState([]);
  const [dataArrays, setDataArrays] = useState([[], [], [], []]);

  const labels = [
    // "Particular",
    "Zone",
    "Category",
    "All India Ranking",
    "Zone Ranking",
    "Category Ranking",
    "State Ranking",
    "Points",
    "Books Distributed",
    "Chaitanya Charitamrita Set",
    "Srimad Bhagavatam Set",
    "Mahabig Books",
    "Big Books",
    "Medium Books",
    "Small Books",
    "Magazines",
    "1 Year BTG Subscription-English",
    "1 Year BTG Subscription-Non English",
    // "Pledged covered"
  ];
  const labelsMap = {
    "Bhadra Purnima": [
      "Zone",
      "Category",
      "All India Ranking",
      "Zone Ranking",
      "Category Ranking",
      "State Ranking",
      "Points",
      "Books Distributed",
      "",
      "Srimad Bhagavatam Set",
    ],
    "Chaitanya Charitamrita": [
      "Zone",
      "Category",
      "All India Ranking",
      "Zone Ranking",
      "Category Ranking",
      "State Ranking",
      "Points",
      "Books Distributed",
      "Chaitanya Charitamrita Set",
    ],
    "Regular Update": labels,
    "Gita Marathon": labels,
    // Add more events here if needed
  };
  const currentLabels = labelsMap[selectedEventForCompare] || labels;
  console.log("Selected Event:", selectedEventForCompare);
  console.log("Current Labels:", currentLabels);
  console.log("Data Arrays:", dataArrays);
  // useEffect(() => {
  //     // Example data arrays from API
  //     const data1 = ["Value 1.1", "Value 1.2", "Value 1.3", "Value 1.4", "Value 1.5", "Value 1.6", "Value 1.7", "Value 1.8"];
  //     const data2 = ["Value 2.1", "Value 2.2", "Value 2.3", "Value 2.4", "Value 2.5", "Value 2.6", "Value 2.7", "Value 2.8"];
  //     const data3 = ["Value 3.1", "Value 3.2", "Value 3.3", "Value 3.4", "Value 3.5", "Value 3.6", "Value 3.7", "Value 3.8"];
  //     const data4 = ["Value 4.1", "Value 4.2", "Value 4.3", "Value 4.4", "Value 4.5", "Value 4.6", "Value 4.7", "Value 4.8"];

  //     // Simulate fetching data from an API
  //     const fetchedData = [data1, data2, data3, data4];
  //     setDataArrays(fetchedData);
  // }, []);

  const numRows = 8; // Fixed number of rows for the table
  const numCols = 4; // Fixed number of columns for the table
  // const fetchDataFromAllLiveRanking = async (date, zone) => {
  //     try {
  //         const formattedDate = date.toISOString().split("T")[0];
  //         let res;

  //         if (zone == "zone" || zone == "East Zone" || zone == "West Zone" || zone == "North Zone" || zone == "South Zone") {
  //             const formattedZone = zone.replace(/ /g, "");
  //             res = await ApiService.fetchDataFromAllLiveRankingByZone(formattedZone);
  //         } else if (category === "temple" || category == "Big Temple" || category == "Medium Temple" || category == "Small Temple" || category == "Preching Centre" || category == "Extension Centre" || category == "Outpost") {
  //             res = await ApiService.fetchTempleWiseTotalScores(category);
  //         } else {
  //             res = await ApiService.fetchDataFromAllLiveRanking(formattedDate);
  //         }

  //         setDataFromAllLiveRanking(res);
  //     } catch (error) {
  //         console.log(error);
  //     }
  // };
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle cases where date is not provided

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based) and pad with zero
    const year = date.getFullYear(); // Get full year

    return `${day}-${month}-${year}`; // Format to DD-MM-YYYY
  };

  const getAllLiveDownload = () => {
    // Map the financialAllData to only include the necessary fields and rename them
    let filteredData;

    // Check if both selectedFinancialYear and selectedEvent are set
    if (selectedFinancialYear && selectedEvent && filterBy !== "state" && filterBy !== "temple" && filterBy !== "zone" && filterBy !== "bookwise" && !zone && !states && !categoryandzone) {
      filteredData = financialAndEventsAllData.map((item, index) => ({
        "Ranking": index + 1,
        "Particular": item.temple_name,
        "Category": item.temple,
        "Zone": item.zone,
        "Score": item.score,
        "Books Distributed": item.no_of_book_distributed,
      }));
    } else if (!templecategory && zone && !categoryandzone) {
      filteredData = financialAndEventsSelectedZoneAllData.map((item, index) => ({
        "Ranking": index + 1,
        "Particular": item.temple_name,
        "Category": item.temple,
        "Zone": item.zone,
        "Score": item.score,
        "Books Distributed": item.no_of_book_distributed,

      }));
    } else if (!bookwisezone && selectedFinancialYear && filterBy === "bookwise" && !zone && !states && !categoryandzone) {
      filteredData = financialAndEventsBookWiseAllData.map((item, index) => ({
        "Ranking": index + 1,
        "Book Name": item.book_id,
        "Score": item.total_score,
        "Books Distributed": item.total_books,


      }));
    } else if (selectedFinancialYear && filterBy === "zone" && !zone && !states && !categoryandzone) {
      filteredData = financialAndEventsZoneAllData.map((item, index) => ({
        "Ranking": index + 1,
        "Zone": item.zone_id,
        "Score": item.total_score,
        "Books Distributed": item.total_books,
      }));
    } else if (templecategory && filterBy !== "bookwise" && !categoryandzone) {
      filteredData = TempleWiseDataBasedonFEZ.map((item, index) => ({
        "Ranking": index + 1,
        "Particular": item.temple_name,
        "Category": item.temple,
        "Zone": item.zone,
        "Score": item.score,
        "Books Distributed": item.no_of_book_distributed,
      }));
    } else if (selectedFinancialYear && filterBy === "state" && !states && !categoryandzone) {
      filteredData = financialAndEventsStateAllData.map((item, index) => ({
        "Ranking": index + 1,
        "State Name": item.state_name,
        "Score": item.total_score,
        "Books Distributed": item.total_books,
      }));
    } else if (!states && selectedFinancialYear && filterBy === "temple" && !category && !categoryandzone) {
      filteredData = financialAndEventsTempleAllData.map((item, index) => ({
        "Ranking": index + 1,
        "Particular": item.temple_id,
        "Score": item.total_score,
        "Books Distributed": item.total_books,
      }));
    } else if (selectedFinancialYear && bookwisezone && bookwisetemple && !categoryandzone) {
      filteredData = allDataBookWiseWithZoneandTemple.map((item, index) => ({
        "Ranking": index + 1,
        "Book Name": item.book_id,
        "Total Points": item.total_score,
        "Total Count": item.total_books,
      }));
    } else if (!bookwisetemple && filterBy !== "state" && selectedFinancialYear && bookwisezone && !categoryandzone) {
      filteredData = allDataBookWiseWithZone.map((item, index) => ({
        "Ranking": index + 1,
        "Book Name": item.book_id,
        "Total Points": item.total_score,
        "Total Count": item.total_books,
      }));
    } else if (!bookwisetemple && filterBy !== "state" && selectedFinancialYear && bookwisezone && !categoryandzone) {
      filteredData = financialAndEventsZoneAllData.map((item, index) => ({
        "Ranking": index + 1,
        "Book Name": item.book_id,
        "Total Points": item.total_score,
        "Total Count": item.total_books,
      }));
    } else if (categoryandzone) {
      filteredData = allDataFYECandZone.map((item, index) => ({
        "Ranking": index + 1,
        "Particular": item.temple_name,
        "Category": item.temple,
        "Zone": item.zone,
        "Score": item.score,
        "Books Distributed": item.no_of_book_distributed,

      }));
    } else if (states && !categoryandzone) {
      filteredData = financialAndEventsSelectedStateAllData.map((item, index) => ({
        "Ranking": index + 1,
        "Particular": item.temple_name,
        "Category": item.temple,
        "Zone": item.zone,
        "Score": item.score,
        "Books Distributed": item.no_of_book_distributed,
      }));
    } else if (category && filterBy !== "state" && !categoryandzone) {
      filteredData = financialAndEventsSelectedTempleAllData.map((item, index) => ({
        "Ranking": index + 1,
        "Particular": item.temple_name,
        "Category": item.temple,
        "Zone": item.zone,
        "Score": item.score,
        "Books Distributed": item.no_of_book_distributed,
      }));
    } else {
      filteredData = financialAllData.map((item, index) => ({
        "Ranking": index + 1,
        "Particular": item.temple_name,
        "Category": item.temple,
        "Zone": item.zone,
        "Score": item.score,
        "Books Distributed": item.no_of_book_distributed,


      }));
    }

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert the filtered data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Financial Data");

    // Create a file name
    const fileName = "live_ranking.xlsx";

    // Write the workbook to a file
    XLSX.writeFile(workbook, fileName);
  };


  const getCurrentFinancialYear = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    return month >= 4 ? `${year}-${year + 1}` : `${year - 1}-${year}`;
  };
  const fetchDataFromAllLiveRankingByUserId = async (user) => {
    try {
      let res;

      if (user) {
        res = await ApiService.fetchDataFromAllLiveRankingByUserId(
          user.temple_id,
          user.zone_name,
          user.temple,
          user.state_id,
          selectedEvent == "" ? "Regular Update" : selectedEvent
        );
        setUserScoreData(res);
      } else {
        throw new Error("User ID is required");
      }

      setDataFromAllLiveRankinguserId(res);
    } catch (error) {
      console.log(error);
    }
  };
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setShowDropdown(false);
    setSelectedTemple("");
    setSelectedTemples("");
    setComparisonData(null);
    setDataArrays("");
  };
  // const handleTempleSelect = (event) => {
  //     const selectedTemple = event.target.value;
  //     setSelectedTemple(selectedTemple);
  //     const templeData = financialAllData.find(temple => temple.name === selectedTemple);
  //     setComparisonData(templeData);
  // };
  const handleTempleSelect = async (index, event) => {
    const selectedValue = event.target.value;
    // console.log("selectedTemples", selectedTemples)
    // Update the selected option
    const newSelectedOptions = [...selectedTemples];
    newSelectedOptions[index] = selectedValue;
    setSelectedTemples(newSelectedOptions);

    // Fetch data from API based on the selected value
    const fetchedData = await fetchDataFromAPI({ temple: selectedValue, data: CompareAllData });

    // Update the corresponding data array
    const newDataArrays = [...dataArrays];
    newDataArrays[index] = fetchedData;
    setDataArrays(newDataArrays);
  };
  // const handelYearandEventChanges = ({ year: x, event: y }) => {
  //     console.log("selected temple", selectedTemples);
  //     const data = [];
  //     // if (selectedTemples.length > 0) {
  //     //     selectedTemples.map(async (item, index) => {
  //     //         console.log("item", item);
  //     //         const fetchedData = await fetchDataFromAPI(item);
  //     //         // const newDataArrays = [...dataArrays];
  //     //         data[index] = fetchedData;
  //     //         setDataArrays(data);

  //     //     })
  //     //     console.log("dadadda", data);
  //     //     // setDataArrays(data);
  //     // }
  // }
  // const fetchDataFromAPI = async (value) => {
  //   // Simulate an API call based on the value
  //   // Replace this with your actual API call
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve([`${value} - 1`, `${value} - 2`, `${value} - 3`, `${value} - 4`, `${value} - 5`, `${value} - 6`, `${value} - 7`, `${value} - 8`]);
  //     }, 500);
  //   });
  // };

  const fetchDataFromAPI = async ({ temple: value, data }) => {
    // console.log("temple", value);
    const dataObj = data.length > 0 ? data?.filter((item) => item.temple_id == value)[0] : {};
    // console.log(dataObj)
    // setSelectedTempleData(dataArray);
    // Simulate an API call based on the value
    // Replace this with your actual API call
    // if (value) {
    //     return new Promise((resolve) => {
    //         setTimeout(() => {
    //             resolve([
    //                 `${dataObj?.zone}`,
    //                 `${dataObj?.temple}`,
    //                 `${dataObj?.all_rank}`,
    //                 `${dataObj?.zone_rank}`,
    //                 `${dataObj?.temple_rank}`,
    //                 `${dataObj?.state_id_rank}`,
    //                 `${Math.round(dataObj?.score).toLocaleString('en-IN')}`,
    //                 `${Math.round(dataObj?.no_of_book_distributed).toLocaleString('en-IN')}`,

    //             ]);
    //         }, 500);
    //     });

    // }
    if (value) {
      return [
        `${dataObj?.zone || ""}`,
        `${dataObj?.temple || ""}`,
        `${dataObj?.all_rank || ""}`,
        `${dataObj?.zone_rank || ""}`,
        `${dataObj?.temple_rank || ""}`,
        `${dataObj?.state_id_rank || ""}`,
        `${isNaN(Math.round(dataObj?.score)) ? "" : Math.round(dataObj?.score).toLocaleString("en-IN")}`,
        `${isNaN(Math.round(dataObj?.no_of_book_distributed)) ? "" : Math.round(dataObj?.no_of_book_distributed).toLocaleString("en-IN")}`,

        `${isNaN(Math.round(dataObj?.book_counts?.ChaitanyaCharitamritaSet))
          ? ""
          : Math.round(dataObj?.book_counts?.ChaitanyaCharitamritaSet).toLocaleString("en-IN")
        }`,
        `${isNaN(Math.round(dataObj?.book_counts?.SrimadBhagavatamSet))
          ? ""
          : Math.round(dataObj?.book_counts?.SrimadBhagavatamSet).toLocaleString("en-IN")
        }`,

        `${isNaN(Math.round(dataObj?.book_counts?.MahabigBooks)) ? "" : Math.round(dataObj?.book_counts?.MahabigBooks).toLocaleString("en-IN")}`,
        `${isNaN(Math.round(dataObj?.book_counts?.BigBooks)) ? "" : Math.round(dataObj?.book_counts?.BigBooks).toLocaleString("en-IN")}`,
        `${isNaN(Math.round(dataObj?.book_counts?.MediumBooks)) ? "" : Math.round(dataObj?.book_counts?.MediumBooks).toLocaleString("en-IN")}`,
        `${isNaN(Math.round(dataObj?.book_counts?.SmallBooks)) ? "" : Math.round(dataObj?.book_counts?.SmallBooks).toLocaleString("en-IN")}`,
        `${isNaN(Math.round(dataObj?.book_counts?.Magazines)) ? "" : Math.round(dataObj?.book_counts?.Magazines).toLocaleString("en-IN")}`,
        `${isNaN(Math.round(dataObj?.book_counts?.["1 Year BTG Subscription-1"]))
          ? ""
          : Math.round(dataObj?.book_counts?.["1 Year BTG Subscription-1"]).toLocaleString("en-IN")
        }`,
        `${isNaN(Math.round(dataObj?.book_counts?.["1 Year BTG Subscription-2"]))
          ? ""
          : Math.round(dataObj?.book_counts?.["1 Year BTG Subscription-2"]).toLocaleString("en-IN")
        }`,

        ,
      ];
    }
  };

  const fetchZoneWiseTotalScores = async () => {
    try {
      let res;

      if (user) {
        res = await ApiService.fetchZoneWiseTotalScores();
        setUserScoreData(res);
      } else {
        throw new Error("Zone Wise is required");
      }

      setDataFromDBZoneWise(res);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchTempleWiseTotalScores = async () => {
    try {
      let res;

      if (user) {
        res = await ApiService.fetchTempleWiseTotalScores();
        // setUserScoreData(res);
        // console.log("rereeeerrr", res)
      } else {
        throw new Error("Temple Wise is required");
      }

      setDataFromDBTempleWise(res);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchFinancialYear = async () => {
    try {
      const res = await ApiService.fetchFinancialYear();

      setFinancialYearFromDB(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCompareClick = async () => {
    // console.log("selectefinan", selectedYearForCompare)
    try {
      setShowDropdowns(true);
      await fetchDataFromByForCompare(selectedYearForCompare, selectedEventForCompare);

      // let firstSelectedTemple = CompareAllData.filter((temple) => temple?.temple_id === user?.temple_id)?.[0]
      // // console.log("firstSelectedTemple", firstSelectedTemple, user, CompareAllData);

      // setSelectedTemples([`${firstSelectedTemple?.temple_id}`, "", "", ""])

      // const data = [];

      // const fetchedData = await fetchDataFromAPI({ temple: firstSelectedTemple.temple_id, data: CompareAllData });
      // // console.log("dadadda", fetchedData, data);

      // // const newDataArrays = [...dataArrays];
      // data[0] = fetchedData;
      // setDataArrays(data);

      // setDataArrays(data);
    } catch (error) {
      console.log("Error handling compare click:", error);
    }
  };

  const handleCompareFirstClick = async () => {
    try {
      setShowDropdowns(true);
      await fetchDataFromByForCompare(selectedYearForCompare, selectedEventForCompare);

      // Check user role and determine if default selection should be set
      let selectedTempleIds = ["", "", "", ""]; // Default value for other users
      if (user.role !== "GBC (Governing Body Commission)") {
        let firstSelectedTemple = CompareAllData.find((temple) => temple?.temple_id === user?.temple_id);
        if (firstSelectedTemple) {
          selectedTempleIds[0] = `${firstSelectedTemple.temple_id}`;

          const fetchedData = await fetchDataFromAPI({ temple: firstSelectedTemple.temple_id, data: CompareAllData });
          const data = [fetchedData];
          setDataArrays(data);
        }
      }

      // Update the state for selected temples
      setSelectedTemples(selectedTempleIds);
    } catch (error) {
      console.log("Error handling compare click:", error);
    }
  };

  // const handleCompareFirstClick = async () => {
  //     // console.log("selectefinan", selectedYearForCompare)
  //     try {
  //         setShowDropdowns(true);
  //         await fetchDataFromByForCompare(selectedYearForCompare, selectedEventForCompare);

  //         let firstSelectedTemple = CompareAllData.filter((temple) => temple?.temple_id === user?.temple_id)?.[0]
  //         // console.log("firstSelectedTemple", firstSelectedTemple, user, CompareAllData);

  //         setSelectedTemples([`${firstSelectedTemple?.temple_id}`, "", "", ""])

  //         const data = [];

  //         const fetchedData = await fetchDataFromAPI({ temple: firstSelectedTemple.temple_id, data: CompareAllData });
  //         // console.log("dadadda", fetchedData, data);

  //         // const newDataArrays = [...dataArrays];
  //         data[0] = fetchedData;
  //         setDataArrays(data);

  //         // setDataArrays(data);

  //     } catch (error) {
  //         console.log("Error handling compare click:", error);
  //     }
  // };

  const fetchDataFromByForCompare = async (selectedYearForCompare, selectedEventForCompare) => {
    try {
      const res = await ApiService.fetchDataFromByForCompare(selectedYearForCompare, selectedEventForCompare);

      setCompareAllData(res);
      console.log("errrr", res);
      if (selectedTemples.length > 0) {
        const data = [];
        selectedTemples.map(async (item, index) => {
          console.log("item", item);
          const fetchedData = await fetchDataFromAPI({ temple: item, data: res });
          // const newDataArrays = [...dataArrays];
          data[index] = fetchedData;
          setDataArrays(data);
        });
        // console.log("dadadda", data);
        // setDataArrays(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataFromByDefaulFinancialY = async (selectedFinancialYear) => {
    try {
      const res = await ApiService.fetchDataFromByDefaulFinancialY(selectedFinancialYear);

      setFinancialAllData(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataByFinancialandEvents = async (selectedFinancialYear, selectedEvent) => {
    try {
      const res = await ApiService.fetchDataByFinancialandEvents(selectedFinancialYear, selectedEvent);

      setFinancialandEventsAllData(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataByFyearEventZone = async (selectedFinancialYear, selectedEvent, filterBy) => {
    // console.log("sameupdate", selectedFinancialYear, selectedEvent, filterBy)
    try {
      let res;

      if (filterBy === "zone") {
        res = await ApiService.fetchDataByFyearEventZone(selectedFinancialYear, selectedEvent, filterBy);
        setFinancialandEventsZoneAllData(res);
      } else if (filterBy === "temple") {
        res = await ApiService.fetchDataByFyearEventTemple(selectedFinancialYear, selectedEvent, filterBy);
        setFinancialandEventsTempleAllData(res);
      } else if (filterBy === "bookwise") {
        res = await ApiService.fetchDataByFyearEventBookWise(selectedFinancialYear, selectedEvent, filterBy);
        setFinancialandEventsBookWiseAllData(res);
      } else if (filterBy === "state") {
        res = await ApiService.fetchDataByFyearEventState(selectedFinancialYear, selectedEvent, filterBy);
        setFinancialandEventsStateAllData(res);
      } else {
        console.log(`Unsupported filterBy value: ${filterBy}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataByFyearEventZoneandSelected = async (selectedFinancialYear, selectedEvent, filterBy, zone) => {
    try {
      const res = await ApiService.fetchDataByFyearEventZoneandSelected(selectedFinancialYear, selectedEvent, filterBy, zone);

      setFinancialandEventsSelectedZoneAllData(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataBOnFSEvtSzoneforTempleWise = async (selectedFinancialYear, selectedEvent, filterBy, zone, templecategory) => {
    try {
      const res = await ApiService.fetchDataBOnFSEvtSzoneforTempleWise(selectedFinancialYear, selectedEvent, filterBy, zone, templecategory);

      setTempleWiseDataBasedonFEZ(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataByFyearEventTempleandSelected = async (selectedFinancialYear, selectedEvent, filterBy, category) => {
    try {
      const res = await ApiService.fetchDataByFyearEventTempleandSelected(selectedFinancialYear, selectedEvent, filterBy, category);

      setFinancialandEventsSelectedTempleAllData(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataByFyearEventStateandSelected = async (selectedFinancialYear, selectedEvent, filterBy, states) => {
    try {
      const res = await ApiService.fetchDataByFyearEventStateandSelected(selectedFinancialYear, selectedEvent, filterBy, states);

      setFinancialandEventsSelectedStateAllData(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataBookWiseWithZone = async (selectedFinancialYear, selectedEvent, filterBy, bookwisezone) => {
    try {
      const res = await ApiService.fetchDataBookWiseWithZone(selectedFinancialYear, selectedEvent, filterBy, bookwisezone);

      setAllDataBookWiseWithZone(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataBookWiseWithZoneAndTemple = async (selectedFinancialYear, selectedEvent, filterBy, bookwisezone, bookwisetemple) => {
    try {
      const res = await ApiService.fetchDataBookWiseWithZoneAndTemple(selectedFinancialYear, selectedEvent, filterBy, bookwisezone, bookwisetemple);

      setAllDataBookWiseWithZoneandTemple(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataWithYECAndZone = async (selectedFinancialYear, selectedEvent, filterBy, category, categoryandzone) => {
    try {
      const res = await ApiService.fetchDataWithYECAndZone(selectedFinancialYear, selectedEvent, filterBy, category, categoryandzone);

      setAllDataWithFYECandZone(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // console.log("selectedEventbased", selectedFinancialYear, selectedEvent, filterBy, zone, templecategory)
    fetchDataWithYECAndZone(selectedFinancialYear, selectedEvent, filterBy, category, categoryandzone);
  }, [selectedFinancialYear, selectedEvent, filterBy, category, categoryandzone]);

  useEffect(() => {
    // console.log("selectedEventbased", selectedFinancialYear, selectedEvent, filterBy, zone, templecategory)
    fetchDataBookWiseWithZoneAndTemple(selectedFinancialYear, selectedEvent, filterBy, bookwisezone, bookwisetemple);
  }, [selectedFinancialYear, selectedEvent, filterBy, bookwisezone, bookwisetemple]);
  useEffect(() => {
    // console.log("selectedEventbased", selectedFinancialYear, selectedEvent, filterBy, zone, templecategory)
    fetchDataBookWiseWithZone(selectedFinancialYear, selectedEvent, filterBy, bookwisezone);
  }, [selectedFinancialYear, selectedEvent, filterBy, bookwisezone]);

  useEffect(() => {
    // console.log("selectedEventbased", selectedFinancialYear, selectedEvent, filterBy, zone, templecategory)
    fetchDataBOnFSEvtSzoneforTempleWise(selectedFinancialYear, selectedEvent, filterBy, zone, templecategory);
  }, [selectedFinancialYear, selectedEvent, filterBy, zone, templecategory]);

  useEffect(() => {
    // console.log("selectedstate", selectedFinancialYear, selectedEvent, filterBy, states)
    fetchDataByFyearEventStateandSelected(selectedFinancialYear, selectedEvent, filterBy, states);
  }, [selectedFinancialYear, selectedEvent, filterBy, states]);

  useEffect(() => {
    // console.log("selectedEventbased", selectedFinancialYear, selectedEvent, filterBy, category)
    fetchDataByFyearEventTempleandSelected(selectedFinancialYear, selectedEvent, filterBy, category);
  }, [selectedFinancialYear, selectedEvent, filterBy, category]);

  useEffect(() => {
    // console.log("selectedEventbased", selectedFinancialYear, selectedEvent, filterBy, zone)
    fetchDataByFyearEventZoneandSelected(selectedFinancialYear, selectedEvent, filterBy, zone);
  }, [selectedFinancialYear, selectedEvent, filterBy, zone]);

  useEffect(() => {
    // console.log("selectedEventbased", selectedFinancialYear, selectedEvent, filterBy)
    fetchDataByFyearEventZone(selectedFinancialYear, selectedEvent, filterBy);
  }, [selectedFinancialYear, selectedEvent, filterBy]);

  useEffect(() => {
    // console.log("selectedEventbased", selectedFinancialYear, selectedEvent)
    fetchDataByFinancialandEvents(selectedFinancialYear, selectedEvent);
  }, [selectedFinancialYear, selectedEvent]);

  useEffect(() => {
    // console.log("financial_year", selectedFinancialYear)
    fetchDataFromByDefaulFinancialY(selectedFinancialYear);
  }, [selectedFinancialYear]);

  useEffect(() => {
    if (selectedEventForCompare || selectedYearForCompare || selectedEventForCompare) {
      handleCompareClick();
    }
  }, [selectedEventForCompare, selectedYearForCompare, selectedEventForCompare]);

  // useEffect(() => {
  //     if (selectedYearForCompare || selectedEventForCompare) {
  //         handelYearandEventChanges();
  //     }
  // }, [selectedYearForCompare, selectedEventForCompare]);

  useEffect(() => {
    setSelectedFinancialYear(currentYear); // Ensure it updates to the current year on mount
  }, [currentYear]);
  useEffect(() => {
    setSelectedYearForCompare(currentYear); // Ensure it updates to the current year on mount
  }, []);

  useEffect(() => {
    fetchFinancialYear();
  }, []);

  useEffect(() => {
    fetchTempleWiseTotalScores();
  }, []);

  useEffect(() => {
    fetchZoneWiseTotalScores();
  }, []);

  // useEffect(() => {
  //     // console.log("zonezonezone", zone)
  //     fetchDataFromAllLiveRanking(selectedDate, zone);
  // }, [selectedDate, selectedFinancialYear, zone, isSubZone]);

  useEffect(() => {
    fetchDataFromAllLiveRankingByUserId(user);
  }, [user, selectedEvent]);

  const resetFilters = () => {
    setFilterBy("");
    setCategory("");
    setTempleCategory("");
    setStates("");
    setZones("");
    setSelectedEvent("");
    setSelectedDate(new Date());
  };

  return (
    <div className="container common-container update-responsive live-ranking live-ranking-table">
      <div className="update-score-container">
        <div className="row">
          <div className="col-5">
            <div className="card card-shadow">
              <div className="card-body">
                <h5 className="card-title">{t("Live Ranking")}</h5>
                <p className="card-text">
                  {t("Track real-time rankings and performance with Live Ranking Table, providing instant insights into book distribution and more.")}
                </p>
              </div>
            </div>
          </div>
          <div className="col-7">
            {user.role !== "GBC (Governing Body Commission)" && user.role != "Regional Zonal Secretary" && (
              <div className="card card-shadow">
                <div className="card-body">
                  <h5 className="stats-header">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.4744 17.0183H5.51784C2.96254 17.0183 1.0022 16.0954 1.55903 12.3807L2.20739 7.34633C2.55064 5.49279 3.73294 4.78341 4.77032 4.78341H13.2524C14.305 4.78341 15.4187 5.54618 15.8153 7.34633L16.4637 12.3807C16.9366 15.6759 15.0297 17.0183 12.4744 17.0183Z"
                        stroke="#130F26"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.5889 4.60034C12.5889 2.61194 10.977 1.00003 8.98861 1.00003V1.00003C8.03111 0.995975 7.11143 1.3735 6.43294 2.04913C5.75445 2.72476 5.37304 3.64283 5.37305 4.60034H5.37305"
                        stroke="#130F26"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path d="M11.46 8.3532H11.4219" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M6.60114 8.3532H6.563" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    &nbsp; {t("Your Stats")}
                  </h5>
                  <div className="card-text stats">
                    <div className="text-center">
                      <p className="ranking-status-text">{t("All India Ranking")}</p>
                      <span className="ranking-count">{userScoreData.all_rank ?? "-"}</span>
                    </div>
                    <div className="text-center">
                      <p className="ranking-status-text">{t("State Rank")}</p>
                      <span className="ranking-count">{userScoreData.state_rank ?? "-"}</span>
                    </div>
                    <div className="text-center">
                      <p className="ranking-status-text">{t("Zonal Ranking")}</p>
                      <span className="ranking-count">{userScoreData.zone_rank ?? "-"}</span>
                    </div>
                    <div className="text-center">
                      <p className="ranking-status-text">{t("Category Rank")}</p>
                      <span className="ranking-count">{userScoreData.temple_rank ?? "-"}</span>
                    </div>
                    <div className="text-center">
                      <p className="ranking-status-text">{t("Total Points")}</p>
                      <span className="ranking-count">
                        {userScoreData?.total_points ? Math.ceil(userScoreData?.total_points)?.toLocaleString("en-IN") : "-"}
                      </span>
                    </div>
                    <div className="text-center">
                      <p className="ranking-status-text">{t("Books Distributed")}</p>
                      <span className="ranking-count">
                        {userScoreData?.count_book ? Math.ceil(userScoreData?.count_book)?.toLocaleString("en-IN") : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Filters Section */}

        <div className="container">
          <div className="row mt-4 filters-wrap">
            <div className="col-10 filter-container p-0">
              <div
                style={{
                  width: "100px",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  getAllLiveDownload();
                }}
              >
                {t("Download")}
              </div>

              <span className="filter-border"></span>
              <p
                className="filter-text fordrop-down"
                style={{ width: "100px", display: "flex", justifyContent: "space-around", alignItems: "center", textAlignz: "center" }}
              >
                {t("Filter By")}
              </p>
              <span className="filter-border"></span>
              <select
                className="form-select text-center"
                value={selectedFinancialYear} // Update to selectedFinancialYear
                onChange={(e) => setSelectedFinancialYear(e.target.value)} // Update to setSelectedFinancialYear
              >
                {financialYearArray.map((option) => (
                  <option key={option.id} value={option.financial_year}>
                    {option.financial_year}
                  </option>
                ))}
              </select>

              <span className="filter-border"></span>
              {/* Filter By Dropdown */}

              <select
                className="form-select text-center"
                value={selectedEvent}
                onChange={(e) => setSelectedEvent(e.target.value)}
                aria-label="Events"
              >
                <option value="" className="filter-text">
                  {t("Events")}
                </option>
                <option value="Regular Update" className="filter-text">
                  {t("Regular Update")}
                </option>
                <option value="Bhadra Purnima" className="filter-text">
                  {t("Bhadra Purnima")}
                </option>
                <option value="Chaitanya Charitamrita" className="filter-text">
                  {t("Chaitanya Charitamrita")}
                </option>
                <option value="Gita Marathon" className="filter-text">
                  {t("Gita Marathon")}
                </option>
              </select>
              <span className="filter-border"></span>

              <select
                className="form-select text-center"
                value={filterBy}
                onChange={(e) => {
                  setFilterBy(e.target.value);
                  // setZonewise(e.target.value);
                  if (e.target.value != "zone") {
                    setIsSubZone(true);
                    setZones("");
                  } else {
                    setIsSubZone(false);
                    // setIsSubTemple(false)
                  }
                }}
                aria-label="Filter By"
              >
                <option value="" className="filter-text">
                  {t("All India Ranking")}
                </option>
                <option value="zone" className="filter-text">
                  {t("Zone Wise")}
                </option>
                <option value="temple" className="filter-text">
                  {t("Category Wise")}
                </option>
                <option value="state" className="filter-text">
                  {t("State Wise")}
                </option>
                <option value="bookwise" className="filter-text">
                  {t("Book Wise")}
                </option>
              </select>
              <span className="filter-border"></span>
              {/* Conditionally Render Category Dropdown */}
              {filterBy === "temple" && (
                <>
                  <select className="form-select" value={category} onChange={(e) => setCategory(e.target.value)} aria-label="Category">
                    <option value="" className="filter-text">
                      {t("Select Category")}
                    </option>
                    {categories.map((cat, index) => (
                      <option key={index} value={cat} className="filter-text">
                        {t(cat)}
                      </option>
                    ))}
                  </select>
                  <span className="filter-border"></span>
                </>
              )}
              {category && (
                <>
                  <select
                    className="form-select"
                    value={categoryandzone}
                    onChange={(e) => {
                      setZonetTemple(e.target.value);
                      // setSubZone(""); // Reset subZone when a new zone is selected
                    }}
                    aria-label="Zone"
                  >
                    <option value="" className="filter-text text-center">
                      {t("Select Zone")}
                    </option>
                    {zones.map((zon, index) => (
                      <option key={index} value={zon} className="filter-text text-center">
                        {t(zon)}
                      </option>
                    ))}
                  </select>
                  <span className="filter-border"></span>
                </>
              )}
              {filterBy === "state" && (
                <>
                  <select className="form-select" value={states} onChange={(e) => setStates(e.target.value)} aria-label="Category">
                    <option value="" className="filter-text">
                      {t("Select State")}
                    </option>
                    {statesOfIndia.map((sat, index) => (
                      <option key={index} value={sat} className="filter-text">
                        {t(sat)}
                      </option>
                    ))}
                  </select>
                  <span className="filter-border"></span>
                </>
              )}

              {filterBy === "zone" && (
                <>
                  <select
                    className="form-select"
                    value={zone}
                    onChange={(e) => {
                      setZones(e.target.value);
                      // setIsSubZone(true)
                    }}
                    aria-label="Category"
                  >
                    <option value="" className="filter-text text-center">
                      {t("Select Zone")}
                    </option>
                    {zones.map((zon, index) => (
                      <option key={index} value={zon} className="filter-text text-center">
                        {t(zon)}
                      </option>
                    ))}
                  </select>
                  <span className="filter-border"></span>

                  {/* Conditionally Render Temple Category Dropdown */}
                  {["East Zone", "West Zone", "North Zone", "South Zone"].includes(zone) && (
                    <>
                      <select
                        className="form-select"
                        value={templecategory}
                        onChange={(e) => setTempleCategory(e.target.value)}
                        aria-label="Temple Category"
                      >
                        <option value="" className="filter-text">
                          {t("Select Category")}
                        </option>
                        {templeCategories.map((cat, index) => (
                          <option key={index} value={cat.id} className="filter-text">
                            {t(cat.name)}
                          </option>
                        ))}
                      </select>
                      <span className="filter-border"></span>
                    </>
                  )}
                </>
              )}
              {filterBy === "bookwise" && (
                <>
                  <select
                    className="form-select"
                    value={bookwisezone}
                    onChange={(e) => {
                      setBookWiseZones(e.target.value);
                    }}
                    aria-label="Category"
                  >
                    <option value="" className="filter-text text-center">
                      {t("Select Zone")}
                    </option>
                    {zones.map((zon, index) => (
                      <option key={index} value={zon} className="filter-text text-center">
                        {t(zon)}
                      </option>
                    ))}
                  </select>
                  <span className="filter-border"></span>
                  {/* Conditionally Render Temple Category Dropdown */}
                  {["East Zone", "West Zone", "North Zone", "South Zone"].includes(bookwisezone) && (
                    <select
                      className="form-select"
                      value={bookwisetemple}
                      onChange={(e) => setBookWiseTemple(e.target.value)}
                      aria-label="Temple Category"
                    >
                      <option value="" className="filter-text">
                        {t("Select Category")}
                      </option>
                      {templeCategories.map((cat, index) => (
                        <option key={index} value={cat.id} className="filter-text">
                          {t(cat.name)}
                        </option>
                      ))}
                    </select>
                  )}
                  <span className="filter-border"></span>
                </>
              )}

              {/* <span className="filter-border"></span> */}
              <div className="d-flex filter-by" style={{ textAlign: "center", cursor: "pointer", paddingLeft: "8px" }} onClick={resetFilters}>
                <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.14969 3.72539V0.349762L2.93016 4.56929L7.14969 8.78883V5.4132C9.94302 5.4132 12.2131 7.68331 12.2131 10.4766C12.2131 13.27 9.94302 15.5401 7.14969 15.5401C4.35636 15.5401 2.08625 13.27 2.08625 10.4766H0.398438C0.398438 14.2067 3.41962 17.2279 7.14969 17.2279C10.8798 17.2279 13.9009 14.2067 13.9009 10.4766C13.9009 6.74657 10.8798 3.72539 7.14969 3.72539Z"
                    fill="#9F1D21"
                  />
                </svg>
                &nbsp;&nbsp;
                <p className="filter-text reset-live-filters">{t("Reset Filter")}</p>
              </div>
            </div>
            <div className="col-2 d-flex align-items-center justify-content-end p-0">
              <button
                type="button"
                className="custom-compare-btn"
                onClick={() => {
                  openModal();
                  handleCompareFirstClick();
                }}
              >
                {t("Compare")}
              </button>
            </div>
          </div>
        </div>
        {/* Live Ranking Table */}

        <div className="container mt-4">
          {filterBy === "" && !categoryandzone && (
            <table className="table custom-table">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} className="table-row-header">
                    {t("Ranking")}
                  </th>

                  <th style={{ textAlign: "left", cursor: "pointer" }} onClick={handleHeaderClickPart} className="table-row-header">
                    {t("Particular")}
                    <span style={{ marginLeft: "5px", fontWeight: "normal", fontSize: "18px" }}>
                      {searchVisiblePart ? <i className="bi bi-chevron-up"></i> : <i className="bi bi-chevron-down"></i>}
                    </span>
                  </th>
                  <th style={{ textAlign: "left", cursor: "pointer" }} onClick={handleHeaderClickCat} className="table-row-header">
                    {t("Category")}
                    <span style={{ marginLeft: "5px", fontWeight: "normal", fontSize: "18px" }}>
                      {searchVisibleCat ? <i className="bi bi-chevron-up"></i> : <i className="bi bi-chevron-down"></i>}
                    </span>
                  </th>
                  <th style={{ textAlign: "left", cursor: "pointer" }} onClick={handleHeaderClickZone} className="table-row-header">
                    {t("Zone")}
                    <span style={{ marginLeft: "5px", fontWeight: "normal", fontSize: "18px" }}>
                      {searchVisibleZone ? <i className="bi bi-chevron-up"></i> : <i className="bi bi-chevron-down"></i>}
                    </span>
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Score")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Books Distributed")}
                  </th>
                </tr>
                {(searchVisibleCat || searchVisiblePart || searchVisibleZone) && (
                  <tr>
                    <td></td> {/* Empty cell to align the search input properly */}

                    {searchVisiblePart ? (
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={searchTextTempleName}
                          onChange={handleSearchChangeTempleName}
                          placeholder={t("Search by Particular")}
                        />
                      </td>
                    ) : (
                      <td></td>
                    )}
                    {searchVisibleCat ? (
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={searchTextTemple}
                          onChange={handleSearchChangeTemple}
                          placeholder={t("Search by Category")}
                        />
                      </td>
                    ) : (
                      <td></td>
                    )}
                    {searchVisibleZone ? (
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={searchTextZone}
                          onChange={handleSearchChangeZone}
                          placeholder={t("Search by Zone")}
                        />
                      </td>
                    ) : (
                      <td></td>
                    )}
                    <td></td> {/* Empty cell to align the search input properly */}
                    <td></td> {/* Empty cell to align the search input properly */}
                  </tr>
                )}
              </thead>
              <tbody>
                {filteredDataForSearch.map((row, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>

                    <td style={{ textAlign: "left" }}>{i18n.language === "hi" ? row.hinditempleName : row.temple_name}</td>
                    <td style={{ textAlign: "left" }}>{t(row.temple)}</td>
                    <td style={{ textAlign: "left" }}>{t(row.zone)}</td>
                    <td style={{ textAlign: "right" }}>{row.score ? Math.ceil(row.score).toLocaleString("en-IN") : "-"}</td>
                    <td style={{ textAlign: "right" }}>{row.no_of_book_distributed ? row.no_of_book_distributed.toLocaleString("en-IN") : "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="container mt-4">
          {!templecategory && zone && !categoryandzone && (
            <table className="table custom-table">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} className="table-row-header">
                    {t("Ranking")}
                  </th>

                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Particular")}
                  </th>
                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Category")}
                  </th>
                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Zone")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Score")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Books Distributed")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {(zone ? financialAndEventsSelectedZoneAllData : financialAndEventsSelectedZoneAllData).map((row, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>

                    <td style={{ textAlign: "left" }}>{i18n.language === "hi" ? row.hinditempleName : row.temple_name}</td>
                    <td style={{ textAlign: "left" }}>{t(row.temple)}</td>
                    <td style={{ textAlign: "left" }}>{t(row.zone)}</td>
                    <td style={{ textAlign: "right" }}>{row.score ? Math.ceil(row.score).toLocaleString("en-IN") : "-"}</td>

                    <td style={{ textAlign: "right" }}>{row.no_of_book_distributed ? row.no_of_book_distributed.toLocaleString("en-IN") : "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="container mt-4">
          {category && filterBy !== "state" && !categoryandzone && (
            <table className="table custom-table">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} className="table-row-header">
                    {t("Ranking")}
                  </th>

                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Particular")}
                  </th>
                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Category")}
                  </th>
                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Zone")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Score")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Books Distributed")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {(category ? financialAndEventsSelectedTempleAllData : financialAndEventsSelectedTempleAllData).map((row, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>

                    <td style={{ textAlign: "left" }}>{i18n.language === "hi" ? row.hinditempleName : row.temple_name}</td>
                    <td style={{ textAlign: "left" }}>{t(row.temple)}</td>

                    <td style={{ textAlign: "left" }}>{t(row.zone)}</td>
                    <td style={{ textAlign: "right" }}>{row.score ? Math.ceil(row.score).toLocaleString("en-IN") : "-"}</td>

                    <td style={{ textAlign: "right" }}>{row.no_of_book_distributed ? row.no_of_book_distributed.toLocaleString("en-IN") : "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="container mt-4">
          {states && !categoryandzone && (
            <table className="table custom-table">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} className="table-row-header">
                    {t("Ranking")}
                  </th>

                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Particular")}
                  </th>
                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Category")}
                  </th>
                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Zone")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Score")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Books Distributed")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {(states ? financialAndEventsSelectedStateAllData : financialAndEventsSelectedStateAllData).map((row, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>

                    <td style={{ textAlign: "left" }}>{i18n.language === "hi" ? row.hinditempleName : row.temple_name}</td>
                    <td style={{ textAlign: "left" }}>{t(row.temple)}</td>
                    <td style={{ textAlign: "left" }}>{t(row.zone)}</td>
                    <td style={{ textAlign: "right" }}>{row.score ? Math.ceil(row.score).toLocaleString("en-IN") : "-"}</td>

                    <td style={{ textAlign: "right" }}>{row.no_of_book_distributed ? row.no_of_book_distributed.toLocaleString("en-IN") : "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {selectedFinancialYear && filterBy === "zone" && !zone && !states && !categoryandzone && (
          <div className="container mt-4">
            <table className="table custom-table">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} className="table-row-header">
                    {t("Ranking")}
                  </th>
                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Zone")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Score")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Books Distributed")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {financialAndEventsZoneAllData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td style={{ textAlign: "left" }}>{t(row.zone_id)}</td>
                    <td style={{ textAlign: "right" }}>{Math.ceil(row.total_score).toLocaleString("en-IN")}</td>
                    <td style={{ textAlign: "right" }}>{row.total_books.toLocaleString("en-IN")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Book Wise Data Fetch Start */}
        {!bookwisezone && selectedFinancialYear && filterBy === "bookwise" && !zone && !states && !categoryandzone && (
          <div className="container mt-4">
            <table className="table custom-table">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} className="table-row-header">
                    {t("Ranking")}
                  </th>
                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Book Name")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Score")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Books Distributed")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {financialAndEventsBookWiseAllData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td style={{ textAlign: "left" }}>{t(bookIdMapping[row.book_id] || row.book_id)}</td>
                    <td style={{ textAlign: "right" }}>{Math.ceil(row.total_score).toLocaleString("en-IN")}</td>
                    <td style={{ textAlign: "right" }}>{row.total_books.toLocaleString("en-IN")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {!bookwisetemple && filterBy !== "state" && selectedFinancialYear && bookwisezone && !categoryandzone && (
          <div className="container mt-4">
            <table className="table custom-table">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} className="table-row-header">
                    {t("Ranking")}
                  </th>
                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Book Name")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Score")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Books Distributed")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {allDataBookWiseWithZone.map((row, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td style={{ textAlign: "left" }}>{t(bookIdMapping[row.book_id] || row.book_id)}</td>
                    <td style={{ textAlign: "right" }}>{Math.ceil(row.total_score).toLocaleString("en-IN")}</td>
                    <td style={{ textAlign: "right" }}>{row.total_books.toLocaleString("en-IN")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {selectedFinancialYear && bookwisezone && bookwisetemple && !categoryandzone && (
          <div className="container mt-4">
            <table className="table custom-table">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} className="table-row-header">
                    {t("Ranking")}
                  </th>
                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Book Name")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Score")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Books Distributed")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {allDataBookWiseWithZoneandTemple.map((row, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td style={{ textAlign: "left" }}>{t(bookIdMapping[row.book_id] || row.book_id)}</td>
                    <td style={{ textAlign: "right" }}>{Math.ceil(row.total_score).toLocaleString("en-IN")}</td>
                    <td style={{ textAlign: "right" }}>{row.total_books.toLocaleString("en-IN")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* Book Wise Data Fetch End */}

        {!states && selectedFinancialYear && filterBy === "temple" && !category && !categoryandzone && (
          <div className="container mt-4">
            <table className="table custom-table">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} className="table-row-header">
                    {t("Ranking")}
                  </th>
                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Particular")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Score")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Books Distributed")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {financialAndEventsTempleAllData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td style={{ textAlign: "left" }}>{t(row.temple_id)}</td>
                    <td style={{ textAlign: "right" }}>{Math.ceil(row.total_score).toLocaleString("en-IN")}</td>
                    <td style={{ textAlign: "right" }}>{row.total_books.toLocaleString("en-IN")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {selectedFinancialYear && filterBy === "state" && !states && !categoryandzone && (
          <div className="container mt-4">
            <table className="table custom-table">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} className="table-row-header">
                    {t("Ranking")}
                  </th>
                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("State")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Score")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Books Distributed")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {financialAndEventsStateAllData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td style={{ textAlign: "left" }}>{t(row.state_name)}</td>
                    <td style={{ textAlign: "right" }}>{Math.ceil(row.total_score).toLocaleString("en-IN")}</td>
                    <td style={{ textAlign: "right" }}>{row.total_books.toLocaleString("en-IN")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* 
                <div className="container mt-4">
                    {!isSubZone && filterBy === "zone" ? (
                        <table className="table custom-table">
                            <thead>
                                <tr>
                                    <th className="table-row-header">Ranking</th>
                                    <th className="table-row-header">Zone</th>
                                    <th className="table-row-header">Score</th>
                                    <th className="table-row-header">Books Distributed</th>

                                </tr>
                            </thead>
                            <tbody>
                                {liveRankingDataZoneWiseN.map((row, index) => (
                                    <tr key={index}>
                                        <td>{row.index}</td>
                                        <td>{row.zone_id}</td>
                                        <td>{Math.ceil(row.total_score)}</td>
                                        <td>{Math.ceil(row.total_books)}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <table className="table custom-table">
                            <thead>
                                <tr>
                                    <th className="table-row-header">Ranking</th>
                                    <th className="table-row-header">Particular</th>
                                    <th className="table-row-header">Score</th>
                                    <th className="table-row-header">Books Distributed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {liveRankingData.map((row, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{row.temple_name}</td>
                                        <td>
  {row.score ? Math.ceil(row.score).toLocaleString() : "-"}
</td>
                                        <td>
  {row.no_of_book_distributed ? row.no_of_book_distributed.toLocaleString() : "-"}
</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div> */}

        <div className="container mt-4">
          {templecategory && filterBy !== "bookwise" && !categoryandzone && (
            <table className="table custom-table">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} className="table-row-header">
                    {t("Ranking")}
                  </th>

                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Particular")}
                  </th>
                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Category")}
                  </th>
                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Zone")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Score")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Books Distributed")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {(templecategory ? TempleWiseDataBasedonFEZ : TempleWiseDataBasedonFEZ).map((row, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>

                    <td style={{ textAlign: "left" }}>{i18n.language === "hi" ? row.hinditempleName : row.temple_name}</td>
                    <td style={{ textAlign: "left" }}>{t(row.temple)}</td>
                    <td style={{ textAlign: "left" }}>{t(row.zone)}</td>
                    <td style={{ textAlign: "right" }}>{row.score ? Math.ceil(row.score).toLocaleString("en-IN") : "-"}</td>

                    <td style={{ textAlign: "right" }}>{row.no_of_book_distributed ? row.no_of_book_distributed.toLocaleString("en-IN") : "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="container mt-4">
          {categoryandzone && (
            <table className="table custom-table mt-4">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} className="table-row-header">
                    {t("Ranking")}
                  </th>

                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Particular")}
                  </th>
                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Category")}
                  </th>
                  <th style={{ textAlign: "left" }} className="table-row-header">
                    {t("Zone")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Score")}
                  </th>
                  <th style={{ textAlign: "right" }} className="table-row-header">
                    {t("Books Distributed")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {allDataFYECandZone.map((row, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>

                    <td style={{ textAlign: "left" }}>{i18n.language === "hi" ? row.hinditempleName : row.temple_name}</td>
                    <td style={{ textAlign: "left" }}>{t(row.temple)}</td>
                    <td style={{ textAlign: "left" }}>{t(row.zone)}</td>
                    <td style={{ textAlign: "right" }}>{row.score ? Math.ceil(row.score).toLocaleString("en-IN") : "-"}</td>
                    <td style={{ textAlign: "right" }}>{row.no_of_book_distributed ? row.no_of_book_distributed.toLocaleString("en-IN") : "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* Modal for comparing with other temples */}
        {/* 
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Compare Temples"
                    className="Modal"
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEsc={false}
                    overlayClassName="Overlay"
                    style={{
                        content: {
                            position: "absolute",
                            top: "120px",
                            left: "0",
                            right: "0",
                            bottom: "100px", // Add bottom margin to avoid footer
                            backgroundColor: "#fff", // Ensure a solid background
                            margin: "0",
                            padding: "20px",
                            height: "609px",
                            maxHeight: "79vh", // Limit height to 80% of viewport height
                            width: "96vw",
                            overflow: "auto",
                            marginLeft: "30px",
                        },
                        overlay: {
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            backdropFilter: "blur(8px)", // Add blur effect
                        },
                    }}
                >
                    <div className="col-12 text-right">
                        <button
                            type="button"
                            style={{
                                position: "absolute",
                                top: "0px",
                                right: "16px",
                                border: "none",
                                background: "#9F1D21",
                                borderRadius: "50%",
                                width: "40px", // Adjust width as needed
                                height: "40px", // Adjust height as needed
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "20px",
                                fontWeight: "bold",
                                cursor: "pointer",
                                color: "#fff",
                                textAlign: "center",
                                padding: "0",
                                lineHeight: "1"
                            }}
                            onClick={closeModal}
                            aria-label="Close"
                        >
                            &times;
                        </button>
                    </div>

                    <div className="row align-items-start" style={{ marginBottom: "24px" }}>
                        <div className="col-6 text-start">
                            <h2 style={{
                                fontSize: "23px",
                                fontWeight: "600",
                                color: "#43567D",
                                lineHeight: "36px"
                            }}>
                                {t("Compare With Another Temple")}
                            </h2>
                        </div>



                        <div className="col-3 text-right">
                            <select
                                className="form-select text-start filter-text"
                                value={selectedYearForCompare}
                                onChange={(e) => setSelectedYearForCompare(e.target.value)}
                                style={{ padding: "10px", width: "225px", marginLeft: "130px", borderRadius: "6px", fontSize: "16px" }}
                            >
                                {financialYearArray.map((option) => (
                                    <option key={option.id} value={option.financial_year} className="filter-text">
                                        {option.financial_year}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-3 text-right">
                            <select
                                className="form-select text-start filter-text"
                                value={selectedEventForCompare}
                                onChange={(e) => setSelectedEventForComapre(e.target.value)}
                                aria-label="Events"
                                style={{ padding: "10px", width: "215px", borderRadius: "6px", fontSize: "16px" }}
                            >
                                <option value="" className="filter-text">
                                    {t("Events")}
                                </option>
                                <option value="Regular Update" className="filter-text">
                                    {t("Regular Update")}
                                </option>
                                <option value="Bhadra Purnima" className="filter-text">
                                    {t("Bhadra Purnima")}
                                </option>
                                <option value="Chaitanya Charitamrita" className="filter-text">
                                    {t("Chaitanya Charitamrita")}
                                </option>
                                <option value="Gita Marathon" className="filter-text">
                                    {t("Gita Marathon")}
                                </option>
                            </select>
                        </div>



                    </div>

                    <div style={{
                        width: "150%",   // Adjust as needed
                        height: "0px",
                        marginLeft: "-20px",
                        borderBottom: "0.3px solid #909AAD",
                        marginBottom: "24px"  // Adjust spacing as needed
                    }}></div>
                    <div className="row" style={{ marginLeft: "170px" }}>
                        {[...Array(numCols)].map((_, index) => (
                            <div key={index} className="col-3 filter-container-comparing">
                                <select
                                    className="form-select text-center filter-text"
                                    value={selectedTemples[index]}
                                    onChange={(e) => handleTempleSelect(index, e)}
                                    style={{ padding: "10px", borderRadius: "6px", fontSize: "16px" }}

                                >
                                    <option value="">{t("Select Temple")}</option>
                                    {CompareAllData.length > 0 && CompareAllData
                                        .sort((a, b) => a.temple_name.localeCompare(b.temple_name))
                                        .filter(temple => !selectedTemples.includes(temple.temple_id) || temple.temple_id === selectedTemples[index])
                                        .map((temple, idx) => (
                                            <option key={idx} value={temple.temple_id} className="filter-text">
                                                {temple.temple_name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        ))}
                    </div>

                    <Table striped bordered hover responsive className="mt-4 custom-comparetable">
                        <tbody>
                            {currentLabels.map((label, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td width="130" className="text-start">
                                        <span className="filter-text" style={{ fontSize: "16px" }}>
                                            {t(label)}
                                        </span>
                                    </td>
                                    {[...Array(numCols)].map((_, colIndex) => (
                                        <td width="200" key={colIndex}>
                                            {dataArrays[colIndex] ? dataArrays[colIndex][rowIndex] || "" : ""}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal> */}

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Compare Temples"
          className="Modal compare-modal"
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          overlayClassName="Overlay"
          style={{
            content: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              // backgroundColor: "#fff",
              padding: "25px",
              borderRadius: "10px",
              maxWidth: "85%", // Reduced width to make it more compact
              maxHeight: "90%",
              width: "1363px",
              height: "100%",
              // width: "fit-content",
              // height: "fit-content",
              // overflow: "auto",
              // boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(3px)", // Background blur effect
              zIndex: 1000,
            },
          }}
        >
          <div className="compare-table-body">
            <div className="col-12 text-right">
              <button
                type="button"
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  border: "none",
                  background: "#9F1D21",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "#fff",
                  lineHeight: "1",
                }}
                onClick={closeModal}
                aria-label="Close"
              >
                &times;
              </button>
            </div>

            <div className="row align-items-start" style={{ marginBottom: "16px", marginTop: "20px", padding: "0px 5px" }}>
              <div className="col-6 text-start">
                <h2
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                    color: "#43567D",
                    lineHeight: "32px",
                  }}
                >
                  {t("Compare With Another Temple")}
                </h2>
              </div>

              <div className="col-3 text-right">
                <select
                  className="form-select text-start filter-text"
                  value={selectedYearForCompare}
                  onChange={(e) => setSelectedYearForCompare(e.target.value)}
                  style={{
                    padding: "8px",
                    width: "200px",
                    marginLeft: "100px",
                    borderRadius: "6px",
                    fontSize: "14px",
                    backgroundColor: "#F8F9FF",
                    border: "1px solid #DBDFF2",
                    color: "#6E717F",
                  }}
                >
                  {financialYearArray.map((option) => (
                    <option key={option.id} value={option.financial_year} className="filter-text">
                      {option.financial_year}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-3" style={{ display: "flex", justifyContent: "flex-end" }}>
                <select
                  className="form-select text-start filter-text"
                  value={selectedEventForCompare}
                  onChange={(e) => setSelectedEventForComapre(e.target.value)}
                  aria-label="Events"
                  style={{
                    padding: "8px",
                    width: "190px",
                    borderRadius: "6px",
                    fontSize: "14px",
                    backgroundColor: "#F8F9FF",
                    border: "1px solid #DBDFF2",
                    color: "#6E717F",
                  }}
                >
                  <option value="" className="filter-text">
                    {t("Events")}
                  </option>
                  <option value="Regular Update" className="filter-text">
                    {t("Regular Update")}
                  </option>
                  <option value="Bhadra Purnima" className="filter-text">
                    {t("Bhadra Purnima")}
                  </option>
                  <option value="Chaitanya Charitamrita" className="filter-text">
                    {t("Chaitanya Charitamrita")}
                  </option>
                  <option value="Gita Marathon" className="filter-text">
                    {t("Gita Marathon")}
                  </option>
                </select>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "0px",
                borderBottom: "0.3px solid #909AAD",
                marginBottom: "16px",
              }}
            ></div>

            <div className="row" style={{ paddingLeft: "185px" }}>
              {[...Array(numCols)].map((_, index) => (
                <div key={index} className="col-3 filter-container-comparing">
                  <select
                    className="form-select filter-text"
                    value={selectedTemples[index]}
                    onChange={(e) => handleTempleSelect(index, e)}
                    style={{
                      padding: "8px",
                      borderRadius: "6px",
                      fontSize: "14px",
                      backgroundColor: "#F8F9FF",
                      border: "1px solid #DBDFF2",
                      color: "#6E717F",
                      width: "220px",
                    }}
                  >
                    <option value="">{t("Select Temple")}</option>
                    {CompareAllData.length > 0 &&
                      CompareAllData.sort((a, b) => a.temple_name.localeCompare(b.temple_name))
                        .filter((temple) => !selectedTemples.includes(temple.temple_id) || temple.temple_id === selectedTemples[index])
                        .map((temple, idx) => (
                          <option key={idx} value={temple.temple_id} className="filter-text">
                            {i18n.language === "hi" ? temple.hinditempleName : temple.temple_name}
                          </option>
                        ))}
                  </select>
                </div>
              ))}
            </div>
            <div style={{ padding: "0px 0px 0px 20px", height: "70%", overflow: "auto" }}>
              <Table bordered responsive className="table custom-comparetable">
                <tbody>
                  {currentLabels.map((label, rowIndex) => (
                    <tr key={rowIndex}>
                      <td width="120" className="text-start">
                        <span className="filter-text" style={{ fontSize: "14px" }}>
                          {t(label)}
                        </span>
                      </td>
                      {[...Array(numCols)].map((_, colIndex) => (
                        <td width="180" key={colIndex} style={{ fontSize: "14px" }}>
                          {dataArrays[colIndex] ? t(dataArrays[colIndex][rowIndex]) || "" : ""}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default LiveRankingData;
