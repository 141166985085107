import html2canvas from "html2canvas";
import moment from "moment";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ApiService from "../../api/ApiService";

export default function RSATempleBookStats({ selectedEvent, user }) {
  const { t, i18n } = useTranslation();
  const [templeData, setTempleData] = useState([]);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const currentYear = moment().year();
  const previousYear = moment().subtract(1, "year").year();

  const handleDownload = () => {
    setIsButtonVisible(false);

    setTimeout(() => {
      html2canvas(document.querySelector("#rsa-temple-stats")).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "temple-stats.png";
        link.click();
      });
    }, 1000);

    setTimeout(() => {
      setIsButtonVisible(true);
    }, 1000);
  };

  const getRSATempleStatsData = async () => {
    const postData = {
      temple_array: user.temple_array,
      state_array: user.state_array,
      zone_array: user.zone_array,
      event: selectedEvent,
    };

    const res = await ApiService.getRSATempleStats(postData);
    setTempleData(res.scoreData);
  };
  useEffect(() => {
    getRSATempleStatsData(user);
  }, [user, selectedEvent]);

  return (
    <>
      {templeData.length > 0 && (
        <div className="year-rank-card" id="rsa-temple-stats">
          <div className="comparison-header">
            <div className="temple-list-head-sec">
              <h2 className="pledge-head-temple-points">{t("Year Wise Book Stats")}</h2>
            </div>
            {isButtonVisible && (
              <span className="button-text-download" onClick={handleDownload}>
                {t("Download")}
              </span>
            )}
          </div>
          <h2 className="comparison-text">
            {t("Book Distribution")} {currentYear} {t("Vs")} {previousYear}
          </h2>

          <Table bordered hover responsive className="ranking-table">
            <thead className="table-head">
              <tr>
                <th scope="col text-start" className="text-start">
                  {t("Category")}
                </th>
                <th scope="col text-start" className="text-end">
                  {currentYear}
                </th>
                <th scope="col text-start" className="text-end">
                  {previousYear}
                </th>
              </tr>
            </thead>
            <tbody>
              {templeData.map((item, i) => (
                <tr>
                  <td className="welcome-info-text text-start" style={{ lineHeight: "20px" }}>
                    {i18n.language === "hi" ? item?.hinditempleName : item.temple_name}
                  </td>
                  <td className="text-end">{item?.totalCurrentYearScoreCount ?? "-"}</td>
                  <td className="text-end">{item?.totalPreviousYearScoreCount ?? "-"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
}
