import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "./i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.scss";

import LogInWrapper from "./pages/logInWrapper.jsx";
import Dashboard from "./pages/Dashboard.jsx";
import RecordingPledge from "./pages/RecordingOfPledges.jsx";
import AppContext from "./context/AppContext";
import UpdateScore from "./pages/UpdateIccScore.jsx";
import LiveRanking from "./pages/LiveRanking.jsx";
import BlogsAndArticles from "./pages/blogs.jsx";
import BlogDetails from "./pages/blogDetails.jsx";
import MyProfile from "./pages/myProfile.jsx";
import Header from "./components/layout/header.jsx";
import Footer from "./components/layout/footer.jsx";
import Forum from "./pages/forum.jsx";
import ForumDetails from "./pages/forumDetails.jsx";
import ProtectedRoute from "./protected-route/ProtectedRoute.js";
import VideosSection from "./pages/videos.jsx";
import AudioSection from "./pages/audio.jsx";
import ViewTransactions from "./pages/viewTransactions.jsx";
import NotificationDetails from "./components/my-profile/notificationDetails.jsx";
import Resources from "./pages/resources.jsx";
import ContactUs from "./pages/contactUs.jsx";
import Quotes from "./pages/quotes.jsx";
import ConditionalGoogleTranslate from "./components/common/ConditionalGoogleTranslate.jsx";
import LiveScoreUpdates from "./components/common/liveScoreUpdates.jsx";
import RSATemplesStats from "./pages/RSATemplesStats.jsx";

export default function App() {
  const { user } = useContext(AppContext);

  return (
    <HelmetProvider>
      <Router>
        {user != null && <Header />}
        {/* <ConditionalGoogleTranslate /> */}
        <div className={user !== null ? "children-components" : "login-background-image"}>
          {user != null && <LiveScoreUpdates />}
          <Routes>
            {user != null && <Route exact path="/login" element={<ProtectedRoute children={<LogInWrapper />} />} />}
            <Route exact path="/login" element={<LogInWrapper />}></Route>
            <Route path="/" element={<ProtectedRoute children={<Dashboard />} />} />
            <Route path="/update-score" element={<ProtectedRoute children={<UpdateScore />} />} />
            <Route path="/temple-stats" element={<ProtectedRoute children={<RSATemplesStats />} />} />
            <Route exact path="/recording-pledge" element={<ProtectedRoute children={<RecordingPledge />} />} />
            <Route exact path="/live-ranking" element={<ProtectedRoute children={<LiveRanking />} />} />
            <Route exact path="/blogs" element={<ProtectedRoute children={<BlogsAndArticles />} />} />
            <Route exact path="/blogs/:id" element={<ProtectedRoute children={<BlogsAndArticles />} />} />
            <Route exact path="/blog-details/:id" element={<ProtectedRoute children={<BlogDetails />} />} />
            <Route path="/my-profile" element={<ProtectedRoute children={<MyProfile />} />} />
            <Route exact path="/forum" element={<ProtectedRoute children={<Forum />} />} />
            <Route exact path="/forum-details/:id" element={<ProtectedRoute children={<ForumDetails />} />} />
            <Route exact path="/videos" element={<ProtectedRoute children={<VideosSection />} />} />
            <Route exact path="/audios" element={<ProtectedRoute children={<AudioSection />} />} />
            <Route exact path="/view-history" element={<ProtectedRoute children={<ViewTransactions />} />} />
            <Route exact path="/notification-details/:id" element={<ProtectedRoute children={<NotificationDetails />} />} />
            <Route exact path="/posters" element={<ProtectedRoute children={<Resources />} />} />
            <Route exact path="/contact-us" element={<ProtectedRoute children={<ContactUs />} />} />
            <Route exact path="/quotes" element={<ProtectedRoute children={<Quotes />} />} />
          </Routes>
        </div>
      </Router>
      {user != null && <Footer />}
    </HelmetProvider>
  );
}
