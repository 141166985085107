// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector) // Detects the user's language
    .use(initReactI18next) // Passes i18n instance to react-i18next.
    .init({
        resources: {
            en: {
                translation: {
                    "Dashboard": "Dashboard",
                    "Live Ranking": "Live Ranking",
                    "Pledges": "Pledges",
                    "Update Score": "Update Score",
                    "Resources": "Resources",
                    "Forum": "Forum",
                    "Contact Us": "Contact Us",
                    "Videos": "Videos",
                    "Blogs": "Blogs",
                    "Posters": "Posters",
                    "Quotes": "Quotes",
                    "Logout Account": "Logout Account",
                    "language": "Language",
                    "Pages": "Pages",
                    "500+ Pages": "500+ Pages",
                    "300-500 Pages": "300-500 Pages",
                    "150-300 Pages": "150-300 Pages",
                    "100-150 Pages": "100-150 Pages",
                    "10-50 Pages": "10-50 Pages",
                    "English Book": "English Book",
                    "Non-English Book": "Non-English Book",
                    "ChaitanyaCharitamritaSet": "Chaitanya Charitamrita Set",
                    "SrimadBhagavatamSet": "Srimad Bhagavatam Set",
                    "MahabigBooks": "Mahabig Books",
                    "BigBooks": "Big Books",
                    "MediumBooks": "Medium Books",
                    "SmallBooks": "Small Books",
                    "Magazines": "Magazines",
                    "1 Year BTG Subscription-1": "1 Year BTG Subscription-1",
                    "1 Year BTG Subscription-2": "1 Year BTG Subscription-2",
                    "Finished": "Finished",
                    "Unfinished": "Unfinished",
                    "In Progress": "In Progress",
                    "Top 10": "Top 10",
                    "Temples": "Temples",
                    "Top 10": "Top 10",
                    "Temples": "Temples",
                    "in": "in",
                    "Big Temple": "Big Temple",
                    "Small Temple": "Small Temple",
                    "Medium Temple": "Medium Temple",
                    "Outpost": "Outpost",
                    "Preaching Centre": "Preaching Centre",
                    "Extension Centre": "Extension Centre",
                    "Points": "Points",
                    "State Rank": "State Rank",
                    "Zonal Ranking": "Zonal Ranking",
                    "Category Rank": "Category Rank",
                    "Total Points": "Total Points",
                    "Books Distributed": "Books Distributed",
                    "Events": "Events",
                    "Regular Update": "Regular Update",
                    "Bhadra Purnima": "Bhadra Purnima",
                    "Chaitanya Charitamrita": "Chaitanya Charitamrita",
                    "Gita Marathon": "Gita Marathon",
                    "All India Ranking": "All India Ranking",
                    "Zone Wise": "Zone Wise",
                    "Category Wise": "Category Wise",
                    "State Wise": "State Wise",
                    "Book Wise": "Book Wise",
                    "Filter By": "Filter By",
                    "Reset Filter": "Reset Filter",
                    "Ranking": "Ranking",
                    "Category": "Category",
                    "Particular": "Particular",
                    "Zone": "Zone",
                    "Score": "Score",
                    "Books Distributed": "Books Distributed",
                    "Compare": "Compare",

                    "Dedicated to His Divine Grace A.C. Bhaktivedanta Swami Prabhupada - Founder Acharya of the International Society for Krishna Consciousness.": "Dedicated to His Divine Grace A.C. Bhaktivedanta Swami Prabhupada - Founder Acharya of the International Society for Krishna Consciousness.",
                    "All Rights Reserved.": "All Rights Reserved."
                    ,
                    "Submit Your Pledge": "Submit Your Pledge",
                    "Submit your pledge, update your pledge, and view your pledge history. A Regular Update refers to book distribution that occurs throughout the year, whereas a marathon refers to pledges undertaken during the marathon period.": "Submit your pledge, update your pledge, and view your pledge history. A Regular Update refers to book distribution that occurs throughout the year, whereas a marathon refers to pledges undertaken during the marathon period.",
                    "Your Current Pledge": "Your Current Pledge",
                    "View Historical Pledges": "View Historical Pledges",
                    "Submit Your Pledge": "Submit Your Pledge",
                    "Edit your pledge here": "Edit your pledge here",
                    "Book": "Book",
                    "Points": "Points",
                    "Actual Pledge": "Actual Pledge",
                    "Count": "Count",
                    "Total Count": "Total Count",
                    "Total": "Total",
                    "9 Mahabig Books": "9 Mahabig Books",
                    "18 Mahabig Books": "18 Mahabig Books",
                    "Save": "Save",
                    "Pledged": "Pledged",
                    "Actuals": "Actuals",
                    "Are you sure you want to update the books count in": "Are you sure you want to update the books count in",
                    "Yes": "Yes",
                    "Cancel": "Cancel",
                    "Upload File": "Upload File",
                    "You are Uploading": "You are Uploading",
                    "Bulk Score": "Bulk Score",
                    "Close": "Close",
                    "Big Temple": "Big Temple",
                    "Small Temple": "Small Temple",
                    "Medium Temple": "Medium Temple",
                    "Outpost": "Outpost",
                    "Preaching Centre": "Preaching Centre",
                    "Extension Centre": "Extension Centre",
                    "Drop your file or click here to browse": "Drop your file or click here to browse",
                    "UPLOAD": "UPLOAD",
                    "South Zone": "South Zone",
                    "West Zone": "West Zone",
                    "East Zone": "East Zone",
                    "North Zone": "North Zone",
                    "Andaman & Nicobar Islands": "Andaman & Nicobar Islands",
                    "Andhra Pradesh": "Andhra Pradesh",
                    "Arunachal Pradesh": "Arunachal Pradesh",
                    "Assam": "Assam",
                    "Bihar": "Bihar",
                    "Chandigarh": "Chandigarh",
                    "Chhattisgarh": "Chhattisgarh",
                    "Dadra & Nagar Haveli": "Dadra & Nagar Haveli",
                    "Daman & Diu": "Daman & Diu",
                    "Delhi": "Delhi",
                    "Goa": "Goa",
                    "Gujarat": "Gujarat",
                    "Haryana": "Haryana",
                    "Himachal Pradesh": "Himachal Pradesh",
                    "Jammu & Kashmir": "Jammu & Kashmir",
                    "Jharkhand": "Jharkhand",
                    "Karnataka": "Karnataka",
                    "Kerala": "Kerala",
                    "Lakshadweep": "Lakshadweep",
                    "Madhya Pradesh": "Madhya Pradesh",
                    "Maharashtra": "Maharashtra",
                    "Manipur": "Manipur",
                    "Meghalaya": "Meghalaya",
                    "Mizoram": "Mizoram",
                    "Nagaland": "Nagaland",
                    "Odisha": "Odisha",
                    "Puducherry": "Puducherry",
                    "Punjab": "Punjab",
                    "Rajasthan": "Rajasthan",
                    "Sikkim": "Sikkim",
                    "Tamil Nadu": "Tamil Nadu",
                    "Tripura": "Tripura",
                    "Uttar Pradesh": "Uttar Pradesh",
                    "Uttarakhand": "Uttarakhand",
                    "West Bengal": "West Bengal",
                    "Telangana": "Telangana",
                    "Srimad Bhagavatam Sets": "Srimad Bhagavatam Sets",
                    "Chaitanya Charitamrita Sets": "Chaitanya Charitamrita Sets",
                    "Click Here to Download the Template": "Click Here to Download the Template",
                    "Instruction How to Upload the Data": "Instruction How to Upload the Data",
                    "Date fields": "Date fields",
                    "Showing": "Showing",
                    "The below graphs indicates state wise performance of book distribution.": "The below graphs indicates state wise performance of book distribution.",
                    "Darker color indicates higher book distribution, lighter color indicates lower book distribution.": "Darker color indicates higher book distribution, lighter color indicates lower book distribution.",
                    "In Book Name please add the name of the book with correct spelling.": "In Book Name please add the name of the book with correct spelling.",
                    "In Book Count please add the book distribution count.": "In Book Count please add the book distribution count.",
                    "In the Date fields please add the date for which you need to upload the data. The date format should be DD-MM-YYYY.": "In the Date fields please add the date for which you need to upload the data. The date format should be DD-MM-YYYY.",
                    "Upload file should be in the .xlsx format.": "Upload file should be in the .xlsx format.",
                    "Note: Once the file is uploaded, no corrections can be made.": "Note: Once the file is uploaded, no corrections can be made.",
                    "हिन्दी": "Hindi",
                    // Add more translations here...
                },
            },
            hi: {
                translation: {
                    "Dashboard": "डैशबोर्ड",
                    "Live Ranking": "तात्कालिक प्राप्त स्थान",
                    "Pledges": "संकल्प",
                    "Update Score": "अंक अपडेट करें",
                    "Resources": "संसाधन",
                    "Forum": "मंच",
                    "Contact Us": "संपर्क करें",
                    "Videos": "वीडियो",
                    "Blogs": "ब्लॉग",
                    "Posters": "पोस्टर",
                    "Poster": "पोस्टर",
                    "Banner": "बैनर",
                    "Book Mark": "बुक मार्क",
                    "Pamphlet": "पुस्तिका",
                    "Quotes": "सुविचार",
                    "Our Forum": "हमारा मंच",
                    "View Questions": "प्रश्न देखें",
                    "Questions": "प्रासनाये",
                    "Question": "सवाल",
                    "Search...": "खोजें...",
                    "Reset All": "सभी को पुनः तैयार करना",
                    "Ask Question": "प्रश्न पूछो",
                    "Newest": "नवीनतम",
                    "Sort By": "क्रमबद्ध करें",
                    "Search by Category": "श्रेणी के आधार पर खोजें",
                    "Trending": "ट्रेंडिंग",
                    "Likes": "पसंद है",
                    "Answers": "जवाब",
                    "Search by Zone": "ज़ोन के अनुसार खोजें",
                    "Search by Particular": "विशेष द्वारा खोजें",
                    "Back to Forum": "मंच पर वापस",
                    "Back": "वापस जाओ",
                    "Most Liked": "सर्वाधिक पसंद किया गया",
                    "Last Month": "पिछले महीने",
                    "Last Week": "पिछले सप्ताह",
                    "No Questions Found": "कोई प्रश्न नहीं मिला",
                    "Congregation Book Distribution": "मण्डली पुस्तक वितरण",
                    "Logout Account": "लॉग आउट करें",
                    "language": "भाषा",
                    "Welcome": "स्वागत है",
                    "Explore the dashboard for detailed insights and current progress in book distribution": "डैशबोर्ड का उपयोग करके विस्तृत जानकारी और किताबों के वितरण में वर्तमान प्रगति की जानकारी प्राप्त करें।",
                    "You are on the": "आप",
                    "rank": "रैंक पर हैं",
                    "Rank": "रैंक पर हैं",
                    "State Name": "राज्य का नाम",
                    "Your score will please Srila Prabhupada.": "आपके अंक श्रीला प्रभुपाद को प्रसन्नता प्रदान करेंगे।",
                    "Your Pledge": "आपका संकल्प",
                    "Your Points": "आपके अंक",
                    "distributed": "वितरित",
                    "books": "पुस्तकें",
                    "Download Sample": "सैंपल डाउनलोड करें",
                    "Pledge Covered": "संकल्प सम्पूर्णता की स्थिति",
                    "Your pledge completion status": "आपका संकल्प की स्थिति",
                    "Your Ranking": "आपकी श्रेणी",
                    "Regular Distribution": "नियमित वितरण",
                    "Bhadra Purnima Marathon": "भाद्र पूर्णिमा मैराथन",
                    "Chaitanya Charitamrita Marathon": "चैतन्य चरितामृत मैराथन",
                    "Gita Marathon": "गीता मैराथन",
                    "All India Rank": "भारत में प्राप्त स्थान",
                    "State Rank": "राज्य में प्राप्त स्थान",
                    "Zonal Rank": "क्षेत्र में प्राप्त स्थान",
                    "Category Rank": "वर्ग में प्राप्त स्थान",
                    "Total Points": "कुल अंक",
                    "Book Wise Growth Chart": "पुस्तकों के अनुसार वृद्धि चार्ट",
                    "Year Wise Growth Chart": "वर्ष के अनुसार वृद्धि चार्ट",
                    "Book": "किताब",
                    "CC Set": "सीसी सेट",
                    "SB-Set": "एसबी-सेट",
                    // "Mahabig Books":
                    "Progress": "प्रगति",
                    "Books Count": "पुस्तकों की गिनती",
                    "Status": "स्थिति",
                    "Download": "डाउनलोड करे",
                    "Chaitanya Charitamrita Set": "चैतन्य चरितामृत सेट",
                    "Srimad Bhagavatam Set": "श्रीमद-भागवतम सेट",
                    "Mahabig Books": "महाबिग पुस्तकें",
                    "Big Books": "बड़ी पुस्तकें",
                    "Medium Books": "मध्यम पुस्तकें",
                    "Small Books": "छोटी पुस्तकें",
                    "Magazines": "पत्रिकाएं",
                    "1-Year BTG-Eng": "1-वर्ष बीटीजी-अंग्रे.",
                    "1-Year BTG-Non-Eng": "1-वर्ष बीटीजी-गैर-अंग्रे.",
                    "Last Year": "पिछले साल",
                    "Current Year": "चालू वर्ष",
                    "1 Year BTG Subscription-1": "1 साल की बीटीजी सदस्यता-1",
                    "1 Year BTG Subscription-2": "1 साल की बीटीजी सदस्यता-2",
                    "Pages": "पृष्ठ",
                    "Are you sure you want to delete the question?": "क्या आप वाकई प्रश्न हटाना चाहते हैं?",
                    "500+ Pages": "500+ पृष्ठ",
                    "300-500 Pages": "300-500 पृष्ठ",
                    "150-300 Pages": "150-300 पृष्ठ",
                    "100-150 Pages": "100-150 पृष्ठ",
                    "10-50 Pages": "10-50 पृष्ठ",
                    "English Book": "अंग्रेजी पुस्तक",
                    "Non-English Book": "गैर-अंग्रेजी पुस्तक",
                    "Finished": "समाप्त",
                    "Unfinished": "अधूरा",
                    "In Progress": "प्रगति पर है",
                    "Top 10": "टॉप 10",
                    "Temples": "मंदिर",
                    "in": "में",
                    "Continue Reading...": "जारी रखें पढ़ रहे हैं...",
                    "Big Temple": "बड़ा मंदिर",
                    "Small Temple": "छोटा मंदिर",
                    "Medium Temple": "मध्यम मंदिर",
                    "Outpost": "आउटपोस्ट",
                    "Preaching Centre": "प्रचार केंद्र",
                    "Extension Centre": "विस्तार केंद्र",
                    "Points": "अंक",
                    "Your Stats": "आपके आँकड़े",
                    "All India Ranking": "सम्पूर्ण भारत में रैंकिंग",
                    // "State Rank": "राज्य रैंकिंग",
                    // "State Rank": "राज्य रैंक",
                    "Zonal Ranking": "जोनल रैंकिंग",
                    // "Category Rank": "श्रेणी रैंक",
                    "Total Points": "कुल अंक",
                    "Books Distributed": "वितरित की गईं पुस्तकें",
                    "Track real-time rankings and performance with Live Ranking Table, providing instant insights into book distribution and more.": "वर्तमान श्रेणी एवँ प्रदर्शन, वर्तमान श्रेणी तालिका के साथ पुस्तक वितरण एवँ अन्य तथ्यों की तत्काल स्थिति देखें।",
                    "Filter By": "फिल्टर करें",
                    "Events": "इवेंट्स",
                    "Regular Update": "नियमित अपडेट",
                    "Bhadra Purnima": "भद्र पूर्णिमा",
                    "Chaitanya Charitamrita": "चैतन्य चरितामृत",
                    "Gita Marathon": "गीता मैराथन",
                    "All India Ranking": "भारत में प्राप्त स्थान",
                    "Zone Wise": "क्षेत्रानुसार",
                    "Category Wise": "कैटेगरी वाइज",
                    "State Wise": "राज्य वाइज",
                    "Book Wise": "पुस्तक वाइज",
                    "Reset Filter": "फ़िल्टर रीसेट करें",
                    "Compare": "तुलना करें",
                    "Ranking": "रैंकिंग",
                    "Category": "श्रेणी",
                    "Particular": "विशेष",
                    "Zone": "क्षेत्र",
                    "Score": "अंक",
                    "Zone Wise Total Points": "क्षेत्रानुसार कुल अंक",
                    "State Wise Points": "राज्य के अनुसार प्राप्त अंक",
                    "State-wise graphs visualize book distribution points specific to each state.": "राज्य-वार ग्राफ प्रत्येक राज्य के लिए पुस्तक वितरण अंक को दृश्य रूप में प्रस्तुत करते हैं।",
                    "Books Distributed by Category in each Zone": "प्रत्येक जोन में श्रेणी के अनुसार पुस्तकें वितरित की गईं",
                    "of": "का",
                    "Dedicated to His Divine Grace A.C. Bhaktivedanta Swami Prabhupada - Founder Acharya of the International Society for Krishna Consciousness.": "कृष्ण कृपामूर्ति ए.सी. भक्तिवेदांत स्वामी प्रभुपाद - अंतर्राष्ट्रीय कृष्ण भावनामृत संघ के संस्थापकाचार्य को समर्पित",
                    "All Rights Reserved.": "सर्वाधिकार सुरक्षित।",
                    "Submit Your Pledge": "अपनी प्रतिज्ञा जमा करें",
                    "Submit your pledge, update your pledge, and view your pledge history. A Regular Update refers to book distribution that occurs throughout the year, whereas a marathon refers to pledges undertaken during the marathon period.": "अपना संकल्प यहाँ संपादित करें, अपने संकल्प को अपडेट करें, और अपने संकल्प का इतिहास देखें। नियमित अपडेट से तात्पर्य वर्ष भर होने वाले पुस्तक वितरण से है, जबकि मैराथन से तात्पर्य मैराथन अवधि के दौरान लिए गए संकल्प से है।",
                    "Your Current Pledge": "आपकी वर्तमान प्रतिज्ञा",
                    "View Historical Pledges": "ऐतिहासिक संकल्पों को देखें",
                    "Submit Your Pledge": "अपनी प्रतिज्ञा सबमिट करें",
                    "Submit": "जमा करना",
                    "India pledge completion status": "भारत प्रतिज्ञा पूर्णता स्थिति",
                    "Edit your pledge here": "अपनी प्रतिज्ञा यहाँ संपादित करें",
                    "Book": "पुस्तक",
                    "Points": "अंक",
                    "Actual Pledge": "वास्तविक प्रतिज्ञा",
                    "Count": "गिनती",
                    "Total Count": "कुल गिनती",
                    "9 Mahabig Books": "9 महाबिग पुस्तकें",
                    "18 Mahabig Books": "18 महाबिग पुस्तकें",
                    "Total": "कुल",
                    "Save": "सेव करें",
                    "Update Your Points": "अपने पॉइंट्स अपडेट करें",
                    "Submit your book distribution points, update your points and view your points history.": "अपनी पुस्तक वितरण अंक प्रस्तुत करें, अपने अंक अपडेट करें और अपने अंको का इतिहास देखें।",
                    "Your Performance Status": "आपकी प्रदर्शन स्थिति",
                    "Bulk Upload": "बल्क अपलोड",
                    "View Historical Points": "ऐतिहासिक अंक देखें",
                    "Update Your points": "अपने अंक अपडेट करें",
                    "Pledged": "प्रतिज्ञा",
                    "Actuals": "वास्तविक",
                    "No. Of Books": "पुस्तकों की संख्या",
                    "Edit your points here": "यहां अपने अंक संपादित करें",
                    "Select Date": "तारीख चुनें",
                    "Modify": "संशोधित करें",
                    "Are you sure you want to update the books count in": "क्या आप निश्चित हैं कि आप किताबों की गिनती को अपडेट करना चाहते हैं",
                    "Yes": "हाँ",
                    "Cancel": "रद्द करें",
                    "Need to get in touch with us? Either fill out the form with your inquiry or kindly contact us at": "हमसे संपर्क करना चाहते हैं? कृपया अपना प्रश्न फ़ॉर्म में भरें या हमसे संपर्क करें",
                    "or call on": "या हमें इस नंबर पर कॉल करें",
                    "ISKCON Logo": "इस्कॉन लोगो",
                    "Need Help?": "सहायता चाहिए?",
                    "Subject": "विषय",
                    "Your Message": "आपका संदेश",
                    "Type here": "यहां टाइप करें",
                    "Type your message": "अपना संदेश टाइप करें",
                    "Send Message": "संदेश भेजें",
                    "Send": "भेजें",
                    "Sevak Head": "सेवक मुखिया",
                    "Upload File": "फाइल अपलोड करें",
                    "Please enter the subject": "कृपया विषय दर्ज करें",
                    "Please enter the message": "कृपया संदेश दर्ज करें",
                    "Uploaded File": "अपलोड की गई फाइल",
                    "Click to Upload": "अपलोड करने के लिए क्लिक करें",
                    "or drag and drop": "या फाइल खींचकर छोड़ें",
                    "Max. File size: 2 MB": "अधिकतम फाइल आकार: 2 एमबी",
                    "File size exceeds 2MB. Please upload a smaller file.": "फाइल का आकार 2MB से अधिक है। कृपया एक छोटी फाइल अपलोड करें।",
                    "Upload File": "फ़ाइल अपलोड करें",
                    "You are Uploading": "आप अपलोड कर रहे हैं",
                    "Bulk Score": "बल्क अंक",
                    "Close": "बंद करें",
                    "Drop your file or click here to browse": "अपनी फ़ाइल ड्रॉप करें या ब्राउज़ करने के लिए यहां क्लिक करें",
                    "UPLOAD": "अपलोड",
                    "Showing": "दिखाएं",
                    "Date fields": "दिनांक फ़ील्ड",
                    "Click Here to Download the Template": "टेम्पलेट डाउनलोड करने के लिए यहाँ क्लिक करें",
                    "Instruction How To Upload The Data": "डेटा अपलोड करने के निर्देश",
                    "In Book Name please add the name of the book with correct spelling.": "बुक नाम में कृपया सही स्पेलिंग के साथ बुक का नाम जोड़ें।",
                    "In Book Count please add the book distribution count.": "बुक काउंट में कृपया बुक वितरण की गणना जोड़ें।",
                    "In the Date Fields please add the date for which you need to upload the data. The date format should be DD-MM-YYYY.": "तारीख़ के फ़ील्ड में कृपया उस तारीख़ को जोड़ें जिसके लिए आपको डेटा अपलोड करना है। तारीख़ का प्रारूप DD-MM-YYYY होना चाहिए।",
                    "Upload file should be in the .xlsx format.": "अपलोड की गई फ़ाइल .xlsx प्रारूप में होनी चाहिए।",
                    "Note: Once the file is uploaded, no corrections can be made.": "नोट: एक बार फ़ाइल अपलोड हो जाने के बाद, कोई संशोधन नहीं किया जा सकता।",
                    "Compare With Another Temple": "दूसरे मंदिर से तुलना करें",
                    "Select Temple": "मंदिर चुनें",
                    "1 Year BTG Subscription-English": "1 वर्ष बीटीजी सदस्यता-अंग्रेज़ी",
                    "1 Year BTG Subscription-Non English": "1 वर्ष बीटीजी सदस्यता-गैर-अंग्रेज़ी",
                    "Zone Ranking": "क्षेत्र में प्राप्त स्थान",
                    "Category Ranking": "वर्ग में प्राप्त स्थान",
                    "State Ranking": "राज्य में प्राप्त स्थान",
                    "Hare Krishna": "हरे कृष्ण",
                    "Logout Account": "लॉगआउट खाता",
                    "My Profile": "मेरी प्रोफ़ाइल",
                    "Notifications": "सूचनाएं",
                    "Name": "नाम",
                    "Phone": "फ़ोन",
                    "Email": "ईमेल",
                    "Role": "भूमिका",
                    "Temple": "मंदिर",
                    "Zone": "ज़ोन",
                    "State": "राज्य",
                    "Note": "नोट",
                    "The below graphs indicates state wise performance of book distribution.": "नीचे दिए गए ग्राफ़ पुस्तक वितरण का राज्यानुसार प्रदर्शन को दर्शाते हैं।",
                    "Darker color indicates higher book distribution, lighter color indicates lower book distribution.": "गहरा रंग अधिक पुस्तक वितरण को दर्शाता है, हल्का रंग कम पुस्तक वितरण को दर्शाता है।",
                    "Step": "स्टेप",
                    "Enter your name": "अपना नाम दर्ज करें",
                    "Enter your phone number": "अपना फ़ोन नंबर दर्ज करें",
                    "Enter your email": "अपना ईमेल दर्ज करें",
                    "Enter your role": "अपनी भूमिका दर्ज करें",
                    "Enter your temple": "अपना मंदिर दर्ज करें",
                    "Enter your zone": "अपना ज़ोन दर्ज करें",
                    "Enter your state": "अपना राज्य दर्ज करें",
                    "India's Pledge": "भारत का संकल्प",
                    "India's Points": "भारत के अंक",
                    "Your Score": "आपका अंक",
                    "India's Score": "भारत का अंक",
                    "Download": "डाउनलोड",
                    "Sr.No": "क्रमांक",
                    "Book Name": "पुस्तक का नाम",
                    "Event Name": "कार्यक्रम का नाम",
                    "No. of Books": "पुस्तकों की संख्या",
                    "Updated By": "अप्डेटेड बाय",
                    "Updated On": "अप्डेटेड तारीख ",
                    "Updated For": "अप्डेटेड फॉर",
                    "All Your Historical": "आपके सभी ऐतिहासिक",
                    "View Your Historical Points": "अपने ऐतिहासिक अंक देखें",
                    "No History Found": "कोई इतिहास नहीं मिला",
                    "View Your Historical Pledges": "अपने ऐतिहासिक प्रतिज्ञाओं को देखें",
                    "Hide replies": "उत्तर छुपाएँ",
                    "View replies": "उत्तर देखें",
                    "Answer": "उत्तर",
                    "Blog And Articles": "ब्लॉग और लेख",
                    "Read More": "अधिक पढ़ें",
                    "Categories": "श्रेणियाँ",
                    "Forum details": "फोरम विवरण",
                    "Reply": "जवाब",
                    "Write a comment": "एक टिप्पणी लिखें",
                    "For Prabhupada's Pleasure": "प्रभुपाद की प्रसन्नता के लिए",
                    "write your comment here": "यहाँ अपनी टिप्पणी लिखें",
                    "Post Comments": "टिप्पणियाँ पोस्ट करें",
                    "Comments": "टिप्पणियाँ",
                    "Comment": "टिप्पणी",
                    "Enter question": "प्रश्न दर्ज करें",
                    "Popular": "लोकप्रिय",
                    "Your Answer": "आपका उत्तर",
                    "All Categories": "सभी श्रेणियाँ",
                    "Srimad Bhagavatam": "श्रीमद भगवतम",
                    "Gita Marathon": "गीता मैराथन",
                    "CC Marathon": "सीसी मैराथन",
                    "Book Pricing": "पुस्तक मूल्य निर्धारण",
                    "History of Book Distribution": "पुस्तक वितरण का इतिहास",
                    "Monthly Sankirtan Festival": "मासिक संकीर्तन महोत्सव",
                    "Vidya Daan": "विद्या दान",
                    "Corporate Book Distribution": "कॉर्पोरेट पुस्तक वितरण",
                    "Success Stories": "सफलता की कहानियाँ",
                    "Prabhupada Distributing Books": "प्रभुपाद पुस्तकों का वितरण",
                    "For Prabhupada's Pleasure": "प्रभुपाद की प्रसन्नता के लिए",
                    "Others": "अन्य",
                    "Search...": "खोजें...",
                    "Quotes": "विचार",
                    "Big Temple": "बड़ा मंदिर",
                    "Small Temple": "छोटा मंदिर",
                    "Medium Temple": "मध्यम मंदिर",
                    "Outpost": "आउटपोस्ट",
                    "Preaching Centre": "प्रचार केंद्र",
                    "Extension Centre": "विस्तार केंद्र",
                    "South Zone": "दक्षिण क्षेत्र",
                    "West Zone": "पश्चिम क्षेत्र",
                    "East Zone": "पूर्व क्षेत्र",
                    "North Zone": "उत्तर क्षेत्र",
                    "Andaman & Nicobar Islands": "अंडमान और निकोबार द्वीपसमूह",
                    "Andaman & Nicobar Island": "अंडमान और निकोबार द्वीपसमूह",

                    "Andhra Pradesh": "आंध्र प्रदेश",
                    "Arunachal Pradesh": "अरुणाचल प्रदेश",
                    "Assam": "असम",
                    "Bihar": "बिहार",
                    "Chandigarh": "चंडीगढ़",
                    "Chhattisgarh": "छत्तीसगढ़",
                    "Dadra & Nagar Haveli": "दादरा और नगर हवेली",
                    "Daman & Diu": "दमन और दीव",
                    "Delhi": "दिल्ली",
                    "Goa": "गोवा",
                    "Gujarat": "गुजरात",
                    "Haryana": "हरियाणा",
                    "Himachal Pradesh": "हिमाचल प्रदेश",
                    "Jammu & Kashmir": "जम्मू और कश्मीर",
                    "Jammu and Kashmir": "जम्मू और कश्मीर",
                    "Marathon": "मैराथन",
                    "Jharkhand": "झारखंड",
                    "Karnataka": "कर्नाटक",
                    "Kerala": "केरल",
                    "Lakshadweep": "लक्षद्वीप",
                    "Madhya Pradesh": "मध्य प्रदेश",
                    "Maharashtra": "महाराष्ट्र",
                    "Manipur": "मणिपुर",
                    "Meghalaya": "मेघालय",
                    "Mizoram": "मिज़ोरम",
                    "Nagaland": "नागालैंड",
                    "Odisha": "ओडिशा",
                    "Puducherry": "पुडुचेरी",
                    "Punjab": "पंजाब",
                    "Rajasthan": "राजस्थान",
                    "Sikkim": "सिक्किम",
                    "Tamil Nadu": "तमिलनाडु",
                    "Tripura": "त्रिपुरा",
                    "Uttar Pradesh": "उत्तर प्रदेश",
                    "Uttarakhand": "उत्तराखंड",
                    "West Bengal": "पश्चिम बंगाल",
                    "Telangana": "तेलंगाना",
                    "Srimad Bhagavatam Sets": "श्रीमद-भागवतम सेट्स",
                    "Chaitanya Charitamrita Sets": "चैतन्य चरितामृत सेट्स",
                    "Select Category": "श्रेणी चुनें",
                    "Select Zone": "क्षेत्र चुनें",
                    "Select State": "राज्य चुनें",
                    "Select Zone": "क्षेत्र चुनें",
                    "1st": "पहला",
                    "2nd": "दूसरा",
                    "3rd": "तीसरा",
                    "4th": "चौथा",
                    "5th": "पांचवां",
                    "6th": "छठा",
                    "7th": "सातवां",
                    "8th": "आठवां",
                    "9th": "नौवां",
                    "10th": "दसवां",
                    "11th": "ग्यारहवां",
                    "12th": "बारहवां",
                    "13th": "तेरहवां",
                    "14th": "चौदहवां",
                    "15th": "पंद्रहवां",
                    "16th": "सोलहवां",
                    "17th": "सत्रहवां",
                    "18th": "अठारहवां",
                    "19th": "उन्नीसवां",
                    "20th": "बीसवां",
                    "21st": "इक्कीसवां",
                    "22nd": "बाईसवां",
                    "23rd": "तेईसवां",
                    "24th": "चौबीसवां",
                    "25th": "पच्चीसवां",
                    "26th": "छब्बीसवां",
                    "27th": "सत्ताईसवां",
                    "28th": "अठाईसवां",
                    "29th": "उन्तीसवां",
                    "30th": "तीसवां",
                    "31st": "एकतिसवां",
                    "32nd": "बत्तीसवां",
                    "33rd": "तैंतीसवां",
                    "34th": "चौंतीसवां",
                    "35th": "पैंतीसवां",
                    "36th": "छत्तीसवां",
                    "37th": "सैंतीसवां",
                    "38th": "अत्तीसवां",
                    "39th": "उनचालीसवां",
                    "40th": "चालीसवां",
                    "41st": "इकतालीसवां",
                    "42nd": "बयालीसवां",
                    "43rd": "तैतालीसवां",
                    "44th": "चौंतालीसवां",
                    "45th": "पैंतालीसवां",
                    "46th": "छियालीसवां",
                    "47th": "सैंतालीसवां",
                    "48th": "अठतालीसवां",
                    "49th": "उनचासवां",
                    "50th": "पचासवां",
                    "51st": "इक्यावनवां",
                    "52nd": "बावनवां",
                    "53rd": "तिरपनवां",
                    "54th": "चववनवां",
                    "55th": "पचपनवां",
                    "56th": "छप्पनवां",
                    "57th": "सतावनवां",
                    "58th": "अठावनवां",
                    "59th": "उनसठवां",
                    "60th": "साठवां",
                    "61st": "इकसठवां",
                    "62nd": "बासठवां",
                    "63rd": "तिरसठवां",
                    "64th": "चौंसठवां",
                    "65th": "पैंसठवां",
                    "66th": "छियासठवां",
                    "67th": "सड़सठवां",
                    "68th": "अठसठवां",
                    "69th": "उनहत्तरवां",
                    "70th": "सत्तरवां",
                    "71st": "इकहत्तरवां",
                    "72nd": "बहत्तरवां",
                    "73rd": "तिहत्तरवां",
                    "74th": "चौहत्तरवां",
                    "75th": "पचहत्तरवां",
                    "76th": "छिहत्तरवां",
                    "77th": "सतहत्तरवां",
                    "78th": "अठहत्तरवां",
                    "79th": "उनासीवां",
                    "80th": "अस्सीवां",
                    "81st": "इक्यासीवां",
                    "82nd": "बासीवां",
                    "83rd": "तिरासीवां",
                    "84th": "चौरासीवां",
                    "85th": "पचासीवां",
                    "86th": "छियासीवां",
                    "87th": "सतासीवां",
                    "88th": "अठासीवां",
                    "89th": "उननव्वेवा",
                    "90th": "नब्बेवा",
                    "91st": "इक्यानवेवां",
                    "92nd": "बानवेवां",
                    "93rd": "तिरानवेवां",
                    "94th": "चौरानवेवां",
                    "95th": "पंचानवेवां",
                    "96th": "छियानवेवां",
                    "97th": "सत्तानवेवां",
                    "98th": "अठानवेवां",
                    "99th": "निन्यानवेवां",
                    "100th": "सौवां",
                    "101st": "एक सौ एकवां",
                    "102nd": "एक सौ दोवां",
                    "103rd": "एक सौ तीनवां",
                    "104th": "एक सौ चारवां",
                    "105th": "एक सौ पांचवां",
                    "106th": "एक सौ छठा",
                    "107th": "एक सौ सातवां",
                    "108th": "एक सौ आठवां",
                    "109th": "एक सौ नौवां",
                    "110th": "एक सौ दसवां",
                    "111th": "एक सौ ग्यारहवां",
                    "112th": "एक सौ बारहवां",
                    "113th": "एक सौ तेरहवां",
                    "114th": "एक सौ चौदहवां",
                    "115th": "एक सौ पंद्रहवां",
                    "116th": "एक सौ सोलहवां",
                    "117th": "एक सौ सत्रहवां",
                    "118th": "एक सौ अठारहवां",
                    "119th": "एक सौ उन्नीसवां",
                    "120th": "एक सौ बीसवां",
                    "121st": "एक सौ इक्कीसवां",
                    "122nd": "एक सौ बाईसवां",
                    "123rd": "एक सौ तेईसवां",
                    "124th": "एक सौ चौबीसवां",
                    "125th": "एक सौ पच्चीसवां",
                    "126th": "एक सौ छब्बीसवां",
                    "127th": "एक सौ सत्ताईसवां",
                    "128th": "एक सौ अठाईसवां",
                    "129th": "एक सौ उन्नतीसवां",
                    "130th": "एक सौ तीसवां",
                    "131st": "एक सौ इकतीसवां",
                    "132nd": "एक सौ बत्तीसवां",
                    "133rd": "एक सौ तैंतीसवां",
                    "134th": "एक सौ चौंतीसवां",
                    "135th": "एक सौ पैंतीसवां",
                    "136th": "एक सौ छत्तीसवां",
                    "137th": "एक सौ सैंतीसवां",
                    "138th": "एक सौ अठतीसवां",
                    "139th": "एक सौ उनचालीसवां",
                    "140th": "एक सौ चालीसवां",
                    "141st": "एक सौ इकतालीसवां",
                    "142nd": "एक सौ बयालीसवां",
                    "143rd": "एक सौ तैतालीसवां",
                    "144th": "एक सौ चौंतालीसवां",
                    "145th": "एक सौ पैंतालीसवां",
                    "146th": "एक सौ छियालीसवां",
                    "147th": "एक सौ सैंतालीसवां",
                    "148th": "एक सौ अठतालीसवां",
                    "149th": "एक सौ उनचासवां",
                    "150th": "एक सौ पचासवां",
                    "151st": "एक सौ इक्यावनवां",
                    "152nd": "एक सौ बावनवां",
                    "153rd": "एक सौ तिरपनवां",
                    "154th": "एक सौ चववनवां",
                    "155th": "एक सौ पचपनवां",
                    "156th": "एक सौ छप्पनवां",
                    "157th": "एक सौ सतावनवां",
                    "158th": "एक सौ अठावनवां",
                    "159th": "एक सौ उनसठवां",
                    "160th": "एक सौ साठवां",
                    "161st": "एक सौ इकसठवां",
                    "162nd": "एक सौ बासठवां",
                    "163rd": "एक सौ तिरसठवां",
                    "164th": "एक सौ चौंसठवां",
                    "165th": "एक सौ पैंसठवां",
                    "166th": "एक सौ छियासठवां",
                    "167th": "एक सौ सड़सठवां",
                    "168th": "एक सौ अठसठवां",
                    "169th": "एक सौ उनहत्तरवां",
                    "170th": "एक सौ सत्तरवां",
                    "171st": "एक सौ इकहत्तरवां",
                    "172nd": "एक सौ बहत्तरवां",
                    "173rd": "एक सौ तिहत्तरवां",
                    "174th": "एक सौ चौहत्तरवां",
                    "175th": "एक सौ पचहत्तरवां",
                    "176th": "एक सौ छिहत्तरवां",
                    "177th": "एक सौ सतहत्तरवां",
                    "178th": "एक सौ अठहत्तरवां",
                    "179th": "एक सौ उनासीवां",
                    "180th": "एक सौ अस्सीवां",
                    "181st": "एक सौ इक्यासीवां",
                    "182nd": "एक सौ बासीवां",
                    "183rd": "एक सौ तिरासीवां",
                    "184th": "एक सौ चौरासीवां",
                    "185th": "एक सौ पचासीवां",
                    "186th": "एक सौ छियासीवां",
                    "187th": "एक सौ सतासीवां",
                    "188th": "एक सौ अठासीवां",
                    "189th": "एक सौ उननव्वेवा",
                    "190th": "एक सौ नब्बेवा",
                    "191st": "एक सौ इक्यानवेवां",
                    "192nd": "एक सौ बानवेवां",
                    "193rd": "एक सौ तिरानवेवां",
                    "194th": "एक सौ चौरानवेवां",
                    "195th": "एक सौ पंचानवेवां",
                    "196th": "एक सौ छियानवेवां",
                    "197th": "एक सौ सत्तानवेवां",
                    "198th": "एक सौ अठानवेवां",
                    "199th": "एक सौ निन्यानवेवां",
                    "200th": "दो सौवां",
                    "201st": "दो सौ एकवां",
                    "202nd": "दो सौ दोवां",
                    "203rd": "दो सौ तीनवां",
                    "204th": "दो सौ चारवां",
                    "205th": "दो सौ पांचवां",
                    "206th": "दो सौ छठा",
                    "207th": "दो सौ सातवां",
                    "208th": "दो सौ आठवां",
                    "209th": "दो सौ नौवां",
                    "210th": "दो सौ दसवां",
                    "211th": "दो सौ ग्यारहवां",
                    "212th": "दो सौ बारहवां",
                    "213rd": "दो सौ तेरहवां",
                    "214th": "दो सौ चौदहवां",
                    "215th": "दो सौ पंद्रहवां",
                    "216th": "दो सौ सोलहवां",
                    "217th": "दो सौ सत्रहवां",
                    "218th": "दो सौ अठारहवां",
                    "219th": "दो सौ उन्नीसवां",
                    "220th": "दो सौ बीसवां",
                    "221st": "दो सौ इक्कीसवां",
                    "222nd": "दो सौ बाईसवां",
                    "223rd": "दो सौ तेईसवां",
                    "224th": "दो सौ चौबीसवां",
                    "225th": "दो सौ पच्चीसवां",
                    "226th": "दो सौ छब्बीसवां",
                    "227th": "दो सौ सत्ताईसवां",
                    "228th": "दो सौ अठाईसवां",
                    "229th": "दो सौ उन्नतीसवां",
                    "230th": "दो सौ तीसवां",
                    "231st": "दो सौ इकतीसवां",
                    "232nd": "दो सौ बत्तीसवां",
                    "233rd": "दो सौ तैंतीसवां",
                    "234th": "दो सौ चौंतीसवां",
                    "235th": "दो सौ पैंतीसवां",
                    "236th": "दो सौ छत्तीसवां",
                    "237th": "दो सौ सैंतीसवां",
                    "238th": "दो सौ अठतीसवां",
                    "239th": "दो सौ उनचालीसवां",
                    "240th": "दो सौ चालीसवां",
                    "241st": "दो सौ इकतालीसवां",
                    "242nd": "दो सौ बयालीसवां",
                    "243rd": "दो सौ तैतालीसवां",
                    "244th": "दो सौ चौंतालीसवां",
                    "245th": "दो सौ पैंतालीसवां",
                    "246th": "दो सौ छियालीसवां",
                    "247th": "दो सौ सैंतालीसवां",
                    "248th": "दो सौ अठतालीसवां",
                    "249th": "दो सौ उनचासवां",
                    "250th": "दो सौ पचासवां",
                    "251st": "दो सौ इक्यावनवां",
                    "252nd": "दो सौ बावनवां",
                    "253rd": "दो सौ तिरपनवां",
                    "254th": "दो सौ चववनवां",
                    "255th": "दो सौ पचपनवां",
                    "256th": "दो सौ छप्पनवां",
                    "257th": "दो सौ सतावनवां",
                    "258th": "दो सौ अठावनवां",
                    "259th": "दो सौ उनसठवां",
                    "260th": "दो सौ साठवां",
                    "261st": "दो सौ इकसठवां",
                    "262nd": "दो सौ बासठवां",
                    "263rd": "दो सौ तिरसठवां",
                    "264th": "दो सौ चौंसठवां",
                    "265th": "दो सौ पैंसठवां",
                    "266th": "दो सौ छियासठवां",
                    "267th": "दो सौ सड़सठवां",
                    "268th": "दो सौ अठसठवां",
                    "269th": "दो सौ उनहत्तरवां",
                    "270th": "दो सौ सत्तरवां",
                    "271st": "दो सौ इकहत्तरवां",
                    "272nd": "दो सौ बहत्तरवां",
                    "273rd": "दो सौ तिहत्तरवां",
                    "274th": "दो सौ चौहत्तरवां",
                    "275th": "दो सौ पचहत्तरवां",
                    "276th": "दो सौ छिहत्तरवां",
                    "277th": "दो सौ सतहत्तरवां",
                    "278th": "दो सौ अठहत्तरवां",
                    "279th": "दो सौ उनासीवां",
                    "280th": "दो सौ अस्सीवां",
                    "281st": "दो सौ इक्यासीवां",
                    "282nd": "दो सौ बासीवां",
                    "283rd": "दो सौ तिरासीवां",
                    "284th": "दो सौ चौरासीवां",
                    "285th": "दो सौ पचासीवां",
                    "286th": "दो सौ छियासीवां",
                    "287th": "दो सौ सतासीवां",
                    "288th": "दो सौ अठासीवां",
                    "289th": "दो सौ उननव्वेवा",
                    "290th": "दो सौ नब्बेवा",
                    "291st": "दो सौ इक्यानवेवां",
                    "292nd": "दो सौ बानवेवां",
                    "293rd": "दो सौ तिरानवेवां",
                    "294th": "दो सौ चौरानवेवां",
                    "295th": "दो सौ पंचानवेवां",
                    "296th": "दो सौ छियानवेवां",
                    "297th": "दो सौ सत्तानवेवां",
                    "298th": "दो सौ अठानवेवां",
                    "299th": "दो सौ निन्यानवेवां",
                    "300th": "तीन सौवां",
                    "301st": "तीन सौ एकवां",
                    "302nd": "तीन सौ दोवां",
                    "303rd": "तीन सौ तीनवां",
                    "304th": "तीन सौ चारवां",
                    "305th": "तीन सौ पांचवां",
                    "306th": "तीन सौ छठा",
                    "307th": "तीन सौ सातवां",
                    "308th": "तीन सौ आठवां",
                    "309th": "तीन सौ नौवां",
                    "310th": "तीन सौ दसवां",
                    "311th": "तीन सौ ग्यारहवां",
                    "312th": "तीन सौ बारहवां",
                    "313rd": "तीन सौ तेरहवां",
                    "314th": "तीन सौ चौदहवां",
                    "315th": "तीन सौ पंद्रहवां",
                    "316th": "तीन सौ सोलहवां",
                    "317th": "तीन सौ सत्रहवां",
                    "318th": "तीन सौ अठारहवां",
                    "319th": "तीन सौ उन्नीसवां",
                    "320th": "तीन सौ बीसवां",
                    "321st": "तीन सौ इक्कीसवां",
                    "322nd": "तीन सौ बाईसवां",
                    "323rd": "तीन सौ तेईसवां",
                    "324th": "तीन सौ चौबीसवां",
                    "325th": "तीन सौ पच्चीसवां",
                    "326th": "तीन सौ छब्बीसवां",
                    "327th": "तीन सौ सत्ताईसवां",
                    "328th": "तीन सौ अठाईसवां",
                    "329th": "तीन सौ उन्नतीसवां",
                    "Load More": "और लोड करें",
                    "Total Score": "कुल अंक",
                    "Year Wise Stats": "वर्ष वाइज आँकड़े",
                    "Today's Update": "आज का अपडेट",
                    "Event Wise Dashboard": "कार्यक्रमानुसार डैशबोर्ड",
                    "Comparison": "तुलना",
                    "Vs": "बनाम",
                    "Book Count": "पुस्तक गणना",
                    "Upload file": "फ़ाइल अपलोड करें",
                    "Upload": "अपलोड करें",
                    "Your Temples": "आपके मंदिर",
                    "Temple Performance": "मंदिर प्रदर्शन",
                    "State Wise Rank": "राज्य के अनुसार प्राप्त अंक",
                    "Temple stats": "मंदिर के आँकड़े",
                    "Select and upload the file of your choice": "अपनी पसंद की फ़ाइल चुनें और अपलोड करें",
                    "Choose a file or drag & drop it here": "कोई फ़ाइल चुनें या उसे यहां खींचें और छोड़ें",
                    "Excel formats: XLSX, XLS": "एक्सेल प्रारूप: XLSX, XLS",
                    "Browse File": "फाइल खोजो",
                    "Year Wise Book Stats": "वर्ष वाइज पुस्तक आँकड़े",
                    "Book Distribution": "पुस्तक वितरण",
                    "Temple Wise Stats": "मंदिर के अनुसार आँकड़े",
                    "Select any temple to track the ranking and book wise progress of the temple": "मंदिर की रैंकिंग और पुस्तकवार प्रगति को ट्रैक करने के लिए किसी भी मंदिर का चयन करें",
                    "This page provides a comprehensive overview of performance metrics for temples across India, categorized by events, zones, and states.": "यह पृष्ठ पूरे भारत में मंदिरों के प्रदर्शन मेट्रिक्स का एक व्यापक अवलोकन प्रदान करता है, जो घटनाओं, क्षेत्रों और राज्यों द्वारा वर्गीकृत है।",
                    "Hare Krishna Hare Krishna, Krishna Krishna Hare Hare | Hare Rama Hare Rama, Rama Rama Hare Hare": "हरे कृष्ण हरे कृष्ण, कृष्ण कृष्ण हरे हरे । हरे राम हरे राम, राम राम हरे हरे ॥",
                    "The progress indicates your temple pledge and overall india pledge covered": "प्रगति से पता चलता है कि आपकी मंदिर प्रतिज्ञा और समग्र भारत प्रतिज्ञा पूरी हो गई है",
                    "Please be advised that if you wish to change your profile details, kindly contact us at": "कृपया ध्यान दें कि यदि आप अपनी प्रोफ़ाइल विवरण बदलना चाहते हैं, तो कृपया हमसे संपर्क करें",
                    "Category and Zone wise Distribution of Books": "श्रेणी और क्षेत्रानुसार पुस्तकों का वितरण",
                    "Book and Zone wise Distribution of Books(Count)": "पुस्तकों का पुस्तक एवं क्षेत्रानुसार वितरण (गणना)",
                    "Category and Zone wise Distribution of Books(Points)": "पुस्तकों का श्रेणी एवं क्षेत्रानुसार वितरण (अंक)",
                    "हिन्दी": "English",
                    // Add more translations here...
                },
            },
        },
        fallbackLng: 'en', // Fallback language if the user's language is not available
        debug: true, // Set to false in production
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
