import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ApiService from "../api/ApiService";
import notification from "../helpers/notification";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { VscThreeBars } from "react-icons/vsc";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import { FiExternalLink } from "react-icons/fi";
import { FaFacebookF, FaYoutube, FaInstagram, FaMobileAlt } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { HiUser } from "react-icons/hi";
import { Modal } from "react-bootstrap";
import MetaData from "../helpers/MetaData";
import "../css/RecordingOfScores.css";
import "../css/app.css";
import AppContext from "../context/AppContext";
import { useTranslation } from "react-i18next";

// Define points per book type
const POINTS_PER_BOOK = {
  Magazines: 0.1,
  SmallBooks: 0.25,
  MediumBooks: 0.5,
  BigBooks: 1.0,
  MahabigBooks: 2.0,
  SrimadBhagavatamSet: 72.0,
  ChaitanyaCharitamritaSet: 36.0,
  "1 Year BTG Subscription-1": 5.0,
  "1 Year BTG Subscription-2": 2.5,
};

const RecordingOfPledge = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const [pointsFromDB, setPointsFromDB] = useState([]);
  const navigate = useNavigate();
  const [pledgeCountsFromDB, setPledgeCountFromDB] = useState([]);
  const { user, setHistoryEvent } = React.useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalPopUPText, setModalPopUPText] = useState("");

  const [counts, setCounts] = useState({
    ChaitanyaCharitamritaSet: 0,
    SrimadBhagavatamSet: 0,
    MahabigBooks: 0,
    BigBooks: 0,
    BigBooks: 0,
    MediumBooks: 0,
    SmallBooks: 0,
    Magazines: 0,
    "1 Year BTG Subscription-1": 0,
    "1 Year BTG Subscription-2": 0,
  });

  const [selectedEvent, setSelectedEvent] = useState("Regular Update");
  const [points, setPoints] = useState({
    ChaitanyaCharitamritaSet: 0,
    SrimadBhagavatamSet: 0,
    MahabigBooks: 0,
    BigBooks: 0,
    MediumBooks: 0,
    SmallBooks: 0,
    Magazines: 0,
    "1 Year BTG Subscription-1": 0,
    "1 Year BTG Subscription-2": 0,
  });
  const progressTextMapping = {
    ChaitanyaCharitamritaSet: "9 Mahabig Books",
    SrimadBhagavatamSet: "18 Mahabig Books",
    MahabigBooks: "500+ Pages",
    BigBooks: "300-500 Pages",
    MediumBooks: "150-300 Pages",
    SmallBooks: "100-150 Pages",
    Magazines: "10-50 Pages",
    "1 Year BTG Subscription-1": "English Book",
    "1 Year BTG Subscription-2": "Non-English Book",
  };

  const progressImagesMapping = {
    ChaitanyaCharitamritaSet: "/images/books-images/cc-sets.png",
    SrimadBhagavatamSet: "/images/books-images/sb-sets.webp",
    MahabigBooks: "/images/books-images/maha-big-book.jpg",
    BigBooks: "/images/books-images/big-books.jpg",
    MediumBooks: "/images/books-images/medium-books.jpg",
    SmallBooks: "/images/books-images/small-books.jpg",
    Magazines: "/images/books-images/magazine.png",
    "1YearBTGSubscription-1": "/images/books-images/btg.jpg",
    "1YearBTGSubscription-2": "/images/books-images/btg.jpg",
  };

  useEffect(() => {
    const calculatePoints = (bookType) => {
      const count = counts[bookType];
      const pointPerBook = POINTS_PER_BOOK[bookType];
      setPoints((prevPoints) => ({
        ...prevPoints,
        [bookType]: count * pointPerBook,
      }));
    };

    Object.keys(counts).forEach(calculatePoints);
  }, [counts]);

  const totalPoints = Object.values(points).reduce((sum, point) => sum + Number(point), 0);

  const totalCounts = Object.values(counts).reduce((sum, count) => {
    if (count != 0) {
      return sum + Number(count);
    } else {
      return sum;
    }
  }, 0);

  const fetchPoints = async () => {
    try {
      const res = await ApiService.fetchPoints();

      setPointsFromDB(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPoints();
  }, []);

  const fetchPledgeCount = async (user) => {
    const resultCount = {};
    const resultPoints = {};

    setPoints({
      ChaitanyaCharitamritaSet: 0,
      SrimadBhagavatamSet: 0,
      MahabigBooks: 0,
      BigBooks: 0,
      MediumBooks: 0,
      SmallBooks: 0,
      Magazines: 0,
      "1 Year BTG Subscription-1": 0,
      "1 Year BTG Subscription-2": 0,
    });

    setCounts({
      ChaitanyaCharitamritaSet: 0,
      SrimadBhagavatamSet: 0,
      MahabigBooks: 0,
      BigBooks: 0,
      BigBooks: 0,
      MediumBooks: 0,
      SmallBooks: 0,
      Magazines: 0,
      "1 Year BTG Subscription-1": 0,
      "1 Year BTG Subscription-2": 0,
    });

    try {
      const res = await ApiService.fetchPledgeCount(user.temple_id, selectedEvent, true);
      if (res.length > 0) {
        res.forEach((item) => {
          resultCount[item.book_name] = item.count_book;
          resultPoints[item.book_name] = item.total_points;
        });
        setCounts((prevState) => ({
          ...prevState,
          ...resultCount,
        }));

        setPoints((prevState) => ({
          ...prevState,
          ...resultPoints,
        }));
      }
      setPledgeCountFromDB(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPledgeCount(user);
  }, [user, selectedEvent]);

  const previousEvent = pledgeCountsFromDB.map((Previous) => Previous.event);
  const PreviousBook = pledgeCountsFromDB.map((Previous) => Previous.book_name);

  const previousEventBookCounts = pledgeCountsFromDB.reduce((acc, curr) => {
    if (!acc[curr.event]) {
      acc[curr.event] = {};
    }
    acc[curr.event][curr.book_name] = curr.count_book;
    return acc;
  }, {});

  const handleRecordingPledgeSave = async () => {
    const toastifyDiv = document.getElementsByClassName("Toastify__toast-container");
    if (toastifyDiv.length > 0) {
      return false;
    }

    let showPopUp = false;
    let modalText = "";

    if (Object.values(counts).some((item) => item > 0)) {
      const data = Object.keys(counts)
        .filter(
          (bookType) =>
            counts[bookType] > 0 &&
            document.getElementsByClassName(bookType).length > 0 &&
            (typeof previousEventBookCounts[selectedEvent] == "undefined" ||
              document.getElementsByClassName(bookType)[0].value != previousEventBookCounts[selectedEvent][bookType])
        )
        .map((bookType) => {
          if (previousEvent.includes(selectedEvent) && PreviousBook.includes(bookType)) {
            if (counts[bookType] != previousEventBookCounts[selectedEvent][bookType]) {
              showPopUp = true;
            } else {
              showPopUp = true;
              modalText = "The entered pledge count matches the previous count. Please update the count to proceed further.";
              return null;
            }
          }

          return {
            book: bookType,
            count_book: counts[bookType],
            total_points: points[bookType],
            book_id: bookType,
            user_id: user.id,
            user_name: user.name,
            hindiname: user.hindiname,
            temple_id: user.temple_id,
            temple_name: user.temple_name,
            state_id: user.state_id,
            state_name: user.state_name,
            zone: user.zone_name,
            event: selectedEvent,
          };
        })
        .filter((score) => score !== null);

      if (data.length == 0) {
        notification("warning", "Please update the pledge Count to proceed further.");
        return false;
      }

      if (showPopUp) {
        setShow(true);
        setModalPopUPText(modalText);
        setModalData(data);
        return false;
      }

      try {
        setLoading(true);
        const res = await ApiService.recordingPledgeScoreSubmit(data);

        notification("success", "Pledge Submitted Successfully");
        if (res) {
          setCounts({
            ChaitanyaCharitamritaSet: 0,
            SrimadBhagavatamSet: 0,
            MahabigBooks: 0,
            BigBooks: 0,
            BigBooks: 0,
            MediumBooks: 0,
            SmallBooks: 0,
            Magazines: 0,
            "1 Year BTG Subscription-1": 0,
            "1 Year BTG Subscription-2": 0,
          });
          setLoading(false);
          fetchPledgeCount(user);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
        }
      } catch (err) {
        console.log(err);
        notification("error", err.response.data.message);
      }
    } else {
      notification("warning", "Please add the pledge Count to proceed further.");
      return false;
    }
  };

  const updateCount = (bookType, value) => {
    const toastifyDiv = document.getElementsByClassName("Toastify__toast-container");
    if (toastifyDiv.length > 0) {
      return false;
    }

    if (user.role != "Sevak Head") {
      notification("warning", "Only Book Distributor Head can update the pledge.");
      return false;
    }

    if (selectedEvent == "Select Event") {
      notification("warning", "Please select an event to add pledge count.");
      return false;
    }

    setCounts((prevCounts) => ({
      ...prevCounts,
      [bookType]: value < 0 ? 0 : value,
    }));
  };

  const handleNavigateTransactions = () => {
    // localStorage.setItem("history-event", selectedEvent);
    setHistoryEvent(selectedEvent);

    setTimeout(() => {
      navigate("/view-history", { state: { page: "pledge", event: selectedEvent } });
    }, 1000);
  };

  const calculateBookTypeCount = (bookType) => {
    let totalCount = 0;

    // Filter pledgeCountsFromDB data for the specified book type and sum the count_book values
    pledgeCountsFromDB.forEach((item) => {
      if (item.book_name.replaceAll(/\s/g, "") === bookType) {
        totalCount += parseInt(item.count_book, 10); // Convert count_book to integer
      }
    });

    return totalCount;
  };

  let totalPledgeCount = 0;

  const handleHideModal = () => {
    setShow(false);
  };

  const handleShowPopModal = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const res = await ApiService.recordingPledgeScoreSubmit(modalData);

      notification("success", "Pledge Submitted Successfully");
      if (res) {
        setModalData([]);
        setCounts({
          ChaitanyaCharitamritaSet: 0,
          SrimadBhagavatamSet: 0,
          MahabigBooks: 0,
          BigBooks: 0,
          BigBooks: 0,
          MediumBooks: 0,
          SmallBooks: 0,
          Magazines: 0,
          "1 Year BTG Subscription-1": 0,
          "1 Year BTG Subscription-2": 0,
        });
        setLoading(false);
        fetchPledgeCount(user);
        setShow(false);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      }
    } catch (err) {
      console.log(err);
      notification("error", err.response.data.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container common-container pledge-responsive">
        <div className="update-score-container card card-top card-shadow">
          <div className="d-flex">
            <div className="col-12">
              <div className="card-body">
                <h5 className="card-title">{t("Submit Your Pledge")}</h5>
                <p className="card-text">
                  {t(
                    "Submit your pledge, update your pledge, and view your pledge history. A Regular Update refers to book distribution that occurs throughout the year, whereas a marathon refers to pledges undertaken during the marathon period."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="update-score-container mt-4">
          <div className="row">
            <div className="col-6">
              <div className="content-table">
                <div className="d-flex justify-content-between pledge-padding-bottom">
                  <div className="table-description">
                    <h3 className="content-title">{t("Your Current Pledge")}</h3>
                  </div>
                  <div className="events-drop-down">
                    <button className="btn-primarysave custom-button" onClick={() => handleNavigateTransactions()}>
                      {t("View Historical Pledges")}
                    </button>
                  </div>
                </div>
                <table className="table">
                  <thead className="custom-thead">
                    <tr>
                      <th scope="col">{t("Book")}</th>
                      <th scope="col">{t("Points")}</th>
                      {selectedEvent !== "Select Event" && (
                        <th scope="col" className="text-end">
                          {t("Actual Pledge")}
                        </th>
                      )}
                    </tr>
                  </thead>
                  {pointsFromDB.map((data, index) => {
                    const shouldDisplayRow =
                      selectedEvent === "Select Event" ||
                      selectedEvent === "Regular Update" ||
                      (selectedEvent === "Bhadra Purnima" && data.book_name.replaceAll(/\s/g, "") == "SrimadBhagavatamSet") ||
                      selectedEvent === "Gita Marathon" ||
                      (selectedEvent == "Chaitanya Charitamrita" && data.book_name.replaceAll(/\s/g, "") == "ChaitanyaCharitamritaSet");
                    const count = calculateBookTypeCount(data.book_name.replaceAll(/\s/g, ""));
                    totalPledgeCount += count;
                    if (shouldDisplayRow) {
                      return (
                        <tr key={index}>
                          <th scope="row">
                            <div className="d-flex align-items-center">
                              <div className="pledge-image p-2">
                                <img
                                  src={progressImagesMapping[data.book_name.replaceAll(/\s/g, "")]}
                                  className="rounded"
                                  width="45"
                                  height="45"
                                  alt={data.book_name}
                                />
                              </div>
                              <div className="w-200">
                                <h4 className="mb-0 font-weight-bold">{t(data.book_name)}</h4>
                                <p className="m-0 ml-2 pages-count">{t(data.page_range)}</p>
                              </div>
                            </div>
                          </th>
                          <th scope="row">
                            <div className="d-flex align-items-center">
                              <div className="w-200">
                                <h4 className="mb-0 ml-2 font-weight-bold">
                                  {data.score_set} {t("Points")}
                                </h4>
                              </div>
                            </div>
                          </th>
                          {selectedEvent != "Select Event" && (
                            <th scope="row">
                              <div className="d-flex justify-content-end">
                                <div className="w-200">
                                  <h4 className="mb-0 ml-2 font-weight-bold">{Math.ceil(count).toLocaleString("en-IN")}</h4>
                                </div>
                              </div>
                            </th>
                          )}
                        </tr>
                      );
                    }
                  })}
                  <div className="media-total-count-div"></div>
                  <tfoot className="pledge-count-total-div">
                    <tr>
                      <th scope="row">{t("Total Count")}</th>
                      <td></td>
                      <td className="total-count-padding">{Math.ceil(totalPledgeCount).toLocaleString("en-IN")}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            <div className="col-6">
              <div className="content-table">
                <div className="d-flex justify-content-between">
                  <div className="table-description">
                    <h3 className="content-title">{t("Submit Your Pledge")}</h3>
                    <p className="content-subtitle">{t("Edit your pledge here")}</p>
                  </div>
                  <div className="events-drop-down" style={{ paddingRight: "17px" }}>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={t(selectedEvent)}
                      onSelect={(eventKey) => setSelectedEvent(eventKey)}
                      className="mr-3 white-dropdown"
                    >
                      <Dropdown.Item eventKey="Regular Update">{t("Regular Update")}</Dropdown.Item>
                      <Dropdown.Item eventKey="Bhadra Purnima">{t("Bhadra Purnima Marathon")}</Dropdown.Item>
                      <Dropdown.Item eventKey="Chaitanya Charitamrita">{t("Chaitanya Charitamrita Marathon")}</Dropdown.Item>
                      <Dropdown.Item eventKey="Gita Marathon">{t("Gita Marathon")}</Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{t("Book")}</th>
                      <th scope="col" className="text-center">
                        {t("Count")}
                      </th>
                      <th scope="col" className="text-center">
                        {t("Points")}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {Object.keys(counts).map((bookType) => {
                      const shouldDisplayRow =
                        selectedEvent === "Select Event" ||
                        selectedEvent === "Regular Update" ||
                        (selectedEvent === "Bhadra Purnima" && bookType === "SrimadBhagavatamSet") ||
                        selectedEvent === "Gita Marathon" ||
                        (selectedEvent === "Chaitanya Charitamrita" && bookType === "ChaitanyaCharitamritaSet");

                      if (shouldDisplayRow) {
                        return (
                          <tr key={bookType} className="align-items-center">
                            <th scope="row">
                              <div className="d-flex align-items-center">
                                <div className="pledge-image media-image">
                                  <img
                                    src={progressImagesMapping[bookType.replaceAll(/\s/g, "")]}
                                    className="rounded"
                                    width="45"
                                    height="45"
                                    alt={bookType}
                                  />
                                </div>
                                <div className="w-200">
                                  <h4 className="mb-0 font-weight-bold media-bold-text">{t(bookType.replace(/([a-z])([A-Z])/g, "$1 $2").trim())}</h4>
                                  <p className="m-0 pages-count">{t(progressTextMapping[bookType] || "Progress text")}</p>
                                </div>
                              </div>
                            </th>
                            <td className="input-box">
                              <button
                                // disabled={user.role != "Sevak Head"}
                                className="counter-button counter-button-left"
                                onClick={() => updateCount(bookType, Number(counts[bookType]) - 1)}
                              >
                                -
                              </button>
                              <input
                                type="text"
                                className={"count-input text-center count-value-text " + bookType}
                                value={Number(counts[bookType]) || ""}
                                onChange={(e) => {
                                  const value = parseInt(e.target.value, 10);
                                  if (!isNaN(value) && value >= 0 && /^\d*$/.test(value)) {
                                    updateCount(bookType, value);
                                  } else if (e.target.value === "") {
                                    updateCount(bookType, ""); // Keep it empty if input is cleared
                                  }
                                }}
                                // readOnly={user.role != "Sevak Head"}
                              />
                              <button
                                // disabled={user.role != "Sevak Head"}
                                className="counter-button counter-button-right"
                                onClick={() => updateCount(bookType, Number(counts[bookType]) + 1)}
                              >
                                +
                              </button>
                            </td>
                            <td className="centered-cell">
                              <div className="pledge-pints mt-1">
                                {parseFloat(points[bookType]?.toFixed(2)).toLocaleString("en-IN")}&nbsp;
                                <span className="hide-points points-count">{t("Points")}</span>
                              </div>
                            </td>
                          </tr>
                        );
                      }

                      return null;
                    })}
                  </tbody>

                  <tfoot>
                    <tr>
                      <th scope="row">
                        {t("Total")} &nbsp;
                        <button disabled={selectedEvent == "Select Event" || loading} className="btn-primarysave" onClick={handleRecordingPledgeSave}>
                          {t("Save")}
                        </button>
                      </th>
                      <td>
                        <div className="total-table-points">{totalCounts.toLocaleString("en-IN")}</div>
                      </td>
                      <td>
                        <div className="total-table-points">{parseFloat(totalPoints.toFixed(2)).toLocaleString("en-IN")}</div>
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal centered show={show} onHide={handleHideModal}>
        <div className="modal-wrap">
          {modalPopUPText == "" ? (
            <p className="card-text p-3">
              {t("Are you sure you want to update the books count in")} {t(selectedEvent)} ?
            </p>
          ) : (
            <p className="card-text p-3">{modalPopUPText}</p>
          )}
          <div className="d-flex justify-content-center mb-2 modal-gap">
            {modalPopUPText == "" && (
              <button className="btn-primarysave custom-button" onClick={() => handleShowPopModal()}>
                {t("Yes")}
              </button>
            )}
            <button className="btn-primarysave custom-button" onClick={() => setShow(false)}>
              {t("Cancel")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RecordingOfPledge;
