import { useEffect, useState } from "react";
import "../css/videos.css";
import { Link } from "react-router-dom";
import Fancybox from "../helpers/Fancybox";
import ApiService from "../api/ApiService";
import Pagination from "../components/common/pagination";
import { useTranslation } from "react-i18next";


export default function VideosSection() {
  const { t, i18n } = useTranslation();

  const categories = [
    "All Categories",
    "Srila Prabhupada On Book Distribution",
    "Success Stories",
    "Significance Of Book Distribution",
    "Get Inspired By Veteran Book Distribution",
    "Others",
  ];

  const [videosList, setVideosList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [totalPagesCont, setTotalPagesCont] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const getVideosList = async () => {
    setVideosList([]);
    const response = await ApiService.getVideosListData(selectedCategory, pageNumber);
    if (response.status) {
      setVideosList(response.data);
      setTotalPagesCont(response.total_count);
    }
  };

  useEffect(() => {
    getVideosList();
  }, [selectedCategory, pageNumber]);

  return (
    <div className="container video-wrapper">
      {/* <div className="blog-top-header"> */}
      <div className="col-sm-9">
        <h4 className="video-heading">{t("Videos")}</h4>
      </div>
      {/* <div className="blog-filters-row col-sm">
          <div className="col-lg-12">
            <div className="blog-filters">
              <select className="form-select" aria-label="Default select example" onChange={(e) => setSelectedCategory(e.target.value)}>
                {categories.map((category, i) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                  </svg>
                </span>
              </div>
              <input
                type="text"
                className="form-control search-container"
                placeholder="Search..."
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
          </div>
        </div> */}
      {/* </div> */}
      <div className="video-container">
        <div className="row">
          {videosList.map((video, i) => (
            <div className="col-lg-4 mb-4" key={i}>
              <div className="video-card video-holder">
                <Fancybox>
                  <a data-fancybox="video" href={video.link} data-thumb={process.env.REACT_APP_BACKEND_URL + video.thumbnail}>
                    <figure className="mb-0 thumbnil">
                      <img className="image" src={process.env.REACT_APP_BACKEND_URL + video.thumbnail} alt="" />
                    </figure>
                    <div className="yt-logo">
                      <figure className="yt-figure">
                        <img src="/images/homepage/why-krishna/youtube.jpg" alt="" />
                      </figure>
                    </div>
                    <div className="video-description">
                      <p className="video-title">
                        {i18n.language === 'hi' ? video.hindititle : video.title}
                      </p>

                    </div>
                  </a>
                </Fancybox>
              </div>
            </div>
          ))}
        </div>
      </div>
      {totalPagesCont > 1 && (
        <div className="bottom-nav">
          <Pagination pageNumber={pageNumber} totalPagesCont={totalPagesCont} setPageNumber={setPageNumber} />
        </div>
      )}
    </div>
  );
}
