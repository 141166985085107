import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import AppContext from "../context/AppContext";
//http://localhost:3000/dashboard?link=12345
const ProtectedRoute = ({ children }) => {
  const search = useLocation().search;
  const router = useLocation();
  const link_id = new URLSearchParams(search).get("link");
  const { token, user } = React.useContext(AppContext);

  if (token && router.pathname == "/login") {
    return <Navigate to="/" />;
  }

  if (
    user != null &&
    (user.role == "GBC (Governing Body Commission)" || user.role == "Regional Zonal Secretary") &&
    (router.pathname == "/recording-pledge" || router.pathname == "/update-score" || router.pathname == "/view-history")
  ) {
    return <Navigate to="/" />;
  }

  return token ? children : <Navigate to={link_id ? "/login?link=" + link_id : "/login"} />;
};

export default ProtectedRoute;
