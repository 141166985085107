import React, { useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import ApiService from "../../api/ApiService";
import html2canvas from "html2canvas";
import { tooltip } from "./toolTip";
import { OverlayTrigger } from "react-bootstrap";
import "../../css/commonCss.css";
import { useTranslation } from "react-i18next";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function StackedChart({ selectedEvent, type }) {
  const { t, i18n } = useTranslation();
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const booksCountArray = [
    {
      label: "ChaitanyaCharitamritaSet",
      y: "0",
    },
    {
      label: "SrimadBhagavatamSet",
      y: "0",
    },
    {
      label: "MahabigBooks",
      y: "0",
    },
    {
      label: "BigBooks",
      y: "0",
    },
    {
      label: "MediumBooks",
      y: "0",
    },
    {
      label: "SmallBooks",
      y: "0",
    },
    {
      label: "Magazines",
      y: "0",
    },
    {
      label: "1 Year BTG Subscription-1",
      y: "0",
    },
    {
      label: "1 Year BTG Subscription-2",
      y: "0",
    },
  ];

  const templesArray = [
    {
      label: t("Big Temple"),
      y: "0",
    },

    {
      label: t("Medium Temple"),
      y: "0",
    },
    {
      label: t("Small Temple"),

      y: "0",
    },
    {
      label: t("Preaching Centre"),
      y: "0",
    },
    {
      label: t("Extension Centre"),
      y: "0",
    },
    {
      label: t("Outpost"),
      y: "0",
    },
  ];

  const zoneColors = {
    "East Zone": "#AF4B91",
    "West Zone": "#8070C9",
    "South Zone": "#E6A632",
    "North Zone": "#41B0AA",
  };

  const progressBookNamesMapping = {
    ChaitanyaCharitamritaSet: t("CC Set"),
    SrimadBhagavatamSet: t("SB-Set"),
    MahabigBooks: t("Mahabig Books"),
    BigBooks: t("Big Books"),
    MediumBooks: t("Medium Books"),
    SmallBooks: t("Small Books"),
    Magazines: t("Magazines"),
    "1 Year BTG Subscription-1": t("1-Year BTG-Eng"),
    "1 Year BTG Subscription-2": t("1-Year BTG-Non-Eng"),
  };

  function updateResultArrayWithDefaultValues(resultData, sortArray) {
    resultData.forEach((zone) => {
      const dataPointsMap = new Map(zone.dataPoints.map(({ label, y }) => [label, y]));

      sortArray.forEach((temple, index) => {
        const yValue = dataPointsMap.get(temple.label) || "0";
        sortArray[index].y = yValue;
      });

      sortArray.forEach((label) => {
        if (!dataPointsMap.has(label.label)) {
          zone.dataPoints.push({ label: label.label, y: 0 });
        }
      });

      zone.dataPoints.sort((a, b) => {
        return sortArray.findIndex((item) => item.label === a.label) - sortArray.findIndex((item) => item.label === b.label);
      });
    });
    return resultData;
  }

  const [zoneData, setZoneData] = useState([]);

  const getAllDataByZones = async () => {
    setZoneData([]);
    try {
      const response = await ApiService.getTempleAndBooksDataByZones(type, selectedEvent);

      let updatedData = [];

      let stackedArray = [];
      if (response.data.length > 0) {
        if (type == "temples") {
          updatedData = updateResultArrayWithDefaultValues(response.data, templesArray);
        } else {
          updatedData = updateResultArrayWithDefaultValues(response.data, booksCountArray);

          updatedData.forEach((books) => {
            books.dataPoints.forEach((item) => {
              item.label = progressBookNamesMapping[item.label];
            });
          });
        }

        updatedData.map((list) => {
          const zoneColor = zoneColors[list.name];
          stackedArray.push({
            type: "stackedColumn",
            showInLegend: true,
            yValueFormatString: "#,###",
            color: zoneColor,
            ...list,
          });
        });
        setZoneData(stackedArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllDataByZones();
  }, [selectedEvent]);

  const toggleDataSeries = (e) => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
  };

  const getDataPointWidth = () => {
    return window.innerWidth > 768 ? 18 : type == "temples" ? 18 : 18;
  };

  const options = {
    animationEnabled: true,
    // exportEnabled: true,
    title: {
      // text: "Council Wise Points of Types of Temple",
      fontFamily: "Geologica",
    },
    axisX: {
      // title: "Temples",
      includeZero: true,
      interval: 1,
      labelAngle: 0,
      labelWrap: true,
    },
    axisY: {
      // title: "Points",
      includeZero: true,
      lineThickness: 0,
      gridThickness: 1,
      gridColor: "#D3D3D3",
      gridDashType: "dot",
      tickThickness: 0,
      tickColor: "#D3D3D3",
      tickLength: 0,
      lineColor: "#D3D3D3",
      labelFormatter: (e) => Math.ceil(e.value).toLocaleString("en-IN"),
    },
    toolTip: {
      shared: true,
      reversed: true,
      contentFormatter: (e) => {
        return e.entries
          .map((entry) => {
            return `<p style="color:${entry.dataSeries.color}; margin:0px">${entry.dataSeries.legendText}: ${Math.ceil(
              entry.dataPoint.y
            ).toLocaleString("en-IN")}</p>`;
          })
          .join("<br/>");
      },
    },
    legend: {
      dockInsidePlotArea: true,
      verticalAlign: "top",
      horizontalAlign: "right",
      reversed: true,
      maxWidth: 0,
      maxHeight: 0,
      itemWidth: 0,
      fontSize: 0,
    },
    data: zoneData,
    dataPointWidth: getDataPointWidth(),
  };

  const handleDownload = () => {
    setIsButtonVisible(false);

    setTimeout(() => {
      html2canvas(document.querySelector(".stats-graph" + type)).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = `${type}-wise-graph.png`;
        link.click();
      });
    }, 1000);

    setTimeout(() => {
      setIsButtonVisible(true);
    }, 1000);
  };

  return (
    <div className={"top-temples-card stats-graph" + type}>
      <div className="head-sec">
        <div className="temple-list-head-sec">
          <h2 className="stacked-head-name m-0">
            {type == "temples" ? t("Category and Zone wise Distribution of Books(Points)") : t("Book and Zone wise Distribution of Books(Count)")}
          </h2>
          <div className="d-flex align-items-center mt-2 mb-2">
            {Object.entries(zoneColors).map(([zone, color], index) => (
              <React.Fragment key={zone}>
                <span
                  style={{
                    display: "inline-block",
                    width: "14px",
                    height: "15px",
                    backgroundColor: color,
                    marginRight: "5px",
                    borderRadius: "50%",
                  }}
                ></span>
                <span style={{ fontSize: "14px" }}>{t(zone)}</span>
                {index < Object.entries(zoneColors).length - 1 && <span style={{ margin: "0 5px" }}></span>}
              </React.Fragment>
            ))}
          </div>
        </div>
        {isButtonVisible && (
          <span className="button-text-download" onClick={handleDownload}>
            {t("Download")}
          </span>
        )}
      </div>
      <div className="stacked-card">
        <CanvasJSChart options={options} />
      </div>
    </div>
  );
}
