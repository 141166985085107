// // import CommonCarousel from "../components/common/commonCarousel";
// import "../css/quotes.css";

// export default function Quotes() {
//   const quotesData = [
//     {
//       image: "/images/quotes/quote-image-1.png",
//       content:
//         "I am very glad to know that your sales of our literature and books are very good and improving more. It is natural that such distribution of literatures should increase as the people hear more about Krishna Consciousness Movement, so distribution of our books and literatures is our major propaganda program.",
//       date: "Letter to Hamsaduta, 29th June 1970",
//     },
//     {
//       image: "/images/quotes/quote-image-2.jpg",
//       content:
//         "The real preaching is selling books. You should know the tactic how to sell without irritating. What your lecture will do for three minutes, but if he reads one page his life may be turned. We don’t want to irritate anyone, however. If he goes away by your aggressive tactics, then you are nonsense and it is your failure. Neither you could sell a book, neither he would remain. But if he buys a book that is the real successful preaching. ",
//       date: "Letter to Bali Mardana, 30th September 1972 ",
//     },
//     {
//       image: "/images/quotes/quote-image-3.png",
//       content:
//         "The book sales are very encouraging, increasing, increasing. It is very good news. Thank you, I want this. Record selling is temporary.... A book sold becomes a permanent matter for enjoyment. We read the scriptures again and again and it is still fresh; when there is time, I go on reading my own books. ",
//       date: "Letter to Hamsaduta, 1st October 1974",
//     },
//     {
//       image: "/images/quotes/quote-image-4.jpg",
//       content:
//         "I think our books will never keep us in a poverty-stricken condition. This is the blessings of His Divine Grace Sri Srimad Bhaktisiddhanta Sarasvati Goswami Maharaja Prabhupada. He is very pleased that you are distributing so many books. ",
//       date: "Letter to Bhagavan, 14th November 1975",
//     },
//     {
//       image: "/images/quotes/quote-image-5.png",
//       content:
//         "I am very pleased with your activities. Now continue and increase. Everyone will want our books. We will always have customers. This is Caitanya Mahaprabhu’s mercy. ",
//       date: "Letter to Ghanasyama, 20th November 1975 ",
//     },
//     {
//       image: "/images/quotes/quote-image-6.jpg",
//       content:
//         "Books are the basis of our Movement. Whatever appreciation we are getting on account of our books, it is because we are following the path chalked out by exalted devotees. We are not writing something whimsical. ",
//       date: "Letter to Tusta-Krsna, 9th January 1976 ",
//     },
//     {
//       image: "/images/quotes/quote-image-7.jpg",
//       content:
//         "This, our book distribution, is the most important task in our society. Therefore, I am giving so much stress and I am working so hard on this. Because this is my life and soul according to the order of my Guru Maharaja. And by his grace it is to some extent successful. And I took it seriously. I take it seriously still now. That is my life and soul. I never tried in India to construct big temples or even in your country we didn’t. I never tried. But I was selling personally books. That is the history. ",
//       date: "Room Conversation, Bombay, 31st December 1976 ",
//     },
//     {
//       image: "/images/quotes/quote-image-8.png",
//       content:
//         "Be assured that there is no more direct way to preach than to distribute Krsna conscious books. Whoever gets a book is benefited. If he reads the book he is benefited still more, or if he gives the book to someone else for reading, both he and the other person is benefited. Even if one does not read the book but simply holds it and sees it, he is benefited. If he simply gives small donation towards the work of Krsna consciousness he is benefited. And anyone who distributes these transcendental literatures, he is also benefited. Therefore, sankirtan is the prime benediction for the age.",
//       date: "Letter to German Disciples, 6th May 1977",
//     },
//   ];

//   return (
//     <div className="container dashboard-container quote-container">
//       <h4 className="blog-heading mt-3 mb-3">Quotes</h4>
//       {/* <CommonCarousel> */}
//       {quotesData.map((item, i) => (
//         <div className={"row user-welcome-card mb-4 content-section " + ((i + 1) % 2 == 0 ? "content-reverse " : "")} key={i}>
//           <div className="col-lg-3 quote-image-div">
//             <div className="quote-image">
//               <img src={item.image} alt="quote image" />
//               {/* <div className="quotes-icon">
//                 <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" className="bi bi-quote" viewBox="0 0 16 16">
//                   <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 3 7.558V11a1 1 0 0 0 1 1z" />
//                 </svg>
//               </div> */}
//             </div>
//           </div>

//           <div className="col-lg-9 quote-content">
//             <div>
//               {/* <h4 className="blog-title">
//                 “Our Most Important Activity”
//               </h4> */}
//               “{item.content}”<p className="pledge-status-text">– {item.date}</p>
//             </div>
//           </div>
//         </div>
//       ))}
//       {/* </CommonCarousel> */}
//     </div>
//   );
// }
import React, { useState, useEffect } from 'react';
import ApiService from "../api/ApiService";
// Update with the actual path
import "../css/quotes.css";
import { useTranslation } from 'react-i18next';


export default function Quotes() {
  const { t, i18n } = useTranslation();

  const [quotesData, setQuotesData] = useState([]);

  const fetchQuotes = async () => {
    try {
      const res = await ApiService.fetchQuotes();
      setQuotesData(res); // Assuming `res` is an array of quotes
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchQuotes();
  }, []);

  return (
    <div className="container dashboard-container quote-container">
      <h4 className="blog-heading mt-3 mb-3">{t("Quotes")}</h4>
      {quotesData.map((item, i) => (
        <div className={"row user-welcome-card mb-4 content-section " + ((i + 1) % 2 === 0 ? "content-reverse " : "")} key={i}>
          <div className="col-lg-3 quote-image-div">
            <div className="quote-image">
              <img src={process.env.REACT_APP_BACKEND_URL + item.image} alt="quote image" />
            </div>
          </div>

          <div className="col-lg-9 quote-content">
            <div>
              {i18n.language === 'hi' ? `“${item.hindiQuote}”` : `“${item.englishQuote}”`}
              <p className="pledge-status-text">– {item.date}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
