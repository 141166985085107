import { useTranslation } from "react-i18next";
import IndiaMap from "../common/indiaMap";
import GraphChart from "../common/graphChart";
import BooksPledgeTable from "../common/booksPledgeTable";
import { useEffect, useState } from "react";
import ApiService from "../../api/ApiService";
import RSABookWiseGraph from "../common/RSABookWiseGraph";
import StateWisePointsTable from "../common/stateWisePointsTable";
import RSAtemplePerformance from "../common/RSAtemplePerformance";
import RSATempleBookStats from "../common/RSATempleBookStats";

const RSADashboard = ({
  selectedEvent,
  userBooksStatsData,
  overAllPointsCount,
  userEventRanking,
  previousYearUserEventRanking,
  topTemplesList,
  user,
}) => {
  const { t, i18n } = useTranslation();
  const [templePerformanceStatus, setTemplePerformanceStatus] = useState([]);

  const getTemplePerformanceStatus = async () => {
    const postData = {
      temple_array: user.temple_array,
      state_array: user.state_array,
      zone_array: user.zone_array,
      event: selectedEvent,
    };

    const res = await ApiService.getCombineTempleData(postData);

    setTemplePerformanceStatus(res.combinedData);
  };
  useEffect(() => {
    getTemplePerformanceStatus(user);
  }, [user, selectedEvent]);

  return (
    <>
      <div className="row mt-4">
        <div className={selectedEvent == "Regular Update" || selectedEvent == "Gita Marathon" ? "col-md-8" : "col-md-4"}>
          <GraphChart booksData={userBooksStatsData} graphHeader={t("Book Wise Growth Chart")} type={"books"} selectedEvent={selectedEvent} />
        </div>
        <div className="col-md-4 graph-stats-div">
          <GraphChart booksData={overAllPointsCount} graphHeader={t("Year Wise Growth Chart")} type="points" />
        </div>
        {(selectedEvent == "Bhadra Purnima" || selectedEvent == "Chaitanya Charitamrita") && (
          <div className={"col-md-4"}>
            <RSATempleBookStats selectedEvent={selectedEvent} user={user} />
          </div>
        )}
      </div>

      <div className="row mt-4">
        <div className="col-md-6 ">
          <RSAtemplePerformance templePerformanceStatus={templePerformanceStatus} user={user} selectedEvent={selectedEvent} />
        </div>
        <div className="col-md-6 graph-stats-div">
          <BooksPledgeTable selectedEvent={selectedEvent} isRSAUser={true} />
        </div>
      </div>
      {/* <div className="row mt-4">
        <RSABookWiseGraph booksData={categoryBookData} graphHeader={t("Category And Book Wise Chart")} type={"books"} selectedEvent={selectedEvent} />
      </div> */}
      <div className="row mt-4 temple-login" id="state-wise-points">
        <IndiaMap selectedEvent={selectedEvent} />
        <StateWisePointsTable selectedEvent={selectedEvent} />
      </div>
    </>
  );
};

export default RSADashboard;
