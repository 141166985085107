import { useState } from "react";
import "../css/audio.css";

export default function AudioSection() {
  const [audioList, serAudioList] = useState([1, 2, 3, 4, 5]);
  return (
    <div className="container audio-wrapper header-name">
      <div className="blog-top-header">
        <div className="col-sm">
          <h4 className="blog-heading mt-3">Temple Songs & Prayers</h4>
        </div>
        <div className="blog-filters-row col-sm">
          <div className="col-sm">
            <div className="blog-filters">
              <select className="form-select" aria-label="Default select example">
                <option value>Categories</option>
                <option value>Categories</option>
                <option value>Categories</option>
              </select>
            </div>
          </div>
          <div className="col-sm">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                  </svg>
                </span>
              </div>
              <input
                type="text"
                className="form-control search-container"
                placeholder="Search..."
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row audio-table">
        <div className="row text-start">
          <div className="column table-cell-width">#Track</div>
          <div className="column table-cell-width">Play</div>
          <div className="column table-cell-width">Duration</div>
        </div>
        {audioList.map((v, i) => (
          <div className="row audio-list mt-2" key={i}>
            <div className="column text-start table-cell-width">
              {i + 1} &nbsp;&nbsp;
              <img src="https://www.iskcon.org/img/2015/01/iskcon-temple2-300x200.jpg" height="57" width="57" className="audio-img" alt="Audio" />
            </div>
            <div className="column text-start table-cell-width">
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="#9F1D21" className="bi bi-play-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
              </svg>
            </div>
            <div className="column text-start table-cell-width download-option">
              <div>
                {i == 1 || i == 3 ? (
                  <>
                    <span>00:15/03:28</span>&nbsp;&nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-volume-up"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.536 14.01A8.47 8.47 0 0 0 14.026 8a8.47 8.47 0 0 0-2.49-6.01l-.708.707A7.48 7.48 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303z" />
                      <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.48 5.48 0 0 1 11.025 8a5.48 5.48 0 0 1-1.61 3.89z" />
                      <path d="M10.025 8a4.5 4.5 0 0 1-1.318 3.182L8 10.475A3.5 3.5 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.5 4.5 0 0 1 10.025 8M7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11" />
                    </svg>
                  </>
                ) : (
                  "4:23"
                )}
              </div>
              <div className="column text-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  fill="currentColor"
                  className="bi bi-arrow-down-circle"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z"
                  />
                </svg>
                &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  fill="currentColor"
                  className="bi bi-three-dots-vertical"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                </svg>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
