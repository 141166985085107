import moment from "moment/moment";
import React, { useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";

const GraphChart = ({ booksData, graphHeader, domainRange = [0, 100], type = "" }) => {
  const { t, i18n } = useTranslation();
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const progressBookNamesMapping = {
    ChaitanyaCharitamritaSet: t("CC Set"),
    SrimadBhagavatamSet: t("SB-Set"),
    MahabigBooks: t("Mahabig Books"),
    BigBooks: t("Big Books"),
    MediumBooks: t("Medium Books"),
    SmallBooks: t("Small Books"),
    Magazines: t("Magazines"),
    "1 Year BTG Subscription-1": t("1-Year BTG-Eng"),
    "1 Year BTG Subscription-2": t("1-Year BTG-Non-Eng"),
  };

  const currentYear = moment().year();
  const previousYear = moment().subtract(1, "year").year();

  let maxDomainRange = domainRange;
  if (booksData.length > 0) {
    const maxScore = Math.max(...booksData.map((book) => Math.max(parseFloat(book.lastYearScore), parseFloat(book.currentScore))));

    let tickInterval;
    if (maxScore > 1000000) {
      tickInterval = 100000; // For values in the millions
    } else if (maxScore > 100000) {
      tickInterval = 10000; // For values in the hundreds of thousands
    } else if (maxScore > 10000) {
      tickInterval = 1000; // For values in the tens of thousands
    } else if (maxScore > 1000) {
      tickInterval = 100; // For values in the thousands
    } else {
      tickInterval = 10; // For values in the hundreds
    }

    const roundedMaxScore = Math.ceil(maxScore / tickInterval) * tickInterval;
    const extendedMaxDomain = roundedMaxScore + tickInterval * 10;

    maxDomainRange = [0, extendedMaxDomain];
  }

  const data = booksData.map((book) => {
    let formattedName = book?.name ?? "Year";
    if (type == "books") {
      formattedName = progressBookNamesMapping[book?.name];
    }
    return {
      name: formattedName,
      [`${previousYear}`]: book.lastYearScore,
      [`${currentYear}`]: book.currentScore,
    };
  });

  const handleDownload = () => {
    setIsButtonVisible(false);

    setTimeout(() => {
      html2canvas(document.querySelector("#" + type)).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = `${type}-graph.png`;
        link.click();
      });
    }, 1000);

    setTimeout(() => {
      setIsButtonVisible(true);
    }, 1000);
  };

  const formatNumberWithCommas = (number) => {
    return Math.ceil(number).toLocaleString("en-IN");
  };

  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload && payload.length) {
      return (
        <div className="user-welcome-card">
          <p className="m-0 filter-text text-dark">{label?.includes("-") ? label.replaceAll("-", " ") : label}</p>
          <p className="m-0 filter-text last-year-tooltip">{`${t("Last Year")}: ${formatNumberWithCommas(payload[0].value)}`}</p>
          <p className="m-0 filter-text current-year-tooltip">{`${t("Current Year")}: ${formatNumberWithCommas(payload[1].value)}`}</p>
        </div>
      );
    }

    return null;
  };

  const CustomXAxisTick = ({ x, y, payload }) => {
    // console.log("words", payload);
    const words = payload.value.split(" ");
    return (
      <g transform={`translate(${x},${y})`}>
        {words.map((word, index) => (
          <text
            key={index}
            x={0}
            y={10}
            dy={index * 20}
            textAnchor="middle"
            fill="#6e7fa3"
            // transform="rotate(-30)"
            fontSize={13}
          >
            {word?.includes("-") ? word.replaceAll("-", " ") : word}
          </text>
        ))}
      </g>
    );
  };

  const RoundedBar = ({ fill, x, y, width, height }) => {
    const radius = 5;

    const effectiveRadius = Math.min(radius, height / 2);

    return (
      <g>
        <path
          d={`
            M${x + effectiveRadius},${y}
            L${x + width - effectiveRadius},${y}
            Q${x + width},${y} ${x + width},${y + effectiveRadius}
            L${x + width},${y + height}
            L${x},${y + height}
            L${x},${y + effectiveRadius}
            Q${x},${y} ${x + effectiveRadius},${y}
            Z
          `}
          fill={fill}
        />
      </g>
    );
  };

  return (
    <div className="graph-container-card graph-container" id={type}>
      <div className="head-sec">
        <div className="temple-list-head-sec">
          <h2 className="pledge-head-temple-points">
            {graphHeader}
            {type != "books" ? <span style={{ fontSize: "12px" }}> ({t("Points")})</span> : <span style={{ fontSize: "12px" }}> ({t("Count")})</span>}
          </h2>
          <div className="d-flex align-items-center">
            <span className="previous-year-span"></span>&nbsp;&nbsp;
            <span>{previousYear}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className="current-year-span"></span>&nbsp;&nbsp;
            <span>{currentYear}</span>
          </div>
        </div>
        {isButtonVisible && (
          <span className="button-text-download" onClick={handleDownload}>
            {t("Download")}
          </span>
        )}
      </div>
      <div className="books-graph-card">
        <ResponsiveContainer width="100%" className="graph-responsive-container">
          <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid
              strokeDasharray="3 3"
              vertical={false} // Hide vertical grid lines
              horizontal={true} // Show horizontal grid lines
            />
            <XAxis dataKey="name" textAnchor="end" interval={0} tickLine={false} tick={<CustomXAxisTick />} />
            <YAxis
              axisLine={false} // Hide Y-axis line
              tickLine={false}
              interval={1}
              tickCount={15}
              domain={maxDomainRange}
              tickFormatter={formatNumberWithCommas}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey={previousYear.toString()} fill="#C588AC" barSize={15} stackId="a" shape={<RoundedBar />} />
            <Bar dataKey={currentYear.toString()} fill="#A493F2" barSize={15} stackId="b" shape={<RoundedBar />} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default GraphChart;
