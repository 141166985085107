import React, { useEffect } from "react";
import ProgressBar from "progressbar.js"; // Import ProgressBar.js
import "../../css/commonCss.css";

const CommonProgressBar = ({ animatedPercentage = "", type = "", selectedEvent }) => {
  useEffect(() => {
    const container = document.getElementById("common-progress-" + type);

    // Function to clear the existing progress bar
    const clearProgressBar = () => {
      if (container) {
        // Remove existing SVG elements if any
        const existingSvg = container.querySelector("svg");
        if (existingSvg) {
          existingSvg.remove();
        }
      }
    };

    // Clear the progress bar before creating a new one
    clearProgressBar();

    if (animatedPercentage !== "") {
      const bar = new ProgressBar.SemiCircle(container, {
        strokeWidth: type == "your-progress" ? 5 : 6.1,
        color: "url(#gradient)",
        trailColor: "#E8E8E8",
        trailWidth: type == "your-progress" ? 5 : 6.1,
        easing: "easeInOut",
        duration: 1400,
        svgStyle: null,
        text: {
          value: "",
          alignToBottom: false,
        },
        step: (state, bar) => {
          bar.path.setAttribute("stroke", state.color);
          const value = Math.round(bar.value() * 100);
          bar.setText(value === 0 ? "" : `${value}/100`);
          bar.text.style.color = state.color;
          bar.text.style.top = "25%";
        },
      });

      bar.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
      bar.text.style.fontSize = "28px";
      bar.text.style.fontWeight = "800";

      bar.animate((animatedPercentage > 100 ? 100 : animatedPercentage) / 100); // Number from 0.0 to 1.0

      const linearGradient = `
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
            <stop offset="0%" stop-color="#FDDA21"/>
            <stop offset="10%" stop-color="#ccd142"/>
            <stop offset="100%" stop-color="#55BB92"/>
          </linearGradient>
        </defs>
      `;

      bar.svg.insertAdjacentHTML("afterBegin", linearGradient);
    }
  }, [animatedPercentage, selectedEvent, type]);

  if (animatedPercentage === "") {
    return null; // Return null instead of false for React components
  }

  return (
    <div
      id={"common-progress-" + type}
      className={type}
      data-value={animatedPercentage !== 0 ? (animatedPercentage > 100 ? 100 : animatedPercentage) : 0}
    ></div>
  );
};

export default CommonProgressBar;
