import React, { useState } from "react";
import "../../css/commonCss.css";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";

function RSAtemplePerformance({ templePerformanceStatus, selectedEvent }) {
  const { t, i18n } = useTranslation();
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const updatedData = templePerformanceStatus
    .sort((a, b) => b.percentage - a.percentage)
    .map((item) => {
      let progressColor;

      const percentage = item.percentage;

      if (percentage <= 20) {
        progressColor = "#FF0000";
      } else if (percentage > 20 && percentage <= 74) {
        progressColor = "#FA9106";
      } else {
        progressColor = "#FED31E";
        if (percentage >= 100) {
          progressColor = "#47D359";
        }
      }

      return {
        ...item,
        color: progressColor,
      };
    });

  const handleDownload = () => {
    setIsButtonVisible(false);

    setTimeout(() => {
      html2canvas(document.querySelector("#" + "temples-performance")).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = `${"temples-performance"}-graph.png`;
        link.click();
      });
    }, 1000);

    setTimeout(() => {
      setIsButtonVisible(true);
    }, 1000);
  };

  return (
    <div
      className={
        (selectedEvent == "Regular Update" || selectedEvent == "Gita Marathon" ? "rsa-temple-performance-max-card" : "rsa-temple-performance-card") +
        " top-temples-card"
      }
      id="temples-performance"
    >
      <div className="head-sec">
        <div className="temple-list-head-sec">
          <h2 className="pledge-head-temple">{t("Temple Performance")}</h2>
        </div>
        {isButtonVisible && (
          <span className="button-text-download" onClick={handleDownload}>
            {t("Download")}
          </span>
        )}
      </div>
      <div className="mt-2">
        {updatedData.map((row, index) => (
          <div className={"temple-progress-div row user-temple-progress"} key={index}>
            <div className="top-name-div">
              <span className="temple-name">{i18n.language === "hi" ? row?.hinditempleName : row.temple_name}</span>
            </div>
            <div className="top-progress-div">
              <div className="temple-progress-bar-container">
                <div className="progress-bar" style={{ width: `${row.percentage >= 100 ? 100 : row.percentage}%`, backgroundColor: row.color }} />
              </div>
            </div>
            <div className="top-count-div">
              <span className="temple-points-count">{Math.floor(row.percentage)}%</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RSAtemplePerformance;
