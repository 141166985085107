import { useContext } from "react";
import NeedHelp from "../components/my-profile/needHelp";
import AppContext from "../context/AppContext";
import { useTranslation } from "react-i18next";

export default function ContactUs() {
  const { t, i18n } = useTranslation();

  return (
    <div className="container profile-wrapper">
      <div className="row">
        <div className="col-md-5">
          <div className="profile-left">
            <div className="profile-image">
              <div className="">
                <p className="m-0 contact-us-text">{t("Contact Us")}</p>
              </div>
            </div>
            <div className="contact-us-image">
              <img src="/images/common/iskcon_logo_1.png" className="" width="100" height="100" alt={t("ISKCON Logo")} />
            </div>
            <p className="contact-description">
              {t("Need to get in touch with us? Either fill out the form with your inquiry or kindly contact us at")} &nbsp;
              <a href="mailto:support@iskcon.com">support@iskcon.com</a>&nbsp;
              {t("or call on")} <a href="tel:8591404046">8591404046</a>.
            </p>
          </div>
        </div>
        <div className="col-md-7">
          <div className="profile-right">
            <NeedHelp />
          </div>
        </div>
      </div>
    </div>
  );
}
