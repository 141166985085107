import { useEffect, useState } from "react";
import "../css/videos.css";
import ApiService from "../api/ApiService";
import { OverlayTrigger } from "react-bootstrap";
import { tooltip } from "../components/common/toolTip";
import { useTranslation } from "react-i18next";

export default function Resources() {
  const categories = ["All Categories", "Poster", "Banner", "Book Mark", "Pamphlet", "Others"];
  const { t, i18n } = useTranslation();

  const [resourcesList, setResourcesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  const getResourcesListData = async () => {
    setResourcesList([]);
    const response = await ApiService.getResourcesList(selectedCategory);
    if (response.status) {
      setResourcesList(response.data);
    }
  };

  useEffect(() => {
    getResourcesListData();
  }, [selectedCategory]);

  const handleDownload = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="container video-wrapper blog-wrapper">
      <div className="blog-top-header">
        <div className="col-sm-9">
          <h4 className="blog-heading mt-3">{t("Posters")}</h4>
        </div>
        <div className="input-group ">
          <div className="input-group-prepend search-width">
            <span className="input-group-text search-span" id="basic-addon1">
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.61745 1.9435L3.61746 1.94353L3.62503 1.94166C3.98503 1.8527 4.38176 1.84332 4.88111 1.84473C5.83834 1.84975 6.82277 1.84997 7.80428 1.85019C8.67625 1.85038 9.54592 1.85058 10.3921 1.85413C10.4015 3.84791 10.3997 5.84058 10.398 7.83595C10.3972 8.77084 10.3964 9.70632 10.3967 10.6428C7.4699 10.6441 4.54142 10.6455 1.61569 10.6415C1.60221 9.7434 1.60373 8.8562 1.60526 7.96055C1.60637 7.31447 1.60748 6.66398 1.60298 6.00184C1.60531 5.77702 1.60264 5.58615 1.60013 5.40668C1.59667 5.15961 1.59352 4.93415 1.60413 4.67148C1.62056 4.26502 1.67099 3.92317 1.78648 3.61914L1.79076 3.60788L1.79473 3.59651C2.07711 2.78869 2.79009 2.14034 3.61745 1.9435Z"
                  stroke="#43567D"
                  stroke-width="1.8"
                />
                <path
                  d="M21.2779 1.84446L21.2779 1.84455L21.2909 1.84415C22.0493 1.82022 22.743 1.98404 23.2464 2.36504L23.2542 2.37091L23.262 2.37662C23.9599 2.88141 24.3707 3.79318 24.3596 4.73073L24.3596 4.73073L24.3595 4.73831C24.3571 5.46076 24.357 6.17985 24.357 6.89715C24.3569 8.14783 24.3568 9.3931 24.3439 10.6414C22.6789 10.6444 21.0159 10.6439 19.352 10.6435C18.0879 10.6431 16.8232 10.6428 15.5566 10.6439C15.5553 10.6091 15.5545 10.5717 15.5543 10.5313C15.554 10.4535 15.5556 10.3755 15.5575 10.2844C15.5578 10.2718 15.558 10.2589 15.5583 10.2458C15.5603 10.146 15.5623 10.0313 15.5601 9.91328C15.5631 7.97948 15.5626 6.04456 15.562 4.11091C15.5618 3.36624 15.5615 2.62176 15.5615 1.8776C16.6458 1.83761 17.7064 1.84364 18.7882 1.84979C19.5998 1.85441 20.4234 1.85909 21.2779 1.84446Z"
                  stroke="#43567D"
                  stroke-width="1.8"
                />
                <path
                  d="M1.40614 15.2971C1.40612 15.2971 1.40684 15.297 1.40837 15.2967L1.40614 15.2971ZM1.60444 15.3692C1.60417 15.3389 1.60405 15.3118 1.60417 15.2871C1.6291 15.2861 1.6563 15.2849 1.68404 15.2833C3.24803 15.2884 4.82307 15.2877 6.39631 15.2869C7.73312 15.2862 9.06863 15.2856 10.3949 15.2886C10.4039 17.3736 10.4019 19.4574 10.3999 21.5435C10.3992 22.3849 10.3984 23.2267 10.3983 24.069C9.0796 24.0805 7.76294 24.0802 6.44149 24.08C5.77883 24.0799 5.11496 24.0798 4.44903 24.0812L4.44903 24.0811L4.43689 24.0813C3.58601 24.0945 2.76204 23.7516 2.26801 23.15L2.2681 23.15L2.25901 23.1392C1.81702 22.6178 1.59022 21.8912 1.60157 21.1363L1.60169 21.1289L1.60168 21.1215C1.60049 20.3094 1.60236 19.4903 1.60424 18.6692C1.60675 17.57 1.60927 16.4674 1.60447 15.3733L1.60448 15.3733L1.60444 15.3692Z"
                  stroke="#43567D"
                  stroke-width="1.8"
                />
                <path
                  d="M19.4502 15.3239L19.4611 15.3226L19.472 15.3209C21.3286 15.0437 23.3032 16.1363 24.0628 17.8538L24.0661 17.8613L24.0696 17.8687C24.6163 19.0467 24.6017 20.4789 24.0283 21.6418L24.0283 21.6418L24.0243 21.6499C23.5368 22.6644 22.6366 23.4796 21.5787 23.8655L21.5787 23.8655L21.5734 23.8674C20.4906 24.2701 19.2424 24.2238 18.1983 23.7381L18.1983 23.7381L18.1912 23.7349C17.1699 23.2706 16.3369 22.3912 15.9265 21.3456L15.9265 21.3456L15.9238 21.3389C15.4548 20.1715 15.5228 18.7935 16.107 17.6796L16.107 17.6796L16.1109 17.672C16.7567 16.4118 18.0503 15.498 19.4502 15.3239Z"
                  stroke="#43567D"
                  stroke-width="1.8"
                />
              </svg>
            </span>
          </div>
          <div className="blog-filters" style={{ width: "72%" }}>
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setSelectedCategory(e.target.value);
                // setPageNumber(1);
              }}
              value={selectedCategory}
            >
              {categories.map((category, i) => (
                <option className="filter-text" key={category} value={category}>
                  {t(category)}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="blogs-container">
        <div className="row">
          {resourcesList.map((v, i) => (
            <div className="col-lg-4 mb-4" key={i}>
              <div className="blog-card resource-card">
                <img src={v.thumbnail} />
                <div className="d-flex justify-content-between  p-2">
                  <p className="blog-title text-center">{i18n.language === 'hi' ? v.hindititle : v.title}</p>
                  <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={tooltip}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45"
                      height="25"
                      fill="#000"
                      className="bi bi-download mt-1 download-button cursor-pointer"
                      viewBox="0 0 16 16"
                      onClick={() => handleDownload(v.link)}
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                    </svg>
                  </OverlayTrigger>
                </div >
              </div >
            </div >
          ))
          }
        </div >
      </div >
    </div >
  );
}
