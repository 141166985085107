import { Link, useParams } from "react-router-dom";
import ApiService from "../../api/ApiService";
import { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function NotificationDetails({}) {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [notificationDetails, setNotificationDetails] = useState([]);

  const getNotificationDetails = async (NotificationId) => {
    const response = await ApiService.getNotificationDetailsById(NotificationId);
    if (response.status) {
      setNotificationDetails(response.data);
    }
  };

  useEffect(() => {
    getNotificationDetails(id);
  }, [id]);

  const convertDateFormate = (dateString) => {
    return moment(dateString).format("DD MMM, YYYY");
  };

  const convertDateFormateToHindi = (dateString) => {
    const monthNamesInHindi = [
      "जनवरी", // January
      "फरवरी", // February
      "मार्च", // March
      "अप्रैल", // April
      "मई", // May
      "जून", // June
      "जुलाई", // July
      "अगस्त", // August
      "सितंबर", // September
      "अक्टूबर", // October
      "नवंबर", // November
      "दिसंबर", // December
    ];

    const date = moment(dateString);
    const day = date.date();
    const month = monthNamesInHindi[date.month()];
    const year = date.year();
    return `${day} ${month}, ${year}`;
  };

  return (
    <div className="container profile-wrapper">
      <div className="row">
        <div className="col-md-1">
          <div className="forum-left transaction-bottom">
            <div
              className="history-back"
              onClick={() => {
                localStorage.setItem("user-notification", true);
                window.history.back();
              }}
            >
              <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.70125 13.9237C7.80669 13.8192 7.89039 13.6947 7.9475 13.5576C8.00462 13.4206 8.03402 13.2735 8.03402 13.125C8.03402 12.9765 8.00462 12.8294 7.9475 12.6924C7.89039 12.5553 7.80669 12.4308 7.70125 12.3263L4 8.625L15.25 8.625C15.5484 8.625 15.8345 8.50647 16.0455 8.29549C16.2565 8.08452 16.375 7.79837 16.375 7.5C16.375 7.20163 16.2565 6.91548 16.0455 6.7045C15.8345 6.49353 15.5484 6.375 15.25 6.375L4 6.375L7.70125 2.67375C7.80669 2.56917 7.89039 2.44474 7.9475 2.30765C8.00462 2.17056 8.03402 2.02351 8.03402 1.875C8.03402 1.72649 8.00462 1.57944 7.9475 1.44235C7.89039 1.30526 7.80669 1.18083 7.70125 1.07625C7.49047 0.866716 7.20533 0.749107 6.90813 0.749107C6.61092 0.749107 6.32578 0.866716 6.115 1.07625L1.28875 5.91375C0.866215 6.33377 0.627514 6.90423 0.625002 7.5C0.630477 8.09187 0.868951 8.65774 1.28875 9.075L6.115 13.9125C6.32429 14.1235 6.60859 14.2432 6.9058 14.2453C7.20302 14.2474 7.48899 14.1318 7.70125 13.9237Z"
                  fill="#6E717F"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="col-md-11">
          <div className="profile-right">
            <div className="container">
              <span className="header-text">{t("Notifications")}</span>
              <div className="profile-right">
                <div className="notification-wrapper">
                  <div className="notification-header">
                    <div className="row">
                      <div className="col-md-10">
                        <span className="notification-subject m-0 text-dark">
                          {i18n.language === "hi" ? notificationDetails.hindititle : notificationDetails.title}
                        </span>
                      </div>
                      <div className="col-md-2 text-md-end">
                        <span className="header-name-span">
                          {i18n.language === "hi"
                            ? convertDateFormateToHindi(notificationDetails.createdAt)
                            : convertDateFormate(notificationDetails.createdAt)}
                        </span>
                      </div>
                    </div>

                    <div
                      className="notification-details-description"
                      dangerouslySetInnerHTML={{
                        __html: i18n.language === "hi" ? notificationDetails.hindidescription : notificationDetails.description,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
