import { useTranslation } from "react-i18next";

export default function Pagination({ pageNumber, setPageNumber, totalPagesCont }) {
  const nextPage = () => {
    setPageNumber((page) => page + 1);
  };
  const { t, i18n } = useTranslation();

  const prevPage = () => {
    setPageNumber((page) => page - 1);
  };

  const getPageRange = (currentPage, totalPages) => {
    const delta = 1;
    let start = Math.max(1, currentPage - delta);
    let end = Math.min(totalPages, currentPage + delta);

    if (end - start < 2 * delta) {
      if (start === 1) {
        end = Math.min(totalPages, start + 2 * delta);
      } else {
        start = Math.max(1, end - 2 * delta);
      }
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const pages = getPageRange(pageNumber, totalPagesCont);

  return (
    <div className="pagination-section">
      <nav className="float-start" aria-label="Page navigation example">
        <ul className="m-0 p-1">
          <li className="page-item pagination-list-style m-0">
            <div className="page-show-text">
              <span className="page-show-text"></span>{t("Showing")} {pageNumber} {t("of")} {totalPagesCont}
            </div>
          </li>
        </ul>
      </nav>
      <nav className="float-end all-pagination" aria-label="Page navigation example">
        <ul className="pagination page-numbers m-0">
          <li className="page-item m-0">
            <button className="page-link" disabled={pageNumber == 1} onClick={() => prevPage()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#9f1d21" className="bi bi-caret-left-fill" viewBox="0 0 16 16">
                <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
              </svg>
            </button>
          </li>
          {pages.map((page) => (
            <li key={page} className={`m-0 page-item ${page === pageNumber ? "" : ""}`}>
              <button className={"page-link " + (page === pageNumber ? "page-active" : "")} onClick={() => setPageNumber(page)}>
                {page}
              </button>
            </li>
          ))}

          <li className="page-item m-0">
            <button className="page-link" disabled={totalPagesCont == pageNumber} onClick={() => nextPage()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#9f1d21" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
              </svg>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}
